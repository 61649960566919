export { default as streaming } from './streaming.svg';
export { default as computer } from './computer.svg';
export { default as lovense } from './lovense.svg';
export { default as lovenseSquare } from './lovenseSquare.svg';
export { default as checkmark } from './checkmark.svg';
export { default as backgroundCheckmark } from './backgroundCheckmark.svg';
export { default as vxModels } from './vxmodelsLogo.svg';
export { default as visitx } from './visitxLogo.svg';
export { default as visitxAbbreviated } from './visitxLogoAbbreviated.svg';
export { default as visitxWhiteAbbreviated } from './visitxWhiteLogoAbbreviated.svg';
export { default as lock } from './lock.svg';
export { default as lock2 } from './lock2.svg';
export { default as backgroundLock } from './backgroundLock.svg';
export { default as chats } from './chats.svg';
export { default as toy } from './toy.svg';
export { default as money } from './money.svg';
export { default as moneyGray } from './moneyGray.svg';
export { default as applyToy } from './applyToy.svg';
export { default as webcam } from './webcam.svg';
export { default as telephoneWebcam } from './telephoneWebcam.svg';
export { default as telegramBadge } from './telegramBadge.svg';
export { default as telegram } from './telegram.svg';
export { default as channelBadge } from './channelBadge.svg';
export { default as moneyBadge } from './moneyBadge.svg';
export { default as threeDotsFull } from './threeDotsFull.svg';
export { default as ticket } from './ticket.svg';
export { default as telephoneActive } from './telephoneActive.svg';
export { default as telephoneInactive } from './telephoneInactive.svg';
export { default as checkmarkBadge } from './checkmarkBadge.svg';
export { default as vxOutline } from './vxOutline.svg';
export { default as play } from './play.svg';
export { default as studioAccount } from './studioAccount.svg';
export { default as vxLiveLogo } from './vxLiveLogo.svg';
export { default as webcamAlternative } from './webcamAlternative.svg';
export { default as userGroup } from './userGroup.svg';
export { default as signUpBadge } from './signUpBadge.svg';
export { default as dollarHeart } from './dollarHeart.svg';
export { default as VX } from './VX.svg';
export { default as desktopAndMobile } from './desktopAndMobile.svg';
export { default as threeStars } from './threeStars.svg';
export { default as robotHeadIcon } from './bx_bx-bot.svg';
