import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import ToyControl from './ToyControl/ToyControl';
import { APP_BASE_PATH } from '../../util/env';
import ChatSettings from './ChatSettings/ChatSettingsContainer';

// eslint-disable-next-line react/prop-types
const Settings = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = `${path}/chatsettings`;
  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={indexRoute} />
      <Route path={`${path}/chatsettings`} component={ChatSettings} />
      <Route path={`${path}/toycontrol`} component={ToyControl} />
      {/* Notification settings redirect for historical reasons: */}
      <Redirect from={`${path}/notifications`} to={`${APP_BASE_PATH}/newscenter/settings`} />
      <Redirect to={indexRoute} />
    </Switch>
  );
};

export default Settings;
