import React, {FC, memo} from 'react';
import {Redirect, Switch} from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import Spinner from '../../components/Spinner/Spinner';
import Development from '../../_development/docs/index';
import ErrorBoundary from '../../atoms/ErrorBoundary/ErrorBoundary';
import {SecurityRole} from '../../graphql/VXModels/types';
import {useCanUseTelegram} from '../../packages/Telegram';
import Account from '../../routes/Account/index';
import BenefitsWizard from '../../routes/BenefitsWizard/BenefitsWizard';
import Dashboard from '../../routes/Dashboard/Dashboard';
import Finances from '../../routes/Finances/index';
import GetOnline from '../../routes/GetOnline/GetOnline';
import JumpLinks from '../../routes/JumpLinks';
import Legal from '../../routes/Legal';
import Marketing from '../../routes/Marketing/index';
import MediaManagement from '../../routes/MediaManagement/index';
import NewsCenter from '../../routes/NewsCenter';
import OnlineProfile from '../../routes/OnlineProfile/index';
import Ranking from '../../routes/Ranking/index';
import Service0900 from '../../routes/Service0900/index';
import Settings from '../../routes/Settings/index';
import Telegram from '../../routes/Telegram';
import TV from '../../routes/TV';
import VXCash from '../../routes/VXCash/index';
import Welcome from '../../routes/Welcome/Welcome';
import {APP_BASE_PATH} from '../../util/env';
import {useHasRole, useUserData} from '../../util/UserData';
import ContentContainer from './ContentContainer';
import InnerContentContainer from './InnerContentContainer';
import VXGames from '../../packages/VXGames/routes';
import {useQuery} from '@apollo/react-hooks';
import {GET_JWT_AUTHTOKEN} from '../../graphql/VXModels/queries/queries';
import {setTusAuthTokenCookie} from '../../util/cookies';

const Content: FC = memo(() => {
    const hasVXModelsRole = useHasRole(SecurityRole.VX_USER_VXMODELS);
    const isInternalAccount = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);

    const userData = useUserData();
    const canCreateBonusCodes = !!(
        hasVXModelsRole && userData?.model?.bonuscodes?.canCreateVisitXBonusCodes
    );

    const preview = userData?.model?.media.previewPicture16;
    const isPictureAccepted = preview?.picture?.isChecked === true;

    const isActorDocsUploaded = userData?.model?.documents?.allActorDocumentsUploaded;
    const isOriginatorDocsUploaded = userData?.model?.documents?.allOriginatorDocumentsUploaded;
    const hasAllRequiredDocuments = userData?.model?.documents?.hasAllRequiredDocuments;

    const ProfilePicAndDocsUploadedAndRP =
        isPictureAccepted &&
        (hasAllRequiredDocuments || isActorDocsUploaded || isOriginatorDocsUploaded);

    const indexPath = ProfilePicAndDocsUploadedAndRP ? '/dashboard' : '/welcome';

    const canUseTelegram = useCanUseTelegram();

    const {data: tusData} = useQuery(GET_JWT_AUTHTOKEN);

    const tusToken = React.useMemo(() => {
        return tusData?.auth?.accessToken;
    }, [tusData?.auth?.accessToken]);

    React.useEffect(() => {
        if (tusToken) {
            setTusAuthTokenCookie(tusToken);
        }
    }, [tusToken]);

    return (
        <ContentContainer>
            <InnerContentContainer>
                {userData ? (
                    <ErrorBoundary>
                        <Switch>
                            <Redirect exact from={`${APP_BASE_PATH}/`} to={APP_BASE_PATH + indexPath}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/legal`} component={Legal}/>
                            <ProtectedRoute path={'/link'} component={JumpLinks}/>
                            <ProtectedRoute
                                path={`${APP_BASE_PATH}/welcome`}
                                component={Welcome}
                                verify={(): boolean => !ProfilePicAndDocsUploadedAndRP}
                            />
                            <ProtectedRoute path={`${APP_BASE_PATH}/dashboard`} component={Dashboard}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/newscenter`} component={NewsCenter}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/onlineprofile`} component={OnlineProfile}/>
                            <ProtectedRoute
                                path={`${APP_BASE_PATH}/mediamanagement`}
                                component={MediaManagement}
                            />
                            <ProtectedRoute path={`${APP_BASE_PATH}/marketing`} component={Marketing}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/finances`} component={Finances}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/vxcash`} component={VXCash}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/service0900`} component={Service0900}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/account`} component={Account}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/settings`} component={Settings}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/ranking`} component={Ranking}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/vxgames`} component={VXGames}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/get-online`} component={GetOnline}/>
                            <ProtectedRoute path={`${APP_BASE_PATH}/tv`} component={TV}/>
                            <ProtectedRoute
                                path={`${APP_BASE_PATH}/vouchers`}
                                component={BenefitsWizard}
                                verify={(): boolean => canCreateBonusCodes}
                            />
                            <ProtectedRoute
                                path={`${APP_BASE_PATH}/telegram`}
                                component={Telegram}
                                verify={(): boolean => canUseTelegram}
                            />
                            <ProtectedRoute
                                path={`${APP_BASE_PATH}/_dev`}
                                component={Development}
                                verify={(): boolean => isInternalAccount}
                            />
                            <Redirect from={`${APP_BASE_PATH}/download`} to={`${APP_BASE_PATH}/get-online`}/>
                            <Redirect to={APP_BASE_PATH + indexPath}/>
                        </Switch>
                    </ErrorBoundary>
                ) : (
                    <Spinner size="xl"/>
                )}
            </InnerContentContainer>
        </ContentContainer>
    );
});

Content.displayName = 'Content';

export default Content;
