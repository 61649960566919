import React, { FC } from 'react';
import { useHasRole } from '../../../../util/UserData';
import {
  AffiliateStatsItem,
  GoToVXCashItem,
  InviteModelsItem,
  InviteUsersItem,
} from '../items/Affiliate';
import { SecurityRole } from '../../../../graphql/VXModels/types';

const AffiliateFragment: FC = () => {
  const isMaster = useHasRole(SecurityRole.VX_USER_MASTER);
  return (
    <>
      <InviteUsersItem />
      <InviteModelsItem />
      {isMaster && <AffiliateStatsItem />}
      {isMaster && <GoToVXCashItem />}
    </>
  );
};
export default AffiliateFragment;
