import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { webcam } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { LovenseItem, SoftwareItem, UsernotesItem, VideochatSettingsItem } from '../items';

const VideochatGroup: FC = () => {
  return (
    <LeftMenuGroup title={_('navigation:main.videochat')} icon={webcam}>
      <SoftwareItem />
      <VideochatSettingsItem />
      <LovenseItem />
    </LeftMenuGroup>
  );
};

export default VideochatGroup;
