import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { bullhorn } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { AdmailsItem, BonusCodesItem, CompetitionsItem, MarketingActionsItem } from '../items';

const MarketingGroup: FC = () => {
  return (
    <LeftMenuGroup title={_('navigation:main.marketing')} icon={bullhorn}>
      <BonusCodesItem />
      <AdmailsItem />
      <CompetitionsItem />
      <MarketingActionsItem />
    </LeftMenuGroup>
  );
};

export default MarketingGroup;
