import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Date, returns a ISO-1806 conform representation of a date */
    DateTime: any;
    /** VX UserId, Int >= 10000 */
    UserId: any;
    /** Url */
    Url: any;
    MixedValue: any;
    /** dynamic, not typed JSON data */
    Json: any;
    /** dynamic, not typed scalar data */
    Any: any;
    /** Date, returns Y-m-d format */
    Date: any;
};


export type Abo = {
    __typename?: 'Abo';
    /** Auth token */
    token: Scalars['String'];
};


export type AbotokenArgs = {
    platformId?: Maybe<Scalars['Int']>;
};

/** Account data */
export type Account = {
    __typename?: 'Account';
    /** First name */
    firstname: Scalars['String'];
    /** Middle name */
    middlename?: Maybe<Scalars['String']>;
    /** Lastname */
    lastname: Scalars['String'];
    /** Postal address */
    address: AccountAddress;
    /** E-mail address */
    email?: Maybe<Scalars['String']>;
    /** Phone number */
    phone1?: Maybe<Scalars['String']>;
    /** Additional phone number */
    phone2?: Maybe<Scalars['String']>;
    /** Fax */
    fax1?: Maybe<Scalars['String']>;
    /** Additional fax */
    fax2?: Maybe<Scalars['String']>;
    /** Mobile phone number */
    mobile?: Maybe<Scalars['String']>;
    /** Is model verified */
    isVerified?: Maybe<Scalars['Boolean']>;
    /** Key accounter */
    keyAccounter?: Maybe<KeyAccounter>;
};


/** Account data */
export type AccountisVerifiedArgs = {
    considerUserId?: Maybe<Scalars['Boolean']>;
};

/** Account address */
export type AccountAddress = {
    __typename?: 'AccountAddress';
    /** street */
    street?: Maybe<Scalars['String']>;
    /** street number */
    streetNbr?: Maybe<Scalars['String']>;
    /** postal code */
    postalCode?: Maybe<Scalars['String']>;
    /** city */
    city?: Maybe<Scalars['String']>;
    /** state */
    state?: Maybe<Scalars['String']>;
    /** country */
    country?: Maybe<Scalars['String']>;
};

export type AccountingMutation = {
    __typename?: 'AccountingMutation';
    requestAccounting: KeyAccounting;
};

export type Adlink = {
    __typename?: 'Adlink';
    /** type of adlink */
    type: Scalars['String'];
    /** type of adlink */
    subtype?: Maybe<Scalars['String']>;
    /** short part of url */
    shortUri: Scalars['String'];
    /** resolved url */
    longUrl: Scalars['Url'];
    /** base url */
    baseUrl: Scalars['Url'];
    /** source */
    source: Scalars['String'];
    /** comment */
    comment?: Maybe<Scalars['String']>;
    /** created date time */
    created: Scalars['DateTime'];
    /** modified date time */
    modified: Scalars['DateTime'];
};

export type AdlinksMutation = {
    __typename?: 'AdlinksMutation';
    /** Update adlinks */
    updateAdlinks: Array<Maybe<Adlink>>;
};

export enum AdlinkTypeEnum {
    /** VISTI-X */
    VISITX = 'VISITX',
    /** Lustagenten */
    LUSTAGENTEN = 'LUSTAGENTEN',
    /** BeiAnrufSex.com */
    BEI_ANRUF_SEX = 'BEI_ANRUF_SEX',
    /** Werde Cam-Girl Seite */
    WERDECAMGIRL = 'WERDECAMGIRL',
    /** Constest / SexiestSelfies */
    CONTEST = 'CONTEST'
}


/** Langs */
export enum ApiLang {
    /** {"de":"Deutsch", "en":"German"} */
    de = 'de',
    /** {"de":"Englisch", "en":"English"} */
    en = 'en'
}

/** Security scope */
export enum AppScope {
    vxmodels = 'vxmodels'
}

export type Attachment = {
    __typename?: 'Attachment';
    /** Attachment type */
    type: AttachmentTypeEnum;
    /** Attachment payload */
    payload: Scalars['String'];
    /** Attachment URL */
    url?: Maybe<Scalars['String']>;
    /** Additional attachment data */
    meta: AttachmentMeta;
};

export type AttachmentInput = {
    /** Attachment type */
    type: AttachmentTypeEnum;
    /** Attachment payload */
    payload: Scalars['Any'];
    /** Additional attachment data */
    meta: AttachmentInputMeta;
};

export type AttachmentInputMeta = {
    /** Filename */
    filename: Scalars['String'];
    /** Attachment type */
    type: Scalars['String'];
    /** Attachmemt size */
    size: Scalars['Int'];
    /** Hash */
    hash?: Maybe<Scalars['String']>;
    /** Preview URL */
    previewUrl?: Maybe<Scalars['String']>;
    /** Dimension (width, height) */
    dimensions?: Maybe<DimensionsInput>;
};

export type AttachmentMeta = {
    __typename?: 'AttachmentMeta';
    /** Filename */
    filename: Scalars['String'];
    /** Attachment type */
    type: Scalars['String'];
    /** Attachmemt size */
    size: Scalars['Int'];
    /** Hash */
    hash?: Maybe<Scalars['String']>;
    /** Preview URL */
    previewUrl?: Maybe<Scalars['String']>;
    /** Dimension (width, height) */
    dimensions?: Maybe<Dimensions>;
};

export enum AttachmentTypeEnum {
    /** BASE64 */
    BASE64 = 'BASE64',
    /** IMG_SRC */
    IMG_SRC = 'IMG_SRC',
    /** IMG_SRC_SET */
    IMG_SRC_SET = 'IMG_SRC_SET',
    /** no value */
    NULL = 'NULL',
    /** UMP_ID */
    UMP_ID = 'UMP_ID',
    /** UMV_ID */
    UMV_ID = 'UMV_ID',
    /** URL */
    URL = 'URL',
    /** UUID */
    UUID = 'UUID'
}

export type AuthMutation = {
    __typename?: 'AuthMutation';
    /** Login with username and password */
    login_v2?: Maybe<LoginResult>;
    /** Login with a one time token (autologin, CT) */
    tokenAuth?: Maybe<AuthUser>;
    /** Logout */
    logout?: Maybe<Scalars['Boolean']>;
    /** Request password reset */
    requestPasswordReset?: Maybe<GenericResponse>;
    /** Set password */
    setPassword?: Maybe<GenericResponse>;
    /** Sign up */
    signup_v2?: Maybe<SignupResult>;
    /** Verify by Token (sent via Email) */
    verify: GenericResponse;
};


export type AuthMutationlogin_v2Args = {
    username: Scalars['String'];
    password: Scalars['String'];
    redir?: Maybe<Scalars['String']>;
};


export type AuthMutationtokenAuthArgs = {
    token: Scalars['String'];
    scope?: Maybe<AppScope>;
};


export type AuthMutationlogoutArgs = {
    token?: Maybe<Scalars['String']>;
};


export type AuthMutationrequestPasswordResetArgs = {
    username: Scalars['String'];
    email: Scalars['String'];
};


export type AuthMutationsetPasswordArgs = {
    password: Scalars['String'];
    hash?: Maybe<Scalars['String']>;
};


export type AuthMutationsignup_v2Args = {
    signupData: AuthSignupInput;
    scope?: Maybe<AppScope>;
    callbackUrl?: Maybe<Scalars['String']>;
};


export type AuthMutationverifyArgs = {
    userId: Scalars['UserId'];
    verificationCode: Scalars['String'];
};

export type AuthSignupInput = {
    /** username */
    username: Scalars['String'];
    /** email address */
    email: Scalars['String'];
    /** password */
    password: Scalars['String'];
    /** firstname */
    firstname: Scalars['String'];
    /** lastname */
    lastname: Scalars['String'];
    /** gender */
    gender: GenderEnum;
    /** birthdate */
    birthdate: Scalars['Date'];
    /** language */
    lang: ApiLang;
    /** gift code */
    giftcode?: Maybe<Scalars['String']>;
    /** userId reference (old remuneration model) */
    userReferer?: Maybe<Scalars['String']>;
    /** webmaster id (WMID) */
    w?: Maybe<Scalars['String']>;
    /** webmaster campaign identifier */
    ws?: Maybe<Scalars['String']>;
    /** webmaster token */
    wt?: Maybe<Scalars['String']>;
    /** adserver token */
    adtv?: Maybe<Scalars['String']>;
    /** partner */
    partner?: Maybe<Scalars['String']>;
    /** partnerProvider */
    partnerProvider?: Maybe<Scalars['String']>;
    /** externalId */
    externalId?: Maybe<Scalars['String']>;
    /** studioHash */
    studioHash?: Maybe<Scalars['String']>;
    /** facebook account */
    facebook?: Maybe<Scalars['String']>;
    /** instagram account */
    instagram?: Maybe<Scalars['String']>;
    /** youtube account */
    youtube?: Maybe<Scalars['String']>;
    /** Twitter account (full profile url) */
    twitter?: Maybe<Scalars['String']>;
};

/** The representation of the currently logged in user. */
export type AuthUser = UserInterface & {
    __typename?: 'AuthUser';
    /** user id of authenticated user */
    id: Scalars['UserId'];
    /** username */
    username: Scalars['String'];
    /** authentication scope */
    scope: Scalars['String'];
    /** roles */
    roles: Array<SecurityRole>;
    /** auth token */
    token?: Maybe<Scalars['String']>;
    /** auth timestamp */
    ts?: Maybe<Scalars['Int']>;
    /** expire timestamp */
    exp?: Maybe<Scalars['Int']>;
    /** extra data, not typed */
    extra?: Maybe<Scalars['Json']>;
    /** redir */
    redir?: Maybe<Scalars['Url']>;
    /** hotline code */
    hotlineCode: Scalars['String'];
    /** The language passed during the signup call. Lowercase. Can have values which are not in ApiLang. */
    signupLanguage: Scalars['String'];
    /** Screenname */
    screenname: Scalars['String'];
    /** Beta Deep Link */
    betaDeeplink?: Maybe<Scalars['String']>;
    /** jwt access token */
    accessToken: Scalars['String'];
    /** jwt refresh token */
    refreshToken: Scalars['String'];
};


/** The representation of the currently logged in user. */
export type AuthUserbetaDeeplinkArgs = {
    target?: Maybe<VXModelsMobileTargetEnum>;
};

export type Avatar = {
    __typename?: 'Avatar';
    /** Photoalbum of avatar */
    album: MediaAlbum;
    /** Picture data of avatar */
    picture?: Maybe<MediaPicture>;
};

export enum AvsGateway {
    sofortident = 'sofortident',
    webcamavs = 'webcamavs',
    ageratorcam = 'ageratorcam',
    ageratorvideo = 'ageratorvideo',
    xcheck = 'xcheck',
    persocheck = 'persocheck',
    videoavs = 'videoavs',
    giropayid = 'giropayid'
}

export type AvsHistoryEntry = {
    __typename?: 'AvsHistoryEntry';
    /** verification result */
    status: AvsStatusEnum;
    /** verification date time */
    date?: Maybe<Scalars['DateTime']>;
    /** AVS used verification gateway */
    gateway?: Maybe<AvsGateway>;
};

export type AvsStatus = {
    __typename?: 'AvsStatus';
    /** origin of AVS data */
    origin: Scalars['String'];
    /** AVS status */
    status: AvsStatusEnum;
    /** AVS used verification gateway */
    gateway?: Maybe<Scalars['String']>;
    /** AVS url */
    url?: Maybe<Scalars['Url']>;
    /** verification date time */
    date?: Maybe<Scalars['DateTime']>;
    /** verification message */
    message?: Maybe<Scalars['String']>;
    /** verification history */
    history?: Maybe<Array<Maybe<AvsHistoryEntry>>>;
};

export enum AvsStatusEnum {
    valid = 'valid',
    invalid = 'invalid',
    error = 'error',
    user_abort = 'user_abort',
    unknown = 'unknown',
    pending = 'pending',
    revoked = 'revoked',
    unavailable = 'unavailable',
    rejected = 'rejected'
}

export type BeiAnrufSex = {
    __typename?: 'BeiAnrufSex';
    /** BeiAnrufSex status */
    state: BeiAnrufSexState;
};

export enum BeiAnrufSexState {
    /** connected call with Service0900, but no video chat */
    connected = 'connected',
    /** online in VISIT-X and available in Service0900 */
    free = 'free',
    /** incoming call on Service0900 */
    incoming = 'incoming',
    /** offline */
    offline = 'offline',
    /** connected call with Service0900 and video chat */
    videochat = 'videochat',
    /** online in VISIT-X, but no Service0900 available */
    offline0900 = 'offline0900'
}

export type BonusCodes = {
    __typename?: 'BonusCodes';
    /** Token */
    token: Scalars['String'];
    /** Returns true if user can create a bonus codes */
    canCreateVisitXBonusCodes: Scalars['Boolean'];
    /** Available minimum top-up amount for content */
    availableContentMinAmounts: Array<Maybe<FormData>>;
};

export enum Calendar365daysInputEnum {
    yes = 'yes',
    no = 'no'
}

export type ChatSetting = {
    __typename?: 'ChatSetting';
    /** Status */
    status: CommonSwitchStatusEnum;
    /** is option enabled */
    isEnabled: Scalars['Boolean'];
    /** is editable */
    isEditable: Scalars['Boolean'];
};

export type ChatSettings = {
    __typename?: 'ChatSettings';
    /** Test-Chat */
    testChat: TestChat;
    /** Heatmap */
    heatmap: Heatmap;
    /** Live preview */
    livePreview: ChatSetting;
    /** Voyeur in single chat */
    voyeurInSingleChat: ChatSetting;
    /** Sound for voyeur in single chat */
    soundForVoyeurInSingleChat: ChatSetting;
    /** Soft chat */
    softChat: ChatSetting;
    /** Conversion chat */
    conversionChat: ChatSetting;
    /** Free chat */
    freeChat: ChatSetting;
    /** Party chat */
    partyChat: ChatSetting;
};

export type ChatSettingsInput = {
    /** Heatmap input */
    heatmap?: Maybe<HeatmapInput>;
    /** Enables/disables a Live-Preview option */
    livePreview?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a VoyeurInSingleChat option */
    voyeurInSingleChat?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a Sound-for-VoyeurInSingleChat option */
    soundForVoyeurInSingleChat?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a Soft-Chat option */
    softChat?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a Conversion-Chat option */
    conversionChat?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a Free-Chat option */
    freeChat?: Maybe<CommonSwitchStatusEnum>;
    /** Enables/disables a Party-Chat option */
    partyChat?: Maybe<CommonSwitchStatusEnum>;
};

export type Cms = {
    __typename?: 'Cms';
    /** article */
    article?: Maybe<CmsArticle>;
};


export type CmsarticleArgs = {
    id: Scalars['ID'];
    rev?: Maybe<Scalars['Int']>;
    lang?: Maybe<ApiLang>;
};

export type CmsArticle = {
    __typename?: 'CmsArticle';
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    content: Scalars['String'];
    lang: ApiLang;
    type: ContentTypeEnum;
    revision: CmsArticleRevision;
    revisions: Array<CmsArticleRevision>;
};

export type CmsArticleRevision = {
    __typename?: 'CmsArticleRevision';
    rev: Scalars['Int'];
    title: Scalars['String'];
    comment: Scalars['String'];
    status: CmsArticleStatusEnum;
    published?: Maybe<Scalars['DateTime']>;
    modified: Scalars['DateTime'];
    pdfUrl?: Maybe<Scalars['String']>;
};

export enum CmsArticleStatusEnum {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    RETIRED = 'RETIRED'
}

export enum CommonSwitchStatusEnum {
    enabled = 'enabled',
    disabled = 'disabled'
}

/** Content type */
export enum ContentTypeEnum {
    markdown = 'markdown',
    html = 'html',
    plaintext = 'plaintext'
}

/** constest */
export type Contest = {
    __typename?: 'Contest';
    /** id */
    id: Scalars['Int'];
    /** contest type */
    type: ContestType;
    /** contest status */
    status: ContestStatus;
    /** contest title */
    title: Scalars['String'];
    /** contest description */
    description?: Maybe<Scalars['String']>;
    /** contest winners */
    winners?: Maybe<Scalars['String']>;
    /** contest prices */
    prices?: Maybe<Scalars['String']>;
    /** contest tasks */
    tasks?: Maybe<Scalars['String']>;
    /** contest upload start */
    uploadStart: Scalars['DateTime'];
    /** contest upload end */
    uploadEnd: Scalars['DateTime'];
    /** contest voting start */
    contestStart: Scalars['DateTime'];
    /** contest voting end */
    contestEnd: Scalars['DateTime'];
    /** upload limit (count) */
    uploadLimit: Scalars['Int'];
    /** uploaded pictures */
    uploadedPictures?: Maybe<Array<Maybe<Picture>>>;
    /** uploaded videos */
    uploadedVideos?: Maybe<Array<Maybe<Video>>>;
    /** total uploaded media */
    totalUploadedMedia: Scalars['Int'];
    /** URL of title picture */
    titlePictureUrl?: Maybe<Scalars['String']>;
    /** is contest active */
    isActive: Scalars['Boolean'];
    /** is contest expired */
    isExpired: Scalars['Boolean'];
    /** is upload active */
    isUploadActive: Scalars['Boolean'];
    /**
     * is voting active @deprecated use isActive
     * @deprecated use isActive
     */
    isVotingActive?: Maybe<Scalars['Boolean']>;
    /** is upload expired */
    isUploadExpired: Scalars['Boolean'];
    /** has contest winners */
    hasWinners: Scalars['Boolean'];
    /** has this model participated */
    hasParticipated: Scalars['Boolean'];
    /**
     * contest URL (deprecated)
     * @deprecated use linkContest instead
     */
    contestUrl?: Maybe<Scalars['Url']>;
    /** contest URL */
    linkContest?: Maybe<Scalars['Url']>;
    /** contest toplist */
    toplist?: Maybe<Array<Maybe<ContestToplistEntry>>>;
    /** sexiest selfies URL */
    linkSexiestSelfies?: Maybe<Scalars['Url']>;
};


/** constest */
export type ContesttoplistArgs = {
    first?: Maybe<Scalars['Int']>;
};

/** contests */
export type Contests = {
    __typename?: 'Contests';
    /** filtered type */
    searchType?: Maybe<Array<Maybe<ContestType>>>;
    /** filtered status */
    searchStatus?: Maybe<ContestStatus>;
    /** found contests */
    contests?: Maybe<Array<Maybe<Contest>>>;
};

/** contest sort */
export enum ContestSort {
    contest_start_asc = 'contest_start_asc',
    contest_start_desc = 'contest_start_desc',
    String = 'String',
    Int = 'Int',
    Boolean = 'Boolean',
    Float = 'Float',
    ID = 'ID'
}

/** contest status */
export enum ContestStatus {
    active = 'active',
    expired = 'expired',
    planned = 'planned',
    String = 'String',
    Int = 'Int',
    Boolean = 'Boolean',
    Float = 'Float',
    ID = 'ID'
}

/** contest toplist entry */
export type ContestToplistEntry = {
    __typename?: 'ContestToplistEntry';
    /** rank */
    rank: Scalars['Int'];
    /** user id */
    userId?: Maybe<Scalars['UserId']>;
    /** username */
    username?: Maybe<Scalars['String']>;
    /** likes */
    likes: Scalars['Int'];
    /** title */
    title: Scalars['String'];
    /** user media id (umvId at video contests, umpId at photo contests) */
    umxId: Scalars['Int'];
    /** preview picture URL */
    previewPictureUrl: Scalars['Url'];
};

/** contest types */
export enum ContestType {
    video = 'video',
    photo = 'photo',
    String = 'String',
    Int = 'Int',
    Boolean = 'Boolean',
    Float = 'Float',
    ID = 'ID'
}

export type ContestUploadedMedia = Picture | Video;

export enum DailyBonusOptInStatusEnum {
    optIn = 'optIn',
    optOut = 'optOut',
    notSet = 'notSet'
}

export type Dashboard = {
    __typename?: 'Dashboard';
    /** Avarage chat rating */
    chatRating: Scalars['Float'];
    /** Avarage chat holding time */
    chatHoldingTime: Scalars['Int'];
    /** Payable amount */
    payableAmount: Scalars['Float'];
    /** Online time for current month */
    onlineTimeCurrentMonth: Scalars['Int'];
    /** Chat and messenger prices */
    prices: Prices;
    /** Excluded teaser tags */
    excludedTeaserTags: Array<Maybe<Scalars['String']>>;
    /** Messenger statistic */
    messengerStatistic: MessengerStatistic;
};


export type DateRange = {
    __typename?: 'DateRange';
    from?: Maybe<Scalars['DateTime']>;
    to?: Maybe<Scalars['DateTime']>;
};


export type DeepL = {
    __typename?: 'DeepL';
    /** Translate Texts */
    translateText: Array<Maybe<TranslateTexts>>;
};


export type DeepLtranslateTextArgs = {
    text: Scalars['String'];
    targetLang?: Maybe<DeeplLangEnum>;
    sourceLang?: Maybe<Scalars['String']>;
};

export enum DeeplLangEnum {
    de = 'de',
    en = 'en',
    es = 'es'
}

export type Dimensions = {
    __typename?: 'Dimensions';
    width: Scalars['Int'];
    height: Scalars['Int'];
};

export type DimensionsInput = {
    width: Scalars['Int'];
    height: Scalars['Int'];
};

export type Document = {
    __typename?: 'Document';
    /** document id */
    id: Scalars['Int'];
    /** document type */
    type?: Maybe<DocumentTypeEnum>;
    /** document status */
    status?: Maybe<DocumentStatusEnum>;
    /** document side */
    side?: Maybe<DocumentSideEnum>;
    /** document owner */
    ownerId?: Maybe<Scalars['Int']>;
    /** document uploader */
    uploaderId?: Maybe<Scalars['Int']>;
    /** insert date */
    insertDate?: Maybe<Scalars['DateTime']>;
    /** expire date */
    expireDate?: Maybe<Scalars['DateTime']>;
    /** file name */
    fileName?: Maybe<Scalars['String']>;
    /** file type */
    fileType?: Maybe<Scalars['String']>;
    /** URL */
    url?: Maybe<Scalars['Url']>;
    /** comment */
    comment?: Maybe<Scalars['String']>;
    /** firstname */
    firstname?: Maybe<Scalars['String']>;
    /** lastname */
    lastname?: Maybe<Scalars['String']>;
    /** number of videos */
    numberOfVideos?: Maybe<Scalars['Int']>;
    /** last update */
    lastUpdate?: Maybe<Scalars['DateTime']>;
    /** reject reasosn if document is rejected */
    rejectionReason?: Maybe<Scalars['String']>;
};

export enum DocumentGroupStatusEnum {
    missing = 'missing',
    missingFrontside = 'missingFrontside',
    missingBackside = 'missingBackside',
    checking = 'checking',
    rejected = 'rejected',
    ok = 'ok'
}

export type Documents = {
    __typename?: 'Documents';
    /** upload endpoint url */
    uploadUrl?: Maybe<Scalars['String']>;
    /** User has all required documents */
    hasAllRequiredDocuments: Scalars['Boolean'];
    /** Are all documents of actors uploaded and verified */
    allActorDocumentsComplete: Scalars['Boolean'];
    /** Are all documents of actors uploaded */
    allActorDocumentsUploaded: Scalars['Boolean'];
    /** User uploaded at least one required document */
    atLeastOneActorDocumentUploaded: Scalars['Boolean'];
    /** Are all documents of originator uploaded and verified */
    allOriginatorDocumentsComplete: Scalars['Boolean'];
    /** Are all documents of originator uploaded */
    allOriginatorDocumentsUploaded: Scalars['Boolean'];
    /** Documents of originator */
    documentsOfOriginator: DocumentUserGroup;
    /** Documents of actors */
    documentsOfActors: Array<Maybe<DocumentUserGroup>>;
    /** Document upload assistant */
    uploadAssistant: DocumentUploadAssistant;
    /** Last uploaded document */
    lastUploadedDocument?: Maybe<Document>;
    /** documents of originator */
    originator: DocumentUserGroup;
    /** documents of actors */
    actors: Array<Maybe<DocumentUserGroup>>;
    /** history of uploaded documents */
    history: Array<Maybe<Document>>;
    /** document types for upload */
    uploadableDocumentTypes: Array<Maybe<DocumentType>>;
    /** returns true if banner [Missing TIN] must be shown */
    showBannerMissingTIN: Scalars['Boolean'];
};


export type DocumentsuploadUrlArgs = {
    userId: Scalars['Int'];
    type?: Maybe<DocumentTypeEnum>;
    side?: Maybe<DocumentSideEnum>;
};


export type DocumentslastUploadedDocumentArgs = {
    type: DocumentTypeEnum;
    userId?: Maybe<Scalars['Int']>;
};

export enum DocumentSideEnum {
    frontside = 'frontside',
    backside = 'backside',
    entireDocument = 'entireDocument'
}

/** Documents mutations */
export type DocumentsMutation = {
    __typename?: 'DocumentsMutation';
    /** update document after upload */
    updateDocumentAfterUpload: DocumentsMutationUpdateResult;
    /** delete a document by document Id */
    deleteDocument: Array<Maybe<Document>>;
    /** skip backside upload for assistent */
    skipBacksideUpload: Scalars['Boolean'];
};


/** Documents mutations */
export type DocumentsMutationupdateDocumentAfterUploadArgs = {
    id: Scalars['Int'];
    type: DocumentTypeEnum;
    userId: Scalars['Int'];
    side?: Maybe<DocumentSideEnum>;
};


/** Documents mutations */
export type DocumentsMutationdeleteDocumentArgs = {
    id: Scalars['Int'];
};


/** Documents mutations */
export type DocumentsMutationskipBacksideUploadArgs = {
    type: DocumentTypeEnum;
    userId: Scalars['Int'];
};

/** Update document result */
export type DocumentsMutationUpdateResult = {
    __typename?: 'DocumentsMutationUpdateResult';
    /** Is successfully updated */
    success?: Maybe<Scalars['Boolean']>;
    /** Data */
    document?: Maybe<Document>;
};

export enum DocumentStatusEnum {
    ok = 'ok',
    checking = 'checking',
    rejected = 'rejected',
    missing = 'missing'
}

export type DocumentType = {
    __typename?: 'DocumentType';
    id: Scalars['Int'];
    name: DocumentTypeEnum;
};

export enum DocumentTypeEnum {
    identityProof = 'identityProof',
    idShot = 'idShot',
    vat = 'vat',
    businnesRegistration = 'businnesRegistration',
    persoSnapshot = 'persoSnapshot',
    addressProof = 'addressProof',
    gemaNachweis = 'gemaNachweis',
    salesContract = 'salesContract',
    transferOwnership = 'transferOwnership',
    selfProtectionStatement = 'selfProtectionStatement',
    modelReleaseForm = 'modelReleaseForm',
    other = 'other'
}

export type DocumentTypeGroup = {
    __typename?: 'DocumentTypeGroup';
    /** document type */
    type: DocumentTypeEnum;
    /** status of last (newest) document */
    status: DocumentGroupStatusEnum;
    /** is document type required for payout */
    isRequired: Scalars['Boolean'];
    /** is document uploaded and verified */
    isCompleted: Scalars['Boolean'];
    /** example document */
    exampleDocumentUrl?: Maybe<Scalars['Url']>;
    /** template document */
    templateDocumentUrl?: Maybe<Scalars['Url']>;
    /** documents hitting this document type */
    documents: Array<Maybe<Document>>;
};

export type DocumentUploadAssistant = {
    __typename?: 'DocumentUploadAssistant';
    /** document upload flow */
    flow: DocumentUploadAssistantFlow;
};


export type DocumentUploadAssistantflowArgs = {
    flowType: DocumentUploadAssistantTypeEnum;
    flowStep?: Maybe<DocumentUploadAssistantStepEnum>;
    userId?: Maybe<Scalars['Int']>;
};

export type DocumentUploadAssistantFlow = {
    __typename?: 'DocumentUploadAssistantFlow';
    /** User ID */
    userId: Scalars['Int'];
    /** Firstnane */
    firstname: Scalars['String'];
    /** Lastname */
    lastname: Scalars['String'];
    /** Are all required steps complete */
    allStepsComplete?: Maybe<Scalars['Boolean']>;
    /** Steps of assistant */
    steps: Array<DocumentUploadAssistantStep>;
    /** Current step */
    currentStep?: Maybe<DocumentUploadAssistantStep>;
};

export type DocumentUploadAssistantStep = {
    __typename?: 'DocumentUploadAssistantStep';
    /** Step name */
    name: DocumentUploadAssistantStepEnum;
    /** Status of step */
    status: DocumentUploadAssistantStepStatusEnum;
    /** Document type for after upload processes */
    documentType: DocumentTypeEnum;
    /** Document type ID for upload interface */
    documentTypeId?: Maybe<Scalars['Int']>;
    /** Upload URL */
    uploadUrl?: Maybe<Scalars['String']>;
    /** Template URL */
    templateUrl?: Maybe<Scalars['String']>;
    /** Example URL */
    exampleUrl?: Maybe<Scalars['String']>;
};

export enum DocumentUploadAssistantStepEnum {
    idShot = 'idShot',
    identityProofFrontside = 'identityProofFrontside',
    identityProofBackside = 'identityProofBackside',
    addressProof = 'addressProof',
    salesTaxClassification = 'salesTaxClassification',
    salesTaxObligation = 'salesTaxObligation',
    modelReleaseForm = 'modelReleaseForm'
}

export enum DocumentUploadAssistantStepStatusEnum {
    complete = 'complete',
    checking = 'checking',
    open = 'open',
    rejected = 'rejected'
}

export enum DocumentUploadAssistantTypeEnum {
    accountOwnerVerification = 'accountOwnerVerification',
    actorVerification = 'actorVerification'
}

export type DocumentUserGroup = {
    __typename?: 'DocumentUserGroup';
    /** user type */
    type: UserType;
    /** fullname of real person */
    realName: Scalars['String'];
    /** Firstnane */
    firstname: Scalars['String'];
    /** Lastname */
    lastname: Scalars['String'];
    /** user id of real person */
    userId: Scalars['UserId'];
    /** document types */
    documentTypes: Array<Maybe<DocumentTypeGroup>>;
    /** incomplete required document types */
    incompleteRequiredTypes: Array<Maybe<DocumentTypeGroup>>;
};


export type DocumentUserGroupdocumentTypesArgs = {
    type?: Maybe<Array<Maybe<DocumentTypeEnum>>>;
};

export type FileStorageSettings = {
    __typename?: 'FileStorageSettings';
    /** File storage type */
    type: FileStorageTypeEnum;
    /** Upload URL */
    uploadEndpoint: Scalars['Url'];
    /** Auth token */
    token: Scalars['String'];
};

export enum FileStorageTypeEnum {
    /** file storage inside VXServices Telegram */
    VXSERVICES_TELEGRAM = 'VXSERVICES_TELEGRAM',
    /** file storage for user media (not implemented yet, just an idea) */
    USER_MEDIA_WRAPPER = 'USER_MEDIA_WRAPPER'
}

export enum FileTypeEnum {
    /** picture type */
    PICTURE = 'PICTURE',
    /** undefined type */
    UNDEFINED = 'UNDEFINED',
    /** video type */
    VIDEO = 'VIDEO'
}

export type FormData = {
    __typename?: 'FormData';
    value: Scalars['String'];
    label: Scalars['String'];
};

export type FtpCredentials = {
    __typename?: 'FtpCredentials';
    host: Scalars['String'];
    port: Scalars['Int'];
    username: Scalars['String'];
    password: Scalars['String'];
};

export enum GenderEnum {
    male = 'male',
    female = 'female',
    transgender = 'transgender'
}

export type GenericResponse = {
    __typename?: 'GenericResponse';
    status: ResponseStatus;
    data?: Maybe<Scalars['Json']>;
    error?: Maybe<Scalars['String']>;
};

export type GroupedLikes = {
    __typename?: 'GroupedLikes';
    /** User-ID */
    userId: Scalars['Int'];
    /** Username */
    userName: Scalars['String'];
    /** Avatar-URL */
    avatarUrl: Scalars['String'];
};

export type Heatmap = {
    __typename?: 'Heatmap';
    /** Heatmap status */
    status: CommonSwitchStatusEnum;
    /** Is heatmap enabled */
    isEnabled: Scalars['Boolean'];
    /** Texts */
    texts: Array<Maybe<LocalizedText>>;
};

export type HeatmapInput = {
    /** Hitmap status */
    status?: Maybe<CommonSwitchStatusEnum>;
    /** Is hitmap enabled */
    isEnabled?: Maybe<Scalars['Boolean']>;
    /** Hitmap texts */
    texts?: Maybe<Array<Maybe<LocalizedTextInput>>>;
};

export type InstagramInfo = {
    __typename?: 'InstagramInfo';
    /** Instagram link */
    url: Scalars['String'];
    /** Status */
    status: InstagramStatusEnum;
    /** Date when new instagram request is allowed */
    rejectedUntil?: Maybe<Scalars['DateTime']>;
};

export enum InstagramStatusEnum {
    noInstaAccount = 'noInstaAccount',
    notRequested = 'notRequested',
    new = 'new',
    accepted = 'accepted',
    rejected = 'rejected'
}

export type InstantMessaging = {
    __typename?: 'InstantMessaging';
    /** Account */
    account: InstantMessagingAccount;
    /** Channel list */
    channels: Array<InstantMessagingChannel>;
    /** Messages */
    messages: Array<InstantMessagingMessage>;
};


export type InstantMessagingaccountArgs = {
    platform: InstantMessagingPlatformEnum;
};


export type InstantMessagingchannelsArgs = {
    platform: InstantMessagingPlatformEnum;
    sort?: Maybe<InstantMessagingChannelsSortEnum>;
    limit?: Maybe<Scalars['Int']>;
    lastSeen?: Maybe<Scalars['String']>;
};


export type InstantMessagingmessagesArgs = {
    platform: InstantMessagingPlatformEnum;
    channelId: Scalars['ID'];
    sort?: Maybe<InstantMessagingMessagesSortEnum>;
    limit?: Maybe<Scalars['Int']>;
    lastSeen?: Maybe<Scalars['String']>;
};

export type InstantMessagingAccount = {
    __typename?: 'InstantMessagingAccount';
    /** Returns true if account is active */
    active: Scalars['Boolean'];
    /** Date of initialisation */
    initialized?: Maybe<Scalars['DateTime']>;
    /** Returns true if account is initialized */
    isInitialized: Scalars['Boolean'];
    /** Token */
    token?: Maybe<Scalars['String']>;
    /** Status */
    status: TelegramStatusEnum;
};

export type InstantMessagingChannel = {
    __typename?: 'InstantMessagingChannel';
    /** Channel ID */
    id: Scalars['ID'];
    /** Platform */
    platform: InstantMessagingPlatformEnum;
    /** Channel name */
    displayName: Scalars['String'];
    /** Information about last message in channel */
    lastMessage?: Maybe<InstantMessagingMessage>;
};

export enum InstantMessagingChannelsSortEnum {
    /** sort channels by last message type ASC, last message sent at ASC */
    LASTMESSAGE__TYPE_ASC__SENTAT_ASC = 'LASTMESSAGE__TYPE_ASC__SENTAT_ASC',
    /** sort channels by last message type ASC, last message sent at DESC */
    LASTMESSAGE__TYPE_ASC__SENTAT_DESC = 'LASTMESSAGE__TYPE_ASC__SENTAT_DESC'
}

export type InstantMessagingMessage = {
    __typename?: 'InstantMessagingMessage';
    /** Message ID */
    id: Scalars['ID'];
    /** Platform */
    platform: InstantMessagingPlatformEnum;
    /** Message type */
    type: InstantMessagingMessageTypeEnum;
    /** Channel ID */
    channelId: Scalars['ID'];
    /** Message text */
    text: Scalars['String'];
    /** Attachment */
    attachment?: Maybe<InstantMessagingMessageAttachment>;
    /** Sending date */
    sentAt: Scalars['DateTime'];
    /** Delivery date */
    deliveredAt?: Maybe<Scalars['DateTime']>;
    /** Receive date */
    receivedAt?: Maybe<Scalars['DateTime']>;
    /** Reading date */
    readAt?: Maybe<Scalars['DateTime']>;
    /** Addidtional information about message */
    meta: InstantMessagingMessageMeta;
};

export type InstantMessagingMessageAttachment = {
    __typename?: 'InstantMessagingMessageAttachment';
    /** ID */
    id: Scalars['Int'];
    /** Universally Unique Identifier */
    uuid: Scalars['String'];
    /** File type */
    type: FileTypeEnum;
    /** File URL */
    url: Scalars['String'];
    /** File dimension */
    dimensions?: Maybe<Dimensions>;
};

export type InstantMessagingMessageInput = {
    /** Platform */
    platform: InstantMessagingPlatformEnum;
    /** Channel ID */
    channelId?: Maybe<Scalars['ID']>;
    /** Message text */
    text: Scalars['String'];
    /** Attachment */
    attachment?: Maybe<Scalars['String']>;
    /** Sending date */
    sendAt?: Maybe<Scalars['DateTime']>;
};

export type InstantMessagingMessageMeta = {
    __typename?: 'InstantMessagingMessageMeta';
    /** Additional information about a control message */
    control?: Maybe<Scalars['String']>;
};

export enum InstantMessagingMessagesSortEnum {
    /** sort channels by sent at ASC */
    MESSAGE__SENTAT_ASC = 'MESSAGE__SENTAT_ASC',
    /** sort messages by sent at DESC */
    MESSAGE__SENTAT_DESC = 'MESSAGE__SENTAT_DESC'
}

export enum InstantMessagingMessageTypeEnum {
    /** incoming messages */
    INCOMING = 'INCOMING',
    /** outgoing broadcast messages */
    OUTGOING_BROADCAST = 'OUTGOING_BROADCAST',
    /** outgoing single messages */
    OUTGOING_SINGLE = 'OUTGOING_SINGLE'
}

export type InstantMessagingMutation = {
    __typename?: 'InstantMessagingMutation';
    /** Send a message */
    sendMessage?: Maybe<InstantMessagingMessage>;
    /** Send a broadcast */
    sendBroadcast?: Maybe<InstantMessagingMessage>;
    /** Request a telegram-feature */
    requestTelegram: Scalars['Boolean'];
};


export type InstantMessagingMutationsendMessageArgs = {
    message: InstantMessagingMessageInput;
};


export type InstantMessagingMutationsendBroadcastArgs = {
    message: InstantMessagingMessageInput;
};

export enum InstantMessagingPlatformEnum {
    /** Telegram */
    TELEGRAM = 'TELEGRAM'
}

export type InvalidSignupField = {
    __typename?: 'InvalidSignupField';
    /** Infalid field */
    fieldName: Scalars['String'];
    /** Validation result */
    validationResult: ValidationResult;
};


/** Key Accounter */
export type KeyAccounter = {
    __typename?: 'KeyAccounter';
    /** Accounter name */
    contactName: Scalars['String'];
    /** E-Mail */
    contactEmail?: Maybe<Scalars['String']>;
    /** Phone number */
    contactPhoneNumber?: Maybe<Scalars['String']>;
};

export type KeyAccounting = {
    __typename?: 'KeyAccounting';
    /** Status */
    status?: Maybe<KeyAccountingStatus>;
    /** Is key accounting request allowed */
    isRequestAllowed: Scalars['Boolean'];
    /** Is profile complete */
    isProfileComplete: Scalars['Boolean'];
};

export enum KeyAccountingStatus {
    yes = 'yes',
    no = 'no',
    checking = 'checking',
    test = 'test'
}

/** Locales */
export enum Locale {
    /** german */
    de_DE = 'de_DE',
    /** english */
    en_US = 'en_US'
}

export type LocalizedText = {
    __typename?: 'LocalizedText';
    language?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type LocalizedTextInput = {
    language?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    translate?: Maybe<Scalars['Boolean']>;
};

export type LoginAccountLockedResult = LoginResultInterface & {
    __typename?: 'LoginAccountLockedResult';
    /** ID of locked user */
    userId: Scalars['ID'];
    /** Returns true if user logged in (in this case always false) */
    wasSuccessful: Scalars['Boolean'];
    /** Information about locking */
    title: Scalars['String'];
    /** Additional information about locking */
    message: Scalars['String'];
    /** Hotline code */
    hotlineCode: Scalars['String'];
    /** Locked until date */
    lockedUntil?: Maybe<Scalars['DateTime']>;
};

export type LoginInvalidCredentialsResult = LoginResultInterface & {
    __typename?: 'LoginInvalidCredentialsResult';
    /** Returns true if user logged in (in this case always false) */
    wasSuccessful: Scalars['Boolean'];
    /** Error message */
    message: Scalars['String'];
};

export type LoginResult = LoginSuccessResult | LoginInvalidCredentialsResult | LoginAccountLockedResult;

export type LoginResultInterface = {
    /** Returns true if user logged in */
    wasSuccessful: Scalars['Boolean'];
};

export type LoginSuccessResult = LoginResultInterface & {
    __typename?: 'LoginSuccessResult';
    /** Returns true if user logged in (in this case always true) */
    wasSuccessful: Scalars['Boolean'];
    /** Returns information about user */
    user: AuthUser;
    /** Redirect URL */
    redirectUrl?: Maybe<Scalars['String']>;
};

export type LovenseToyRequirements = {
    __typename?: 'LovenseToyRequirements';
    /** Is female */
    isFemale: Scalars['Boolean'];
    /** Does speaks german */
    doesSpeaksGerman: Scalars['Boolean'];
    /** Is profile complete */
    isProfileComplete: Scalars['Boolean'];
};

export type LovenseToySettings = {
    __typename?: 'LovenseToySettings';
    /** Status */
    status: LovenseToyStatusEnum;
    /** New request date if lovenseToy rejected */
    newRequestDate?: Maybe<Scalars['DateTime']>;
    /** LovenseToy requirements */
    requirements: LovenseToyRequirements;
};

export enum LovenseToyStatusEnum {
    /** Status: not allowed */
    notAllowed = 'notAllowed',
    /** Status: not set */
    notSet = 'notSet',
    /** Status: requested */
    requested = 'requested',
    /** Status: rejected */
    rejected = 'rejected',
    /** Status: accepted */
    accepted = 'accepted'
}

export type Mailing = {
    __typename?: 'Mailing';
    /** Mailing-ID */
    id: Scalars['Int'];
    /** Mailing name */
    name: Scalars['String'];
    /** Returns true if mailing is editable */
    editable: Scalars['Boolean'];
    /** Mailing type */
    type: MailingTypeEnum;
    /** Status */
    status: MailingStatusEnum;
    /** Progress in percent */
    progress?: Maybe<Scalars['Float']>;
    /** Mailing content */
    content: MailingIContent;
    /** Groups of recipients */
    recipientsGroups: MailingRecipientsGroups;
    /** Recipients filter */
    recipientsFilters: MailingRecipientsFilters;
    /** Number of delivered messages */
    shipmentRecipientsCount: Scalars['Int'];
    /** Date of creation */
    created: Scalars['DateTime'];
    /** Date of modification */
    modified: Scalars['DateTime'];
    /** Date of publishing */
    published?: Maybe<Scalars['DateTime']>;
    /** Message price */
    price?: Maybe<Scalars['Float']>;
    /** Tipping Link added */
    isTipping?: Maybe<Scalars['Boolean']>;
    /** Sticky Message enabled */
    isSticky?: Maybe<Scalars['Boolean']>;
};

export type MailingContentEmailPort25 = MailingIContent & {
    __typename?: 'MailingContentEmailPort25';
    /** Subject */
    subject: Scalars['String'];
    /** Message as text */
    text: Scalars['String'];
    /** Message as html */
    textHtml: Scalars['String'];
    /** Language */
    lang: ApiLang;
    /** E-Mail template */
    template: MailingTemplateEnum;
};

export type MailingContentInput = {
    /** Language */
    lang?: Maybe<ApiLang>;
    /** Mailing text */
    text: Scalars['String'];
    /** Mailing html */
    textHtml?: Maybe<Scalars['String']>;
    /** Subject */
    subject?: Maybe<Scalars['String']>;
    /** Attachment */
    attachment?: Maybe<AttachmentInput>;
    /** Template type */
    template?: Maybe<MailingTemplateEnum>;
    /** Tipping message */
    tipping?: Maybe<Scalars['Boolean']>;
    /** Sticky message */
    sticky?: Maybe<Scalars['Boolean']>;
};

export type MailingContentInstantMessenger = MailingIContent & {
    __typename?: 'MailingContentInstantMessenger';
    /** Language */
    lang: ApiLang;
    /** Text */
    text: Scalars['String'];
    /** Attachment */
    attachment?: Maybe<Attachment>;
};

export type MailingForm = {
    __typename?: 'MailingForm';
    /** Groups of recipients */
    recipientsGroups: MailingRecipientsGroups;
    /** Recipients filter */
    recipientsFilters: MailingRecipientsFilters;
    /** File storage settings */
    fileStorageSettings?: Maybe<FileStorageSettings>;
};

export type MailingIContent = {
    /** Language */
    lang?: Maybe<ApiLang>;
};

export type MailingInput = {
    /** Mailing-ID */
    id?: Maybe<Scalars['Int']>;
    /** Mailing type */
    type: MailingTypeEnum;
    /** Recipients group */
    recipientsGroup: MailingRecipientsGroupsEnum;
    /** Filters of recipients group */
    recipientsGroupFilters: Array<MailingRecipientsGroupsFiltersEnum>;
    /** Content */
    content?: Maybe<MailingContentInput>;
    /** Publish date */
    published?: Maybe<Scalars['DateTime']>;
    /** Price of message */
    price?: Maybe<Scalars['Float']>;
};

export type MailingRecipientsFilters = {
    __typename?: 'MailingRecipientsFilters';
    /** Mailing options */
    options: Array<OptionNode>;
    /** Selection */
    selected: Array<MailingRecipientsGroupsFiltersEnum>;
};

export type MailingRecipientsGroups = {
    __typename?: 'MailingRecipientsGroups';
    /** Mailing options */
    options: Array<OptionNode>;
    /** Selection */
    selected?: Maybe<MailingRecipientsGroupsEnum>;
};

export enum MailingRecipientsGroupsEnum {
    /** VISIT-X Favourites */
    VXMODELS_VISITX_FAVOURITES = 'VXMODELS_VISITX_FAVOURITES',
    /** VISIT-X Promolink Users */
    VXMODELS_PROMOLINK_USERS = 'VXMODELS_PROMOLINK_USERS',
    /** Landing Page Users */
    LANDING_PAGE_USERS = 'LANDING_PAGE_USERS',
    /** VX-PAGES Users (Telegram Free Users) */
    VXPAGES_TELEGRAM_NEWSLETTER = 'VXPAGES_TELEGRAM_NEWSLETTER',
    /** Telegram */
    TELEGRAM = 'TELEGRAM'
}

export enum MailingRecipientsGroupsFiltersEnum {
    /** only users, who are age verified */
    AGE_VERIFIED = 'AGE_VERIFIED',
    /** only users, who did not receive messages in the last 3 days */
    DID_NOT_RECEIVED_MESSAGES_LAST_3_DAYS = 'DID_NOT_RECEIVED_MESSAGES_LAST_3_DAYS',
    /** only users, who never recharged the credit */
    NEVER_RECHARGED_CREDIT = 'NEVER_RECHARGED_CREDIT',
    /** only users, who are registered in the last 7 days */
    REGISTERED_LAST_7_DAYS = 'REGISTERED_LAST_7_DAYS',
    /** telegram free users */
    TELEGRAM_FREE = 'TELEGRAM_FREE',
    /** telegram VIP users */
    TELEGRAM_VIP = 'TELEGRAM_VIP'
}

export type MailingRecipientsList = {
    __typename?: 'MailingRecipientsList';
    /** Mailing type */
    type: MailingTypeEnum;
    /** Number of recipients */
    count: Scalars['Int'];
};

export type Mailings = {
    __typename?: 'Mailings';
    /** File storage settings */
    fileStorageSettings?: Maybe<FileStorageSettings>;
    /** Groups of recipients */
    recipientsGroups: MailingRecipientsGroups;
    /** Recipients filter */
    recipientsFilters: MailingRecipientsFilters;
    /** Recipients list */
    recipients: MailingRecipientsList;
    /** Mailing by ID */
    mailing?: Maybe<Mailing>;
    /** Mailings list */
    mailings: Array<Mailing>;
};


export type MailingsfileStorageSettingsArgs = {
    type: MailingTypeEnum;
};


export type MailingsrecipientsGroupsArgs = {
    type: MailingTypeEnum;
};


export type MailingsrecipientsFiltersArgs = {
    group: MailingRecipientsGroupsEnum;
};


export type MailingsrecipientsArgs = {
    type: MailingTypeEnum;
    group: MailingRecipientsGroupsEnum;
    filter: Array<MailingRecipientsGroupsFiltersEnum>;
};


export type MailingsmailingArgs = {
    id: Scalars['Int'];
};


export type MailingsmailingsArgs = {
    type: MailingTypeEnum;
    group?: Maybe<Array<MailingRecipientsGroupsEnum>>;
    sort?: Maybe<MailingSortEnum>;
    status?: Maybe<Array<Maybe<MailingStatusEnum>>>;
    lastSeenId?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type MailingsMutation = {
    __typename?: 'MailingsMutation';
    /** Save mailing */
    save: Mailing;
    /** Publish mailing */
    publish: Mailing;
    /** Test mailing */
    test: MailingsTestResult;
    /** Delete mailing */
    delete: Scalars['Boolean'];
};


export type MailingsMutationsaveArgs = {
    mailing?: Maybe<MailingInput>;
};


export type MailingsMutationpublishArgs = {
    mailing?: Maybe<MailingInput>;
};


export type MailingsMutationtestArgs = {
    mailing?: Maybe<MailingInput>;
    recipient: Scalars['String'];
};


export type MailingsMutationdeleteArgs = {
    id: Scalars['Int'];
};

export enum MailingSortEnum {
    /** sort by created date ascending */
    CREATED_ASC = 'CREATED_ASC',
    /** sort by created date ascending */
    CREATED_DESC = 'CREATED_DESC'
}

export enum MailingStatusEnum {
    /** mailing in draft or just created */
    DRAFT = 'DRAFT',
    /** mailing review accepted by support */
    REVIEW_ACCEPTED = 'REVIEW_ACCEPTED',
    /** mailing waiting for review by support */
    REVIEW_PENDING = 'REVIEW_PENDING',
    /** mailing is in review by support */
    REVIEW_PROGRESS = 'REVIEW_PROGRESS',
    /** mailing review rejected by support */
    REVIEW_REJECTED = 'REVIEW_REJECTED',
    /** mailing is successfully sent */
    SHIPMENT_DONE = 'SHIPMENT_DONE',
    /** mailing sending failed */
    SHIPMENT_FAILED = 'SHIPMENT_FAILED',
    /** mailing is waiting to be send */
    SHIPMENT_PENDING = 'SHIPMENT_PENDING',
    /** mailing shipment is prepared (recipient list is fetched, etc.) */
    SHIPMENT_PREPARED = 'SHIPMENT_PREPARED',
    /** mailing sending in progress */
    SHIPMENT_PROGRESS = 'SHIPMENT_PROGRESS'
}

export type MailingsTestResult = {
    __typename?: 'MailingsTestResult';
    /** Returns true if test is successful */
    success: Scalars['Boolean'];
    /** Error message */
    message?: Maybe<Scalars['String']>;
};

export enum MailingTemplateEnum {
    DARK = 'DARK',
    LIGHT = 'LIGHT'
}

export enum MailingTypeEnum {
    /** VXModels Powermailing (deprecated Switched off on 22.01.2024)  */
    VXMODELS_POWERMAILING = 'VXMODELS_POWERMAILING',
    /** VX-PAGES mailings send via Email (deprecated Switched off on 22.01.2024) */
    VXPAGES_EMAIL = 'VXPAGES_EMAIL',
    /** VX-PAGES mailings send via VXMESSENGER (deprecated Switched off on 22.01.2024) */
    VXPAGES_VXMESSENGER = 'VXPAGES_VXMESSENGER',
    /** Mailings send via Telegram */
    TELEGRAM = 'TELEGRAM'
}

export type MarketingSettings = {
    __typename?: 'MarketingSettings';
    /** Participation in VISIT-X marketing campaigns */
    hasVXMarketing: Scalars['Boolean'];
    /** Cross marketing mails */
    hasCrossMarketing: Scalars['Boolean'];
    /** Reactivation emails to user */
    hasUserReactivation: Scalars['Boolean'];
};

export type MediaAlbum = {
    __typename?: 'MediaAlbum';
    /** User-ID */
    userId: Scalars['Int'];
    /** Album-ID (UMA_ID) */
    id: Scalars['Int'];
    /** Media type */
    mediaType: Scalars['String'];
    /** Media type name */
    mediaTypeName: Scalars['String'];
    /** Returns true if media is video */
    isVideoAlbum: Scalars['Boolean'];
    /** Status of album */
    status: MediaStatus;
    /** Returns true if album is deletable */
    isDeletable: Scalars['Boolean'];
    /** Rejection reason (if album was rejected) */
    rejectionReason: Scalars['String'];
    /** Creation date */
    created: Scalars['DateTime'];
    /** Release (check) date */
    released?: Maybe<Scalars['DateTime']>;
    /** Returns true if release date can be changed */
    isReleaseDateEditable: Scalars['Boolean'];
    /** Price of album */
    price: Scalars['Float'];
    /** Max. price */
    maxPrice: Scalars['Float'];
    /** Discount in percent */
    discountPercent?: Maybe<Scalars['Int']>;
    /** Start date of discount */
    discountStart?: Maybe<Scalars['DateTime']>;
    /** End date of discount */
    discountEnd?: Maybe<Scalars['DateTime']>;
    /** Number of sales */
    salesTotal: Scalars['Int'];
    /** List of sales */
    salesItems: Array<Maybe<MediaSale>>;
    /** Number of feedbacks */
    feedbacksTotal: Scalars['Int'];
    /** Number of new (unreadable) feedbacks */
    feedbacksNew: Scalars['Int'];
    /** List of feedbacks */
    feedbacksItems: Array<Maybe<MediaFeedback>>;
    /** Album titles for VISIT-X and contentpartner */
    titles: Array<Maybe<LocalizedText>>;
    /** Album descriptions for VISIT-X and contentpartner */
    descriptions: Array<Maybe<LocalizedText>>;
    /** Tags of album */
    tags: Array<Maybe<Scalars['String']>>;
    /** Number of likes */
    likes: Scalars['Int'];
    /** Grouped likes */
    groupedLikes: Array<Maybe<GroupedLikes>>;
    /** Number of dislikes */
    dislikes: Scalars['Int'];
    /** ID of preview picture (FSK-16) */
    previewPicId16: Scalars['Int'];
    /** ID of preview picture (FSK-18) */
    previewPicId18: Scalars['Int'];
    /** Preview picture (FSK-16) */
    previewPicture16?: Maybe<MediaPicture>;
    /** Preview picture (FSK-18) */
    previewPicture18?: Maybe<MediaPicture>;
    /** Main preview picture (rounded avatar) */
    mainPicture?: Maybe<MediaPicture>;
    /** Title picture */
    titlePicture?: Maybe<MediaPicture>;
    /** List of all pictures in album */
    pictures: Array<Maybe<MediaPicture>>;
    /** Video in album */
    video?: Maybe<MediaVideo>;
    /** Media-Contest */
    contest?: Maybe<Contest>;
    /** Returns true if album selected for 365daysCalendar */
    hasFlag365daysCalendar: Scalars['Boolean'];
    /** Returns true if album comes from pornme */
    hasFlagPornmeMigration: Scalars['Boolean'];
    /** Returns true if album has a flag SHARED_VIDEO */
    hasFlagSharedAlbum: Scalars['Boolean'];
    /** Returns true if all ID-Proofs of actors uploaded */
    allIDCardsUploaded: Scalars['Boolean'];
    /** Returns true if all ModelReleaseForms of actors uploaded */
    allModelReleaseFormsUploaded: Scalars['Boolean'];
    /** Average star rating */
    starRating: Scalars['Float'];
    /** Number of released pictures in album */
    numberOfReleasedPictures: Scalars['Int'];
    /** Number of all pictures in album */
    numberOfAllPictures: Scalars['Int'];
    /** Number of rejected pictures in album */
    numberOfRejectedPictures: Scalars['Int'];
    /** Number of new (unchecked) pictures in album */
    numberOfUncheckedPictures: Scalars['Int'];
    /** DailyBonus information */
    dailyBonus?: Maybe<MediaDailyBonus>;
    /** VIP-30 information */
    vip30Scheduling?: Maybe<MediaVip30Scheduling>;
    /** Number of sales on VISIT-X */
    salesTotalVX: Scalars['Int'];
    /** Total amount of sales on VISIT-X */
    salesAmountVX: Scalars['Float'];
    /** Sales statistic */
    salesStatItems: Array<Maybe<MediaSalesStatItem>>;
    /** Returns true if album has a flag SHADOW_BAN */
    hasFlagShadowBan: Scalars['Boolean'];
};


export type MediaAlbumgroupedLikesArgs = {
    limit?: Maybe<Scalars['Int']>;
    avatarSize?: Maybe<PictureSizeEnum>;
};

export type MediaAlbumInput = {
    /** Album ID (UMA_ID) */
    id?: Maybe<Scalars['Int']>;
    /** Album type */
    type: MediaType;
    /** Album price */
    price?: Maybe<Scalars['Float']>;
    /** Discount in percent */
    discountPercent?: Maybe<Scalars['Int']>;
    /** Start-Date of discount */
    discountStart?: Maybe<Scalars['DateTime']>;
    /** End-Date of discount */
    discountEnd?: Maybe<Scalars['DateTime']>;
    /** Titles for VISIT-X */
    titlesVisitX?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Descriptions for VISIT-X */
    descriptionsVisitX?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Album tags */
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Contest ID */
    contestId?: Maybe<Scalars['Int']>;
    /** Status of album: online, offline */
    status?: Maybe<MediaStatusInputEnum>;
    /** Previe picture ID for FSK-16-area */
    previewPicId16?: Maybe<Scalars['Int']>;
    /** Previe picture ID for FSK-18-area */
    previewPicId18?: Maybe<Scalars['Int']>;
    /** GeoLocation: longitude */
    longitude?: Maybe<Scalars['Float']>;
    /** GeoLocation: latitude */
    latitude?: Maybe<Scalars['Float']>;
    /** Album can be used for 365-days calendar */
    flag365daysCalendar?: Maybe<Calendar365daysInputEnum>;
    /** Release date of album */
    releaseDate?: Maybe<Scalars['DateTime']>;
    /** Album has all ID-Proofs of actors uploaded */
    allIDCardsUploaded?: Maybe<Scalars['Boolean']>;
    /** Album has all ModelReleaseForms of actors uploaded */
    allModelReleaseFormsUploaded?: Maybe<Scalars['Boolean']>;
};

export type MediaAlbums = {
    __typename?: 'MediaAlbums';
    /** Number of albums */
    albumsTotal: Scalars['Int'];
    /** List of albums */
    albums: Array<Maybe<MediaAlbum>>;
};

export enum MediaAlbumSortDirection {
    asc = 'asc',
    desc = 'desc'
}

export enum MediaAlbumSortFields {
    status = 'status',
    type = 'type',
    name = 'name',
    uploaded = 'uploaded',
    rating = 'rating',
    id = 'id',
    price = 'price',
    discount = 'discount',
    sales = 'sales'
}

export type MediaDailyBonus = {
    __typename?: 'MediaDailyBonus';
    modelContent: Array<Maybe<MediaDailyBonusModelContent>>;
};

export type MediaDailyBonusModelContent = {
    __typename?: 'MediaDailyBonusModelContent';
    contentId: Scalars['Int'];
    contentType: OfferContentTypeEnum;
    selectedFor?: Maybe<Scalars['DateTime']>;
    preSelectedFor?: Maybe<DateRange>;
};

export type MediaFeedback = {
    __typename?: 'MediaFeedback';
    /** Feedback ID */
    id: Scalars['Int'];
    /** Guest (customer) ID */
    guestId: Scalars['Int'];
    /** Guest (customer) username */
    guestLogin: Scalars['String'];
    /** Guest (customer) avatar */
    guestAvatar: Scalars['String'];
    /** Date of feedback */
    date: Scalars['DateTime'];
    /** Text: Feedback */
    text?: Maybe<Scalars['String']>;
    /** Text: Model answer on customer feedback */
    answer?: Maybe<Scalars['String']>;
    /** Returns true if model answered on customer feedback */
    isAnswered: Scalars['Boolean'];
};


export type MediaFeedbackguestAvatarArgs = {
    size?: Maybe<PictureSizeEnum>;
};

export type MediaMutation = {
    __typename?: 'MediaMutation';
    /** Saves a photo album */
    savePhotoAlbum?: Maybe<MediaAlbum>;
    /** Saves a video */
    saveVideo?: Maybe<MediaAlbum>;
    /** Deletes a photo album */
    deletePhotoAlbum?: Maybe<Scalars['Boolean']>;
    /** Deletes a video */
    deleteVideo?: Maybe<Scalars['Boolean']>;
    /** Deletes a picture */
    deletePicture?: Maybe<MediaAlbum>;
    /** Moves a picture to another album */
    movePicturesToAlbum?: Maybe<MediaAlbum>;
    /** Setss a picture as preview for album */
    setPreviewPicture?: Maybe<MediaAlbum>;
    /** Copy picture rating */
    copyPictureRating: Scalars['Boolean'];
    /** Moves picture on othe position in album */
    changePicturePosition?: Maybe<MediaAlbum>;
    /** Saves an answer on customer feedback */
    replyToComment?: Maybe<MediaAlbum>;
    /** Saves daily bonus */
    setDailyBonusOptInStatus: DailyBonusOptInStatusEnum;
};


export type MediaMutationsavePhotoAlbumArgs = {
    input?: Maybe<MediaAlbumInput>;
};


export type MediaMutationsaveVideoArgs = {
    input?: Maybe<MediaAlbumInput>;
};


export type MediaMutationdeletePhotoAlbumArgs = {
    albumId: Scalars['Int'];
};


export type MediaMutationdeleteVideoArgs = {
    albumId: Scalars['Int'];
};


export type MediaMutationdeletePictureArgs = {
    albumId: Scalars['Int'];
    pictureId: Scalars['Int'];
};


export type MediaMutationmovePicturesToAlbumArgs = {
    sourceAlbumId: Scalars['Int'];
    targetAlbumId: Scalars['Int'];
    pictures: Array<Maybe<MovedPicture>>;
};


export type MediaMutationsetPreviewPictureArgs = {
    albumId: Scalars['Int'];
    pictureId: Scalars['Int'];
    fsk: Scalars['Int'];
};


export type MediaMutationcopyPictureRatingArgs = {
    sourceAlbumId: Scalars['Int'];
    sourcePictureId: Scalars['Int'];
    targetAlbumId: Scalars['Int'];
    targetPictureId: Scalars['Int'];
};


export type MediaMutationchangePicturePositionArgs = {
    albumId: Scalars['Int'];
    pictureId: Scalars['Int'];
    newPosition: Scalars['Int'];
};


export type MediaMutationreplyToCommentArgs = {
    albumId: Scalars['Int'];
    commentId: Scalars['Int'];
    message?: Maybe<Scalars['String']>;
};


export type MediaMutationsetDailyBonusOptInStatusArgs = {
    status: DailyBonusOptInStatusEnum;
};

export enum MediaOrientation {
    portrait = 'portrait',
    landscape = 'landscape',
    String = 'String',
    Int = 'Int',
    Boolean = 'Boolean',
    Float = 'Float',
    ID = 'ID'
}

export type MediaPicture = {
    __typename?: 'MediaPicture';
    /** Album data of picture */
    album: MediaAlbum;
    /** Picture ID (UMP_ID) */
    id: Scalars['Int'];
    /** Date of creation */
    created: Scalars['DateTime'];
    /** Picture status */
    status: MediaStatus;
    /** Picture type */
    type: Scalars['String'];
    /** Age rating (FSK): 12, 15, 16, 18 */
    ageRating: Scalars['Int'];
    /** Width */
    width: Scalars['Int'];
    /** Height */
    height: Scalars['Int'];
    /** Aspect ratio */
    aspectRatio: Scalars['Float'];
    /** Orientation */
    orientation: MediaOrientation;
    /** Picture position in album */
    position: Scalars['Int'];
    /** URL of picture */
    url?: Maybe<Scalars['String']>;
    /** Returns true if picture is a preview-picture for this album */
    isPreviewPicture: Scalars['Boolean'];
    /** Returns true if picture was checked by admin */
    isChecked: Scalars['Boolean'];
    /** Rejection reason (if picture was rejected by admin) */
    rejectionReason?: Maybe<Scalars['String']>;
    /** Returns true if picture has type mainPreviewPicture (rounded avatar) */
    isMainProfilePicture: Scalars['Boolean'];
    /** Returns true if picture has type titlePreviewPicture */
    isTitleProfilePicture: Scalars['Boolean'];
    /** Returns true if picture has flag social-media */
    isSocial: Scalars['Boolean'];
    /** Returns true if picture has flag visitx-branded */
    isVisitXBrand: Scalars['Boolean'];
    /** Returns true if picture can be moved on first position */
    isMovingToPos0Allowed: Scalars['Boolean'];
    /** Returns true if picture can be set as preview for FSK-16-area */
    isAllowedToSetAsPreview16: Scalars['Boolean'];
    /** Returns true if picture can be set as preview for FSK-18-area */
    isAllowedToSetAsPreview18: Scalars['Boolean'];
    /** deprecated! only for compatibility with old Picture-Model! Please use album->released */
    released?: Maybe<Scalars['DateTime']>;
};


export type MediaPictureurlArgs = {
    size?: Maybe<PictureSizeEnum>;
};

export type MediaSale = {
    __typename?: 'MediaSale';
    /** Album */
    album?: Maybe<MediaAlbum>;
    /** Guest (customer) ID */
    guestId: Scalars['Int'];
    /** Guest (customer) login */
    guestlogin: Scalars['String'];
    /** Date of sale */
    date: Scalars['DateTime'];
    /** Guest (customer) price */
    guestAmount: Scalars['Float'];
    /** Sharing of model */
    hostAmount: Scalars['Float'];
    /** Price in credits */
    credits: Scalars['Float'];
    /** Returns true if sale occurred on VXPages-Platform */
    isProductVXPages?: Maybe<Scalars['Boolean']>;
    /** Returns true if sale type voucher */
    isTypeVoucher?: Maybe<Scalars['Boolean']>;
};

export type MediaSalesStatItem = {
    __typename?: 'MediaSalesStatItem';
    /** Product */
    product: Scalars['String'];
    /** Number of sales */
    salesTotal: Scalars['Int'];
    /** Amount total */
    amountTotal: Scalars['Float'];
};

export enum MediaStatus {
    online = 'online',
    rejected = 'rejected',
    deleted = 'deleted',
    broken = 'broken',
    transcoding = 'transcoding',
    new = 'new',
    offline = 'offline',
    rating = 'rating',
    hidden = 'hidden',
    unknown = 'unknown',
    move = 'move',
    planned = 'planned',
    ratingDelayed = 'ratingDelayed'
}

export enum MediaStatusInputEnum {
    online = 'online',
    offline = 'offline'
}

export enum MediaType {
    free = 'free',
    shop = 'shop',
    contest = 'contest',
    cultbabes = 'cultbabes',
    pool = 'pool',
    profile = 'profile',
    profile12 = 'profile12',
    profile16 = 'profile16',
    profile18 = 'profile18',
    lustagenten = 'lustagenten',
    lustagentenMail = 'lustagentenMail',
    homepageMediapool = 'homepageMediapool',
    all = 'all'
}

export type MediaVideo = {
    __typename?: 'MediaVideo';
    /** Album data of video */
    album: MediaAlbum;
    /** Video ID (UMV_ID) */
    id?: Maybe<Scalars['Int']>;
    /** Width */
    width: Scalars['Int'];
    /** Height */
    height: Scalars['Int'];
    /** Duration in seconds */
    duration: Scalars['Int'];
    /** Age rating (FSK): 12, 15, 16, 18 */
    ageRating: Scalars['Int'];
    /** Aspect ratio */
    aspectRatio: Scalars['Float'];
    /** Orientation */
    orientation: MediaOrientation;
    /** Date of creation */
    created: Scalars['DateTime'];
    /** File size in bytes */
    fileSize: Scalars['Int'];
    /** Original file name */
    originalFilename: Scalars['String'];
    /** GeoLocation: longitude */
    longitude: Scalars['Float'];
    /** GeoLocation: latitude */
    latitude: Scalars['Float'];
    /** Status of video */
    status: MediaStatus;
    /** URL of video */
    url: Scalars['String'];
    /** Returns true if video can be used as welcome-clip */
    isApprovedAsWelcomeclip: Scalars['Boolean'];
    /** Returns true if video was flagged as fetish-video */
    hasFlagFetish: Scalars['Boolean'];
    /** Returns true if flag fetish-video can be changed */
    isFlagFetishEditable: Scalars['Boolean'];
    /** Returns true if video has a flag SUSPECTED_CONTENT */
    hasFlagSuspectedContent: Scalars['Boolean'];
    /** @deprecated! only for compatibility with old Video-Model! Please use album->released */
    released?: Maybe<Scalars['DateTime']>;
    /** @deprecated! only for compatibility with old Video-Model! Please use album->titles */
    title?: Maybe<Scalars['String']>;
    /** @deprecated! only for compatibility with old Video-Model! Please use album->descriptions */
    description?: Maybe<Scalars['String']>;
    /** @deprecated! only for compatibility with old Video-Model! Please use album->previewPicture16 or album->previewPicture18 */
    previewPicture?: Maybe<MediaPicture>;
};


export type MediaVideourlArgs = {
    profile?: Maybe<VideoProfile>;
};

export type MediaVip30Scheduling = {
    __typename?: 'MediaVip30Scheduling';
    /** Month (1-12) */
    month: Scalars['Int'];
    year: Scalars['Int'];
};

export type MessengerStatistic = {
    __typename?: 'MessengerStatistic';
    /** Messages */
    messages: Scalars['Int'];
    /** Content sales */
    contentSales: Scalars['Int'];
};


/** The representation of a model */
export type Model = UserInterface & {
    __typename?: 'Model';
    /** Permissions */
    permissions: ModelPermissions;
    /** User id */
    id: Scalars['UserId'];
    /** Username */
    username: Scalars['String'];
    /** Is user registered mobile */
    isMobileRegistration: Scalars['Boolean'];
    /** Account status */
    status: ModelStatusEnum;
    /** Age verification information */
    avs: AvsStatus;
    /** Account information */
    account: Account;
    /** Linked documents */
    documents: Documents;
    /** Originator information */
    originator?: Maybe<Originator>;
    /** Contests information with model context */
    contests?: Maybe<Contests>;
    /** VXLive Teaser information */
    vxteasers?: Maybe<VXTeasers>;
    /** Profile: Properties and Texts */
    profile?: Maybe<Profile>;
    /** All planned tv shows by model */
    tvshows?: Maybe<ModelTVShow>;
    /** notifications */
    notifications: Notifications;
    /** generated adlinks */
    adlinks: Array<Maybe<Adlink>>;
    /** VXcash */
    vxcash: VXcash;
    /** platform */
    originScope: AppScope;
    /** Offset starts at 1, not at 0! */
    photoAlbums: MediaAlbums;
    /** Offset starts at 1, not at 0! */
    videos: MediaAlbums;
    transcoding: Array<UploadQueueItem>;
    avatar?: Maybe<Avatar>;
    wasOnlineWithSSW?: Maybe<Scalars['Boolean']>;
    isOnlineWithSSW?: Maybe<Scalars['Boolean']>;
    commonProfileFillingPercentage?: Maybe<Scalars['Int']>;
    visitxProfileFillingPercentage?: Maybe<Scalars['Int']>;
    isPayoutAllowed?: Maybe<Scalars['Boolean']>;
    extra: ModelExtra;
    dashboard?: Maybe<Dashboard>;
    ranking?: Maybe<Ranking>;
    mailings?: Maybe<Mailings>;
    terms?: Maybe<Terms>;
    bonuscodes?: Maybe<BonusCodes>;
    abo?: Maybe<Abo>;
    /** Instant messaging */
    instantMessaging: InstantMessaging;
    /**
     * The language passed during the signup call. Lowercase. Can have values which
     * are not in ApiLang. Does not necessarily match the current GUI language.
     */
    signupLanguage: Scalars['String'];
    /** Model Settings */
    settings: ModelSettings;
    /** Media management */
    media: ModelMedia;
    /** Service 0900 */
    service0900?: Maybe<Service0900>;
    /** Tooltip settings */
    tooltipSettings?: Maybe<TooltipSettings>;
    /** BeiAnrufSex */
    beiAnrufSex?: Maybe<BeiAnrufSex>;
    /** VideoChat */
    videoChat?: Maybe<VideoChat>;
    /** Has originator */
    hasOriginator: Scalars['Boolean'];
    /** Has actors */
    hasActors: Scalars['Boolean'];
    /** Top amateur */
    topAmateur: TopAmateur;
    /** VXModels challenges */
    vxChallenges?: Maybe<VXGChallenges>;
    /** Is model belongs to the studio */
    isStudio?: Maybe<Scalars['Boolean']>;
    /** Returns true if model has a webmasterId */
    hasWebmasterId: Scalars['Boolean'];
};


/** The representation of a model */
export type ModelcontestsArgs = {
    type?: Maybe<Array<Maybe<ContestType>>>;
    status?: Maybe<ContestStatus>;
    sort?: Maybe<ContestSort>;
};


/** The representation of a model */
export type ModelvxteasersArgs = {
    first?: Maybe<Scalars['Int']>;
    excludedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** The representation of a model */
export type ModelnotificationsArgs = {
    category?: Maybe<Array<Maybe<NotificationCategoryEnum>>>;
    showExpired?: Maybe<Scalars['Boolean']>;
    unreadOnly?: Maybe<Scalars['Boolean']>;
    timezone: Scalars['String'];
};


/** The representation of a model */
export type ModelphotoAlbumsArgs = {
    type?: Maybe<MediaType>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['Int']>;
    sortField?: Maybe<MediaAlbumSortFields>;
    sortDirection?: Maybe<MediaAlbumSortDirection>;
    product?: Maybe<ProductEnum>;
    imported?: Maybe<Scalars['Boolean']>;
};


/** The representation of a model */
export type ModelvideosArgs = {
    type?: Maybe<MediaType>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['Int']>;
    sortField?: Maybe<MediaAlbumSortFields>;
    sortDirection?: Maybe<MediaAlbumSortDirection>;
    product?: Maybe<ProductEnum>;
    imported?: Maybe<Scalars['Boolean']>;
};


/** The representation of a model */
export type ModelavatarArgs = {
    ageRating?: Maybe<Scalars['Int']>;
};

export type ModelExtra = {
    __typename?: 'ModelExtra';
    picturePoolUmaId: Scalars['Int'];
    mailingsUmaId: Scalars['Int'];
    /** @deprecated Use Model.terms.acceptance instead */
    hasAcceptedTermsAndConditions: Scalars['Boolean'];
    /** @deprecated Use Model.terms.acceptance instead */
    hasAcceptedPrivacyPolicy: Scalars['Boolean'];
    showMenuItemActors: Scalars['Boolean'];
    isNewRegistrationWithoutVerifiedEmail: Scalars['Boolean'];
    ftpCredentials?: Maybe<FtpCredentials>;
    sftpCredentials?: Maybe<FtpCredentials>;
    hasPremiumCooperation: Scalars['Boolean'];
};

export type ModelMedia = {
    __typename?: 'ModelMedia';
    /** Video Upload URL */
    videoUploadUrl: Scalars['String'];
    videoUploadTus?: Maybe<UploadDataTus>;
    /** Picture Upload URL */
    pictureUploadUrl: Scalars['String'];
    hasSubmittedAlbumsForDailyBonus: Scalars['Boolean'];
    /** Whether the model has agreed to the 365 conditions */
    dailyBonusOptInStatus: DailyBonusOptInStatusEnum;
    /** Preview picture FSK-12 (Picture on position 0 in sedcard12) */
    previewPicture12: PreviewPicture;
    /** Preview picture FSK-16 (Picture on position 0 in sedcard16) */
    previewPicture16: PreviewPicture;
    /** Preview picture FSK-18 (Picture on position 0 in sedcard18) */
    previewPicture18: PreviewPicture;
    /** Sold content */
    soldContent: Array<Maybe<MediaSale>>;
    video?: Maybe<MediaAlbum>;
};


export type ModelMediavideoUploadUrlArgs = {
    umaSubType?: Maybe<Scalars['Int']>;
};


export type ModelMediavideoUploadTusArgs = {
    umaSubType?: Maybe<Scalars['Int']>;
};


export type ModelMediapictureUploadUrlArgs = {
    umaId: Scalars['Int'];
    pictureType: Scalars['Int'];
    position?: Maybe<Scalars['Int']>;
    fsk?: Maybe<Scalars['Int']>;
    flags?: Maybe<Scalars['Int']>;
};


export type ModelMediasoldContentArgs = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    guestId?: Maybe<Scalars['Int']>;
    includeGifts?: Maybe<Scalars['Boolean']>;
    includeHiddenContent?: Maybe<Scalars['Boolean']>;
};


export type ModelMediavideoArgs = {
    albumId?: Maybe<Scalars['Int']>;
    uploadRequestId?: Maybe<Scalars['String']>;
};

/** model mutation */
export type ModelMutation = {
    __typename?: 'ModelMutation';
    /** functions regarding documents */
    documents: DocumentsMutation;
    /** functions regarding adlinks */
    adlinks: AdlinksMutation;
    /** function regarding notifications */
    notifications?: Maybe<NotificationsMutation>;
    /** vxgames challenges participation */
    vxChallenges: VXGChallengesMutation;
    /** Updates media albums */
    media: MediaMutation;
    /** Updates prices */
    prices: PricesMutation;
    /** Updates an accounting */
    accounting: AccountingMutation;
    /** Updates a profile */
    profile: ProfileMutation;
    /** powermailing */
    mailings: MailingsMutation;
    /** Terms */
    terms: TermsMutation;
    /** instant messaging */
    instantMessaging: InstantMessagingMutation;
    /** settings update */
    settings: SettingsMutation;
    /** Service0900 */
    service0900: Service0900Mutation;
    /** Tooltip settings */
    tooltipSettings: TooltipSettingsMutation;
    /** Send welcome email */
    sendWelcomeMail: GenericResponse;
    /** VXCash */
    vxcash: VXCashMutation;
    /** TVShow */
    tvshows: VXTVShowMutation;
};


/** model mutation */
export type ModelMutationsendWelcomeMailArgs = {
    email?: Maybe<Scalars['String']>;
};

export type ModelPermissions = {
    __typename?: 'ModelPermissions';
    isFinancesVisibleWithoutMasterPassword: Scalars['Boolean'];
    isChatPricesVisible: Scalars['Boolean'];
};

/** Model Settings */
export type ModelSettings = {
    __typename?: 'ModelSettings';
    /** Blocked countries */
    blockedCountries?: Maybe<Array<Scalars['String']>>;
    /** Lovense toy */
    lovenseToySettings: LovenseToySettings;
    /** Marketing settings */
    marketingSettings?: Maybe<MarketingSettings>;
    /** Chat settings */
    chatSettings?: Maybe<ChatSettings>;
};

/** Model account status */
export enum ModelStatusEnum {
    /** no restrictions */
    NO_RESTRICTIONS = 'NO_RESTRICTIONS',
    /** account is locked for sending, but has access to VXMODELS */
    LOCKED_BUT_ACCESS_TO_VXMODELS = 'LOCKED_BUT_ACCESS_TO_VXMODELS',
    /** sending is allowed, but VAT and ID-card is missing */
    SENDING_ALLOWED_NO_VAT_NO_IDCARD = 'SENDING_ALLOWED_NO_VAT_NO_IDCARD',
    /** sending is allowed, but VAT is missing */
    SENDING_ALLOWED_NO_VAT = 'SENDING_ALLOWED_NO_VAT',
    /** sending is allowed, but ID-card is missing */
    SENDING_ALLOWED_NO_IDCARD = 'SENDING_ALLOWED_NO_IDCARD',
    /** locked, because sender is underage */
    LOCKED_UNDERAGE = 'LOCKED_UNDERAGE',
    /** locked by support */
    LOCKED_SUPPORT = 'LOCKED_SUPPORT',
    /** risk, docs needed */
    RISK_DOCS_NEEDED = 'RISK_DOCS_NEEDED',
    /** risk, suspicious */
    RISK_SUSPICIOUS = 'RISK_SUSPICIOUS',
    /** risk, possible ID theft */
    RISK_POSSIBLE_ID_THEFT = 'RISK_POSSIBLE_ID_THEFT',
    /** violation, temporaly locked */
    VIOLATION_TEMP = 'VIOLATION_TEMP',
    /** violation, permanently locked */
    VIOLATION_PERM = 'VIOLATION_PERM',
    /** general lock */
    GENERAL_LOCK = 'GENERAL_LOCK',
    /** account closed, canceled by mail */
    CLOSED_CANCELED_BY_MAIL = 'CLOSED_CANCELED_BY_MAIL',
    /** account closed, canceled by phone */
    CLOSED_CANCELED_BY_PHONE = 'CLOSED_CANCELED_BY_PHONE',
    /** account closed, reactivation blocked */
    CLOSED_BLOCK_REACTIVATION = 'CLOSED_BLOCK_REACTIVATION',
    /** account closed tmporary */
    CLOSED_TEMPORARY = 'CLOSED_TEMPORARY',
    /** account closed, canceled by support */
    CLOSED_CANCELED_BY_SUPPORT = 'CLOSED_CANCELED_BY_SUPPORT'
}

export type ModelTVShow = {
    __typename?: 'ModelTVShow';
    /** Returns true if TVShow is enabled */
    enabled: Scalars['Boolean'];
    /** TVShow links */
    links: ModelTVShowLinks;
    /** Information about planned TVShows */
    planned: Array<TVShow>;
    /** replacement offer for next tv show */
    replacementOffer: Scalars['Boolean'];
};

export type ModelTVShowLinks = {
    __typename?: 'ModelTVShowLinks';
    /** Chat-Url */
    chat?: Maybe<Scalars['Url']>;
    /** Calendar-Url */
    calendar?: Maybe<Scalars['Url']>;
};

export type MovedPicture = {
    /** Picture ID */
    id: Scalars['Int'];
    /** New picture type */
    newType?: Maybe<Scalars['Int']>;
    /** New picture position */
    newPosition?: Maybe<Scalars['Int']>;
};

/** Root Mutation */
export type Mutation = {
    __typename?: 'Mutation';
    /** Authentication functions */
    auth?: Maybe<AuthMutation>;
    /**
     * Authentication functions @deprecated
     * @deprecated use auth instead
     */
    user?: Maybe<AuthMutation>;
    /** @restricted(role: VX_USER) */
    model?: Maybe<ModelMutation>;
};


/** Root Mutation */
export type MutationmodelArgs = {
    id?: Maybe<Scalars['UserId']>;
};

export type NavigationElement = {
    __typename?: 'NavigationElement';
    name: Scalars['String'];
    uri?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    meta: NavigationElementMeta;
    children: Array<Scalars['String']>;
};

export type NavigationElementMeta = {
    __typename?: 'NavigationElementMeta';
    icon?: Maybe<Scalars['String']>;
    action?: Maybe<Scalars['String']>;
};

export type Notification = {
    __typename?: 'Notification';
    /** Notification ID */
    id: Scalars['Int'];
    /** Model */
    model?: Maybe<Model>;
    /** notification type */
    type: NotificationType;
    /** active */
    active: Scalars['Boolean'];
    /** group */
    group?: Maybe<Scalars['String']>;
    /** role */
    role?: Maybe<Scalars['String']>;
    /** title */
    title?: Maybe<Scalars['String']>;
    /** text */
    text?: Maybe<Scalars['String']>;
    /** creation date */
    date: Scalars['DateTime'];
    /** show until */
    showUntil?: Maybe<Scalars['DateTime']>;
    /** params */
    params?: Maybe<Scalars['Json']>;
    /** priority */
    priority: Scalars['Int'];
    /** read date time */
    read?: Maybe<Scalars['DateTime']>;
};

export type NotificationCategory = {
    __typename?: 'NotificationCategory';
    id: Scalars['Int'];
    enum: NotificationCategoryEnum;
    /** localized name */
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
};

export enum NotificationCategoryEnum {
    common = 'common',
    directNotification = 'directNotification',
    document = 'document',
    finance = 'finance',
    marketing = 'marketing',
    medal = 'medal',
    mediacontent = 'mediacontent',
    homepage = 'homepage',
    profile = 'profile',
    ranking = 'ranking',
    system = 'system',
    snagbar = 'snagbar',
    newscenter = 'newscenter',
    vxgames2019 = 'vxgames2019',
    vxgames2020 = 'vxgames2020',
    vxchallenges = 'vxchallenges'
}

export type NotificationGroup = {
    __typename?: 'NotificationGroup';
    id: Scalars['Int'];
    name: NotificationGroupEnum;
    friendlyName: Scalars['String'];
    comment?: Maybe<Scalars['String']>;
};

export enum NotificationGroupEnum {
    /** Developer Accounts [akentner-TX, olegator, nwtest, dktest] */
    developer = 'developer',
    /** Models, die sich innerhalb der letzten 90 Tagen eingeloggt haben */
    models_logged_in_within_90_days = 'models_logged_in_within_90_days',
    /** Models, die den Wochenreport bisher erhalten haben */
    models_weekly_report_receiver = 'models_weekly_report_receiver',
    /** VISIT-X Models, die sich innerhalb der letzten 90 Tagen eingeloggt haben */
    visitx_models_logged_in_within_90_days = 'visitx_models_logged_in_within_90_days',
    /** VX-PAGES Models, die sich innerhalb der letzten 90 Tagen eingeloggt haben */
    vxpages_models_logged_in_within_90days = 'vxpages_models_logged_in_within_90days',
    /** Model im Key-Accounting */
    models_accounted_all = 'models_accounted_all',
    temp__pornme_migration = 'temp__pornme_migration',
    /** Model im Key-Accounting (Sales) */
    models_accounted_sales = 'models_accounted_sales',
    /** Model im Key-Accounting (Acc-Man) */
    models_accounted_accman = 'models_accounted_accman',
    /** statische Liste von GKR am 26.03.2020 */
    temp__vstx_gutschein = 'temp__vstx_gutschein',
    /** Models, die sich innerhalb der letzten 28 Tagen registriert haben und den Account DE haben */
    models_registered_within_28_days_de = 'models_registered_within_28_days_de',
    /** Models, die sich innerhalb der letzten 28 Tagen registriert haben und den Account EN haben */
    models_registered_within_28_days_en = 'models_registered_within_28_days_en',
    /** statische Liste von MBA am 02.06.2020 */
    temp__models_babestation = 'temp__models_babestation',
    /** VX-PAGES Models mit LandingPage, die sich innerhalb der letzten 90 Tagen eingeloggt haben */
    vxpages_lp_models_logged_in_within_90days = 'vxpages_lp_models_logged_in_within_90days',
    /** VX-PAGES Models mit Homepage, die sich innerhalb der letzten 90 Tagen eingeloggt haben */
    vxpages_hp_models_logged_in_within_90days = 'vxpages_hp_models_logged_in_within_90days',
    /** Models, die an TV-Liveshows teilnehmen */
    tv_liveshow_models = 'tv_liveshow_models',
    /** Models, die die Flash-Software letzte 30 Tage benutzt haben */
    models_with_flash_software = 'models_with_flash_software',
    /** Deutschsprachige Models, nicht gesperrt, letzter Login < 90 Tage */
    de_sprachige_models_logged_in_within_90_days = 'de_sprachige_models_logged_in_within_90_days',
    /** T22397 */
    temp__t22397_models_without_idshot = 'temp__t22397_models_without_idshot',
    /** T22397 */
    temp__t22397_models_without_profile_pic = 'temp__t22397_models_without_profile_pic',
    /** Telegram Models (nicht gesperrt, basiert auf FTR_TP 290) */
    telegram_users = 'telegram_users',
    /** Potenzielle VXGames 2022 Teilnehmer */
    de_speaking_girls_no_studios_active_last_30_days = 'de_speaking_girls_no_studios_active_last_30_days',
    /** Content-Selling-Testgruppe */
    content_selling_testgruppe = 'content_selling_testgruppe',
    /** Models mit Abos > 30 Tage */
    abo_more_than_30_days_vxstak_70 = 'abo_more_than_30_days_vxstak_70',
    /** VXGames 2021 (List from 16.03.2022) */
    vxgames20021_list_from_20220316 = 'vxgames20021_list_from_20220316',
    /** AGB für VXGAMES 2023 bestätigt */
    vxgames2023_models_participating = 'vxgames2023_models_participating',
    /**
     * Nur deutschprachige, weibliche Models, keine Partner-Accounts, nicht gesperrt
     * (LockType = [0,90,91,92]), letzter Login < 90d, AGB für VXGAMES 2023 NICHT bestätigt
     */
    vxgames2023_potentially_models_not_participating = 'vxgames2023_potentially_models_not_participating',
    /** Alle Models, die bisher noch nicht die TIN eingegeben haben */
    all_models_without_tin = 'all_models_without_tin',
    /** Alle Models ohne TIN mit Login in 2023 */
    all_models_without_tin_login2023 = 'all_models_without_tin_login2023'
}

export type NotificationInput = {
    /** Notification ID */
    id?: Maybe<Scalars['Int']>;
    /** notification type */
    type: NotificationTypeEnum;
    /** active */
    active: Scalars['Boolean'];
    /** Model UserId */
    userId?: Maybe<Scalars['UserId']>;
    /** group */
    group?: Maybe<NotificationGroupEnum>;
    /** role */
    role?: Maybe<NotificationRoleEnum>;
    /** priority */
    priority?: Maybe<Scalars['Int']>;
    /** create date */
    date?: Maybe<Scalars['DateTime']>;
    /** show from */
    showFrom?: Maybe<Scalars['DateTime']>;
    /** show until */
    showUntil?: Maybe<Scalars['DateTime']>;
    /** params */
    params?: Maybe<Scalars['Json']>;
};

export type NotificationProvider = {
    __typename?: 'NotificationProvider';
    id: Scalars['Int'];
    name: Scalars['String'];
    comment: Scalars['String'];
};

export enum NotificationRoleEnum {
    /** all users */
    CT_USER_STANDARD = 'CT_USER_STANDARD'
}

/** Notifications set */
export type Notifications = {
    __typename?: 'Notifications';
    /** number of notifications hit by filter */
    count: Scalars['Int'];
    /** notification items */
    items?: Maybe<Array<Maybe<Notification>>>;
};


/** Notifications set */
export type NotificationsitemsArgs = {
    sort?: Maybe<NotificationSortEnum>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type NotificationsMutation = {
    __typename?: 'NotificationsMutation';
    markNotificationRead: Array<Maybe<Notification>>;
};


export type NotificationsMutationmarkNotificationReadArgs = {
    id: Array<Maybe<Scalars['Int']>>;
};

export enum NotificationSortEnum {
    /** order by date ascending */
    DATE_ASC = 'DATE_ASC',
    /** order by date descending */
    DATE_DESC = 'DATE_DESC',
    /** order by prio ascending */
    PRIO_ASC = 'PRIO_ASC',
    /** order by prio descending */
    PRIO_DESC = 'PRIO_DESC',
    /** order by prio descending, then date ascending */
    PRIO_DESC__DATE_ASC = 'PRIO_DESC__DATE_ASC',
    /** order by prio descending, then date descending */
    PRIO_DESC__DATE_DESC = 'PRIO_DESC__DATE_DESC'
}

export type NotificationType = {
    __typename?: 'NotificationType';
    /** id */
    id: Scalars['Int'];
    /** type name */
    enum: Scalars['String'];
    /** localized name */
    name: Scalars['String'];
    /** category */
    category: NotificationCategory;
    /** description */
    description: Scalars['String'];
    /** is notification unique (new notifications will update an old one) */
    unique: Scalars['Boolean'];
    /** is notification deletable by user */
    deletable: Scalars['Boolean'];
    repeatAfterDays?: Maybe<Scalars['Int']>;
    displayForDays?: Maybe<Scalars['Int']>;
    /** priority */
    priority: Scalars['Int'];
    /** provider */
    provider: NotificationProvider;
    /** extra provided meta data */
    meta: NotificationTypeMeta;
    /** enricher, e.g. to resolve album title by umaId */
    enricher: Scalars['Json'];
    /** required parameters */
    paramTypes: Scalars['Json'];
    /** default parameters */
    paramDefaults: Scalars['Json'];
};

export enum NotificationTypeEnum {
    /** Host was added as favorite by guest */
    common_actorAddedAsFavorite = 'common_actorAddedAsFavorite',
    /** Chat was rated by guest */
    common_chatRatedByGuest = 'common_chatRatedByGuest',
    /** Recruited guest charged money first time */
    common_firstChargeOfRecruitedGuest = 'common_firstChargeOfRecruitedGuest',
    /** Soft-Chat enabled */
    common_softchat_enabled = 'common_softchat_enabled',
    /** Soft-Chat disabled */
    common_softchat_disabled = 'common_softchat_disabled',
    /** ID-Shot was rejected */
    directNotification_idShot_rejectedByShopVideo = 'directNotification_idShot_rejectedByShopVideo',
    /** Free text notification */
    directNotification_freetext = 'directNotification_freetext',
    /** GEMA confirmation */
    doc_gema_confirmation = 'doc_gema_confirmation',
    /** Missing signature */
    document_signatureMissing = 'document_signatureMissing',
    document_identity_invalidProof = 'document_identity_invalidProof',
    document_identity_missingProof = 'document_identity_missingProof',
    /** Invalid TAX number */
    document_taxNumber_invalid = 'document_taxNumber_invalid',
    /** Missing proof of address */
    document_address_missingProof = 'document_address_missingProof',
    /** Unreadable document */
    document_unreadable = 'document_unreadable',
    /** Missing VAT */
    document_vatDocument_missing = 'document_vatDocument_missing',
    /** VAT expired */
    document_vatDocument_expired = 'document_vatDocument_expired',
    /** Switch to smalltrade */
    finance_legalForm_changedToSmalltrade = 'finance_legalForm_changedToSmalltrade',
    /** Last payout failed */
    finance_payout_failed = 'finance_payout_failed',
    /** Payout locked */
    finance_payout_locked = 'finance_payout_locked',
    /** Homepage disabled */
    homepage_disabled = 'homepage_disabled',
    /** Homepage enabled */
    homepage_enabled = 'homepage_enabled',
    /** Number 0900 disabled */
    marketing_service0900_disabled = 'marketing_service0900_disabled',
    /** Number 0900 enabled */
    marketing_service0900_enabled = 'marketing_service0900_enabled',
    /** Power-Mailing accepted */
    marketing_powermailing_accepted = 'marketing_powermailing_accepted',
    /** Power-Mailing rejected */
    marketing_powermailing_rejected = 'marketing_powermailing_rejected',
    /** Video abo accepted */
    marketing_videoabo_accepted = 'marketing_videoabo_accepted',
    /** Video abo rejected */
    marketing_videoabo_rejected = 'marketing_videoabo_rejected',
    /** Toy control accepted */
    marketing_toycontrol_accepted = 'marketing_toycontrol_accepted',
    /** Toy control rejected */
    marketing_toycontrol_rejected = 'marketing_toycontrol_rejected',
    /** Medal [Hot-Clip] has expired */
    medal_hotclip_expired = 'medal_hotclip_expired',
    /** Medal [Hot-Clip] expires in XXX days */
    medal_hotclip_expires = 'medal_hotclip_expires',
    /** Medal [Private-Shop] has expired */
    medal_private_shop_expired = 'medal_private_shop_expired',
    /** Medal [Private-Shop] expires in XXX days */
    medal_private_shop_expires = 'medal_private_shop_expires',
    /** Medal [Profile current] has expired */
    medal_profile_current_expired = 'medal_profile_current_expired',
    /** Medal [Profile current] expires in XXX days */
    medal_profile_current_expires = 'medal_profile_current_expires',
    /** Medal [Welcome-Clip] has expired */
    medal_welcomeclip_expired = 'medal_welcomeclip_expired',
    /** Medal [Welcome-Clip] expires in XXX days */
    medal_welcomeclip_expires = 'medal_welcomeclip_expires',
    /** Free photoalbum rejected */
    mediacontent_photoalbum_free_rejected = 'mediacontent_photoalbum_free_rejected',
    /** Free photoalbum released */
    mediacontent_photoalbum_free_released = 'mediacontent_photoalbum_free_released',
    /** Shop gallery rejected */
    mediacontent_photoalbum_shop_rejected = 'mediacontent_photoalbum_shop_rejected',
    /** Shop gallery released */
    mediacontent_photoalbum_shop_released = 'mediacontent_photoalbum_shop_released',
    /** Shop gallery bought by guest */
    mediacontent_photoalbum_shop_transaction = 'mediacontent_photoalbum_shop_transaction',
    /** Sedcard rejected */
    mediacontent_photoalbum_profile_rejected = 'mediacontent_photoalbum_profile_rejected',
    /** Sedcard released */
    mediacontent_photoalbum_profile_released = 'mediacontent_photoalbum_profile_released',
    /** Profile video (welcome-clip) rejected */
    mediacontent_video_profile_rejected = 'mediacontent_video_profile_rejected',
    /** Profile video (welcome-clip) released */
    mediacontent_video_profile_released = 'mediacontent_video_profile_released',
    /** Free video (hot clip) rejected */
    mediacontent_video_free_rejected = 'mediacontent_video_free_rejected',
    /** Free video (hot clip) released */
    mediacontent_video_free_released = 'mediacontent_video_free_released',
    /** New comment by shop video */
    mediacontent_video_shop_newComment = 'mediacontent_video_shop_newComment',
    /** Shop video rejected */
    mediacontent_video_shop_rejected = 'mediacontent_video_shop_rejected',
    /** Shop video released */
    mediacontent_video_shop_released = 'mediacontent_video_shop_released',
    /** Shop video bought by guest */
    mediacontent_video_shop_transaction = 'mediacontent_video_shop_transaction',
    mediacontent_photoalbum_picture_ratedByGuest = 'mediacontent_photoalbum_picture_ratedByGuest',
    /** Incomplete appearance profile */
    profile_appearance_incomplete = 'profile_appearance_incomplete',
    /** Fetish-Profile disabled */
    profile_category_fetish_disabled = 'profile_category_fetish_disabled',
    /** Fetish-Profile enabled */
    profile_category_fetish_enabled = 'profile_category_fetish_enabled',
    /** Live-Dates-Profile disabled */
    profile_category_livedates_disabled = 'profile_category_livedates_disabled',
    /** Live-Dates-Profile enabled */
    profile_category_livedates_enabled = 'profile_category_livedates_enabled',
    /** Incomplete common profile */
    profile_common_incomplete = 'profile_common_incomplete',
    /** Flirt & Dating-Profile activated */
    profile_flirtdating_enabled = 'profile_flirtdating_enabled',
    /** Flirt & Dating dectivated */
    profile_flirtdating_disabled = 'profile_flirtdating_disabled',
    /** Incomplete flirt- and dating profile */
    profile_flirtdating_incomplete = 'profile_flirtdating_incomplete',
    /** Incomplete live-show profile */
    profile_liveshow_incomplete = 'profile_liveshow_incomplete',
    /** Incomplete Sedcard 12 */
    profile_sedcard_flirtcore_incomplete = 'profile_sedcard_flirtcore_incomplete',
    /** Incomplete Sedcard 18 */
    profile_sedcard_hardcore_incomplete = 'profile_sedcard_hardcore_incomplete',
    /** Incomplete Sedcard 16 */
    profile_sedcard_softcore_incomplete = 'profile_sedcard_softcore_incomplete',
    /** Host got Bonus-Ranking-Points */
    ranking_bonus_received = 'ranking_bonus_received',
    /** Host got Penalty-Ranking-Points */
    ranking_penalty_received = 'ranking_penalty_received',
    /** Maintainance Announcement */
    system_maintenance_announcement = 'system_maintenance_announcement',
    /** Tax document expires soon */
    actor_finance_payout_expireSoon = 'actor_finance_payout_expireSoon',
    /** Special - Document Action */
    snagbar_announcement_special_documentAction = 'snagbar_announcement_special_documentAction',
    /** Simple Announcement */
    snagbar_announcement_simple = 'snagbar_announcement_simple',
    /** Marketing Announcement */
    snagbar_announcement_marketing = 'snagbar_announcement_marketing',
    vxgames2019_part02_winner = 'vxgames2019_part02_winner',
    vxgames2019_part02_toplist = 'vxgames2019_part02_toplist',
    vxgames2019_part03_winner = 'vxgames2019_part03_winner',
    vxgames2019_part03_toplist = 'vxgames2019_part03_toplist',
    vxgames2019_part04_winner = 'vxgames2019_part04_winner',
    vxgames2019_part04_toplist = 'vxgames2019_part04_toplist',
    vxgames2019_part05_winner = 'vxgames2019_part05_winner',
    vxgames2019_part05_toplist = 'vxgames2019_part05_toplist',
    vxgames2019_part06_winner = 'vxgames2019_part06_winner',
    vxgames2019_part06_toplist = 'vxgames2019_part06_toplist',
    vxgames2019_part07_winner = 'vxgames2019_part07_winner',
    vxgames2019_part07_toplist = 'vxgames2019_part07_toplist',
    vxgames2019_part08_winner = 'vxgames2019_part08_winner',
    vxgames2019_part08_toplist = 'vxgames2019_part08_toplist',
    vxgames2019_part09_winner = 'vxgames2019_part09_winner',
    vxgames2019_part09_toplist = 'vxgames2019_part09_toplist',
    vxgames2019_part10_winner = 'vxgames2019_part10_winner',
    vxgames2019_part10_toplist = 'vxgames2019_part10_toplist',
    vxgames2019_part11_winner = 'vxgames2019_part11_winner',
    vxgames2019_part11_toplist = 'vxgames2019_part11_toplist',
    vxgames2019_part12_winner = 'vxgames2019_part12_winner',
    vxgames2019_part12_toplist = 'vxgames2019_part12_toplist',
    vxgames2019_part13_winner = 'vxgames2019_part13_winner',
    vxgames2019_part13_toplist = 'vxgames2019_part13_toplist',
    vxgames2019_part14_winner = 'vxgames2019_part14_winner',
    vxgames2019_part14_toplist = 'vxgames2019_part14_toplist',
    vxgames2019_part15_winner = 'vxgames2019_part15_winner',
    vxgames2019_part15_toplist = 'vxgames2019_part15_toplist',
    vxgames2019_part16_winner = 'vxgames2019_part16_winner',
    vxgames2019_part16_toplist = 'vxgames2019_part16_toplist',
    vxgames2019_part17_winner = 'vxgames2019_part17_winner',
    vxgames2019_part17_toplist = 'vxgames2019_part17_toplist',
    /** Direct message from VXModels team */
    snagbar_direct_vxmodels = 'snagbar_direct_vxmodels',
    /** Contest Announcement */
    snagbar_announcement_contest = 'snagbar_announcement_contest',
    vxgames2019_part18_winner = 'vxgames2019_part18_winner',
    vxgames2019_part18_toplist = 'vxgames2019_part18_toplist',
    vxgames2019_part19_winner = 'vxgames2019_part19_winner',
    vxgames2019_part19_toplist = 'vxgames2019_part19_toplist',
    vxgames2019_part20_winner = 'vxgames2019_part20_winner',
    vxgames2019_part20_toplist = 'vxgames2019_part20_toplist',
    vxgames2019_part21_winner = 'vxgames2019_part21_winner',
    vxgames2019_part21_toplist = 'vxgames2019_part21_toplist',
    vxgames2019_part22_winner = 'vxgames2019_part22_winner',
    vxgames2019_part22_toplist = 'vxgames2019_part22_toplist',
    vxgames2019_part23_winner = 'vxgames2019_part23_winner',
    vxgames2019_part23_toplist = 'vxgames2019_part23_toplist',
    vxgames2020_winner = 'vxgames2020_winner',
    vxgames2019_part24_winner = 'vxgames2019_part24_winner',
    vxgames2019_part24_toplist = 'vxgames2019_part24_toplist',
    vxgames2019_part25_winner = 'vxgames2019_part25_winner',
    vxgames2019_part25_toplist = 'vxgames2019_part25_toplist',
    /** TV Calendar */
    snagbar_announcement_tv = 'snagbar_announcement_tv',
    /** Notifications for VXGames 2021 */
    vxgames2021 = 'vxgames2021',
    /** VXChallenges winner notifications */
    vxchallenges_winner = 'vxchallenges_winner',
    /** Shop video rating delayed (missing docs) */
    mediacontent_video_shop_delayed = 'mediacontent_video_shop_delayed',
    /** Free video rating delayed (missing docs) */
    mediacontent_video_free_delayed = 'mediacontent_video_free_delayed',
    /** Welcomeclip rating delayed (missing docs) */
    mediacontent_video_profile_delayed = 'mediacontent_video_profile_delayed',
    /** Notifications for VXGames 2022 */
    vxgames2022 = 'vxgames2022',
    /** New comment by shop photo */
    mediacontent_photoalbum_shop_newComment = 'mediacontent_photoalbum_shop_newComment',
    /** Billing address accepted */
    finance_hostaddress_accepted = 'finance_hostaddress_accepted',
    /** Billing address rejected */
    finance_hostaddress_rejected = 'finance_hostaddress_rejected',
    /** Tax data accepted */
    finance_taxdata_accepted = 'finance_taxdata_accepted',
    /** Tax data rejected */
    finance_taxdata_rejected = 'finance_taxdata_rejected',
    /** Payout data accepted */
    finance_payoutdata_accepted = 'finance_payoutdata_accepted',
    /** Payout data rejected */
    finance_payoutdata_rejected = 'finance_payoutdata_rejected'
}

export type NotificationTypeMeta = {
    __typename?: 'NotificationTypeMeta';
    icon?: Maybe<Scalars['String']>;
    iconStyle?: Maybe<Scalars['Json']>;
    action?: Maybe<NotificationTypeMetaAction>;
    form?: Maybe<Array<Maybe<NotificationTypeMetaFormElement>>>;
    ribbon?: Maybe<NotificationTypeMetaRibbon>;
    picture?: Maybe<NotificationTypeMetaPicture>;
    button?: Maybe<NotificationTypeMetaButton>;
};

export type NotificationTypeMetaAction = {
    __typename?: 'NotificationTypeMetaAction';
    link?: Maybe<Scalars['String']>;
};

export type NotificationTypeMetaButton = {
    __typename?: 'NotificationTypeMetaButton';
    label?: Maybe<Scalars['String']>;
    href?: Maybe<Scalars['String']>;
    target?: Maybe<NotificationTypeMetaButtonTarget>;
};

export enum NotificationTypeMetaButtonTarget {
    _blank = '_blank'
}

export type NotificationTypeMetaFormElement = {
    __typename?: 'NotificationTypeMetaFormElement';
    type: Scalars['String'];
    name: Scalars['String'];
    required: Scalars['Boolean'];
    multiline: Scalars['Boolean'];
    label: Scalars['String'];
    helperText?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    rows?: Maybe<Scalars['Int']>;
    rowsMax?: Maybe<Scalars['Int']>;
};

export type NotificationTypeMetaPicture = {
    __typename?: 'NotificationTypeMetaPicture';
    de?: Maybe<Scalars['String']>;
    en?: Maybe<Scalars['String']>;
};

export type NotificationTypeMetaRibbon = {
    __typename?: 'NotificationTypeMetaRibbon';
    hide?: Maybe<Scalars['Boolean']>;
    color?: Maybe<Scalars['String']>;
};

export enum OfferContentTypeEnum {
    video = 'video',
    gallery = 'gallery',
    gift = 'gift',
    wallpaper = 'wallpaper'
}

export type OptionNode = {
    __typename?: 'OptionNode';
    /** Label */
    label: Scalars['String'];
    /** Value */
    value: Scalars['Any'];
};

/** The representation of an originator */
export type Originator = UserInterface & {
    __typename?: 'Originator';
    /** user id */
    id: Scalars['UserId'];
    /** username */
    username: Scalars['String'];
    /** linked models */
    models: Array<Maybe<Model>>;
    /** account information */
    account: Account;
};

export enum PhoneVerificationStatusEnum {
    unknown = 'unknown',
    checking = 'checking',
    verified = 'verified'
}

export type Picture = {
    __typename?: 'Picture';
    /** umpId */
    id: Scalars['Int'];
    /** width in px */
    width: Scalars['Int'];
    /** height in px */
    height: Scalars['Int'];
    /** aspect ratio */
    aspectRatio: Scalars['Float'];
    /** orientation from aspect ratio */
    orientation: MediaOrientation;
    /** released date time */
    released?: Maybe<Scalars['DateTime']>;
    /** age rating */
    ageRating: Scalars['Int'];
    /** URL */
    url?: Maybe<Scalars['String']>;
    /** Likes */
    likes: Scalars['Int'];
};


export type PictureurlArgs = {
    size?: Maybe<PictureSizeEnum>;
};

export enum PictureSizeEnum {
    orig = 'orig',
    w40 = 'w40',
    w60 = 'w60',
    w140 = 'w140',
    w160 = 'w160',
    w320 = 'w320',
    w640 = 'w640',
    w1024 = 'w1024'
}

export enum PremiumCooperationStatus {
    yes = 'yes',
    no = 'no',
    checking = 'checking'
}

export type PreviewPicture = {
    __typename?: 'PreviewPicture';
    /** Photoalbum of preview picture */
    album: MediaAlbum;
    /** Picture data of preview picture */
    picture?: Maybe<MediaPicture>;
};

export type PriceRange = {
    __typename?: 'PriceRange';
    rangeKey: Scalars['String'];
    rangeValue: Scalars['Float'];
};

export type Prices = {
    __typename?: 'Prices';
    /** Minimal price of video chat */
    videoChatMinPrice: Scalars['Float'];
    /** Maximal price of video chat */
    videoChatMaxPrice: Scalars['Float'];
    /** Default price of video chat */
    videoChatDefaultPrice: Scalars['Float'];
    /** Current price of video chat */
    videoChatUserPrice: Scalars['Float'];
    /** Minimal price of single (private) chat */
    singleChatMinPrice: Scalars['Float'];
    /** Maximal price of single (private) chat */
    singleChatMaxPrice: Scalars['Float'];
    /** Default price of single (private) chat */
    singleChatDefaultPrice: Scalars['Float'];
    /** Current price of single (private) chat */
    singleChatUserPrice: Scalars['Float'];
    /** Minimal price of message in messenger */
    messengerMinPrice: Scalars['Float'];
    /** Maximal price of message in messenger */
    messengerMaxPrice: Scalars['Float'];
    /** Default price of message in messenger */
    messengerDefaultPrice: Scalars['Float'];
    /** Current price of message in messenger */
    messengerUserPrice: Scalars['Float'];
    /** Messenger price range for slider */
    messengerPriceRange: Array<PriceRange>;
};

export type PricesInput = {
    /** Videochat price */
    videoChatPrice?: Maybe<Scalars['Float']>;
    /** Singlechat price */
    singleChatPrice?: Maybe<Scalars['Float']>;
    /** Messenger price */
    messengerPrice?: Maybe<Scalars['Float']>;
    /** Referer */
    referrer?: Maybe<Scalars['String']>;
};

export type PricesMutation = {
    __typename?: 'PricesMutation';
    /** Save new chat and messenger prices */
    savePrices?: Maybe<Prices>;
};


export type PricesMutationsavePricesArgs = {
    input?: Maybe<PricesInput>;
};

export enum ProductEnum {
    all = 'all',
    visitx = 'visitx'
}

export type Profile = {
    __typename?: 'Profile';
    /** Forbids changes of gender if true */
    isGenderDisabled?: Maybe<Scalars['Boolean']>;
    /** Forbids changes of birthday if true */
    isBirthdayDisabled?: Maybe<Scalars['Boolean']>;
    /** Forbids changes of languages if true */
    isProfileLanguagesDisabled?: Maybe<Scalars['Boolean']>;
    /** Forbids changes of hair color if true */
    isHairColorDisabled?: Maybe<Scalars['Boolean']>;
    /** Forbids changes of breast size if true */
    isBreastSizeDisabled?: Maybe<Scalars['Boolean']>;
    /** Forbids changes of chat practices if true */
    isVxShowTypeDisabled?: Maybe<Scalars['Boolean']>;
    /** Birhday (visible for users) */
    birthday?: Maybe<ProfileItem>;
    /** Job (visible for users) */
    occupation?: Maybe<ProfileItem>;
    /** City (visible for users) */
    city?: Maybe<ProfileItem>;
    /** Zipcode (visible for users) */
    zipCode?: Maybe<ProfileItem>;
    /** Geo-Data for map */
    geoHashCalculated?: Maybe<ProfileItem>;
    /** Geo-Data for map */
    geoHashCustom?: Maybe<ProfileItem>;
    /** Gender: woman, man, transexual */
    gender?: Maybe<ProfileItem>;
    /** Sexuality: heterosexual, homosexual, bisexual */
    sexuality?: Maybe<ProfileItem>;
    /** Three words (for example: crazy, honest, romantic e.t.c.) */
    tags?: Maybe<ProfileItem>;
    /** I am (characteristics): wild, shy, lazy e.t.c */
    characteristics?: Maybe<ProfileCharacteristics>;
    /** Relation status: single, married, seeking, open relationship e.t.c */
    relationshipState?: Maybe<ProfileItem>;
    /** Spoken kanguages */
    languages?: Maybe<ProfileItem>;
    /** Country (visible for users) */
    country?: Maybe<ProfileItem>;
    /** Profile style: stylish, classic, trendy e.t.c. */
    appearance?: Maybe<ProfileItem>;
    /** Skin tone: caucasian, mediterranean, asian, dark_skinned */
    skinType?: Maybe<ProfileItem>;
    /** Shape: slim, athletic, fat e.t.c. */
    figure?: Maybe<ProfileItem>;
    /** Height */
    height?: Maybe<ProfileItem>;
    /** Weight */
    weight?: Maybe<ProfileItem>;
    /** Hair length: short, long, bald head e.t.c */
    hairLength?: Maybe<ProfileItem>;
    /** Hair color: blonde, black, brown e.t.c */
    hairColor?: Maybe<ProfileItem>;
    /** Eye color: green, grey, blue, brown */
    eyeColor?: Maybe<ProfileItem>;
    /** Body modification: tattoo, piercing, no modifications e.t.c */
    bodyModification?: Maybe<ProfileItem>;
    /** Cup size: AA, A, B, F e.t.c */
    breastSize?: Maybe<ProfileItem>;
    /** Pubic: natural, bikini cut, freestyle e.t.c */
    pubicHair?: Maybe<ProfileItem>;
    /** Beard: no beard, three-day, full e.t.c */
    beard?: Maybe<ProfileItem>;
    /** Hairness: hairless, shaved, hairy e.t.c */
    hairness?: Maybe<ProfileItem>;
    /** Penis size: S, M, L e.t.c */
    penisSize?: Maybe<ProfileItem>;
    /** Cam location: bedroom, bathroom, kitchen e.t.c. */
    camLocation?: Maybe<ProfileItem>;
    /** Chat practices: livesex, chat / flirt e.t.c. */
    chatPractices?: Maybe<ProfileItem>;
    /** Chat outfits: nylons, dessous, uniforms e.t.c. */
    chatOutfits?: Maybe<ProfileItem>;
    /** Toys: dildos, wax, fruit e.t.c. */
    toys?: Maybe<ProfileItem>;
    /** Sexual preferences: vanilla sex, anal sex e.t.c. */
    sexualPreferences?: Maybe<ProfileItem>;
    /** Sexual practices: role plays, S/M, feet e.t.c. */
    sexualPractices?: Maybe<ProfileItem>;
    /** Couple status: solo, couple */
    coupleState?: Maybe<ProfileItem>;
    /** Birhday of partner (visible for users) */
    partnerBirthdate?: Maybe<ProfileItem>;
    /** Profile style of partner: stylish, classic, trendy e.t.c. */
    partnerAppearance?: Maybe<ProfileItem>;
    /** Body modification of partner: tattoo, piercing, no modifications e.t.c */
    partnerBodyModification?: Maybe<ProfileItem>;
    /** Cup size of partner: AA, A, B, F e.t.c */
    partnerBreastSize?: Maybe<ProfileItem>;
    /** Eye color of partner: green, grey, blue, brown */
    partnerEyeColor?: Maybe<ProfileItem>;
    /** Shape of partner: slim, athletic, fat e.t.c. */
    partnerFigure?: Maybe<ProfileItem>;
    /** Gender of partner: woman, man, transexual */
    partnerGender?: Maybe<ProfileItem>;
    /** Hair color of partner: blonde, black, brown e.t.c */
    partnerHairColor?: Maybe<ProfileItem>;
    /** Hair length of partner: short, long, bald head e.t.c */
    partnerHairLength?: Maybe<ProfileItem>;
    /** Hairness of partner: hairless, shaved, hairy e.t.c */
    partnerHairness?: Maybe<ProfileItem>;
    /** Height of partner */
    partnerHeight?: Maybe<ProfileItem>;
    /** Penis size of partner: S, M, L e.t.c */
    partnerPenisSize?: Maybe<ProfileItem>;
    /** Pubic of partner: natural, bikini cut, freestyle e.t.c */
    partnerPubicHair?: Maybe<ProfileItem>;
    /** Skin tone of partner: caucasian, mediterranean, asian, dark_skinned */
    partnerSkinType?: Maybe<ProfileItem>;
    /** Weight of partner */
    partnerWeight?: Maybe<ProfileItem>;
    /** Allows changes of fetisch profile if true */
    isFetishUnlockedBySupport?: Maybe<Scalars['Boolean']>;
    /** Fetish staus: active, inactive */
    fetishState?: Maybe<ProfileItem>;
    /** Fetish role: dominant, submissive, switcher, neither */
    fetishRole?: Maybe<ProfileItem>;
    /** Fetish practices: foot fetish, shoes, sox, fisting e.t.c. */
    fetishPractices?: Maybe<ProfileItem>;
    /** Fetish experiences: domina, slave, servant e.t.c. */
    fetishExperiences?: Maybe<ProfileItem>;
    /** Fetish toys: nipple clamps, clothespins, dildo, pump e.t.c. */
    fetishToys?: Maybe<ProfileItem>;
    /** Fetish gay spesials */
    fetishGaysSpecials?: Maybe<ProfileItem>;
    /** Text: other fetishes */
    fetishText?: Maybe<ProfileText>;
    /** Text: taboo */
    fetishTaboos?: Maybe<ProfileText>;
    /** Is lustagenten profile activated */
    laSettingsProfileActive?: Maybe<Scalars['Boolean']>;
    /** Is enabled automessaging für lustagenten-users */
    laSettingsAutomessagingActive?: Maybe<Scalars['Boolean']>;
    /** Is enabled live-dates für lustagenten-users */
    laSettingsLiveDatesActive?: Maybe<Scalars['Boolean']>;
    /** Is enabled action-dates für lustagenten-users */
    laSettingsActionDatesActive?: Maybe<Scalars['Boolean']>;
    /** Nickname on lustagenten portal */
    laNickname?: Maybe<Scalars['String']>;
    /** Nickname status */
    laNicknameStatus?: Maybe<Scalars['String']>;
    /** Media-ID (UMA_ID) of lustagenten photoalbum */
    laMailAttachmentUmaId?: Maybe<Scalars['Int']>;
    /** Text: preferences */
    laTextPreferences?: Maybe<ProfileText>;
    /** Text: perfect date */
    laTextPerfectDate?: Maybe<ProfileText>;
    /** Text: sexual experiences */
    laTextSexualExperiences?: Maybe<ProfileText>;
    /** Text: welcome-automail 1 */
    laTextWelcome1?: Maybe<ProfileText>;
    /** Text: welcome-automail 2 */
    laTextWelcome2?: Maybe<ProfileText>;
    /** Text: welcome-automail 3 */
    laTextWelcome3?: Maybe<ProfileText>;
    /** Picture for welcome-automail 1 */
    laTextWelcome1Picture?: Maybe<MediaPicture>;
    /** Picture for welcome-automail 2 */
    laTextWelcome2Picture?: Maybe<MediaPicture>;
    /** Picture for welcome-automail 3 */
    laTextWelcome3Picture?: Maybe<MediaPicture>;
    /** Text: About me */
    textAboutMe?: Maybe<ProfileText>;
    /** Text: Chat Motto */
    textChatMotto?: Maybe<ProfileText>;
    /** Text: Webcam schedule */
    textOnlineSchedule?: Maybe<ProfileText>;
    /** Text: What is your favorite book? */
    textInterviewBook?: Maybe<ProfileText>;
    /** Text: In which city do you want to live? */
    textInterviewCity?: Maybe<ProfileText>;
    /** Text: A perfect date would be like this. */
    textInterviewDate?: Maybe<ProfileText>;
    /** Text: What is on your bucket list? */
    textInterviewEnterprise?: Maybe<ProfileText>;
    /** Text: What was the most exciting erotic experience? */
    textInterviewEroticExperience?: Maybe<ProfileText>;
    /** Text: What was your most extraordinary sex experience? */
    textInterviewExtraordinarySex?: Maybe<ProfileText>;
    /** Text: What is your favorite food? */
    textInterviewFood?: Maybe<ProfileText>;
    /** Text: What do you do, when you are currently not online? */
    textInterviewHobbies?: Maybe<ProfileText>;
    /** Text: What was your hottest experience? */
    textInterviewHotExperience?: Maybe<ProfileText>;
    /** Text: What would you take to a uninhabited island? */
    textInterviewIsland?: Maybe<ProfileText>;
    /** Text: What is your favorite music? */
    textInterviewMusic?: Maybe<ProfileText>;
    /** Text: How should your perfect partner look like? */
    textInterviewPartner?: Maybe<ProfileText>;
    /** Text: What was the best party you have ever visited? */
    textInterviewParty?: Maybe<ProfileText>;
    /** Text: What do you like most about yourself? */
    textInterviewPersonalAdmiration?: Maybe<ProfileText>;
    /** Text: What do you like during sex? */
    textInterviewSexGo?: Maybe<ProfileText>;
    /** Text: What do you not like during sex? */
    textInterviewSexNoGo?: Maybe<ProfileText>;
    /** Text: What do you like to try out? */
    textInterviewSexualCuriosity?: Maybe<ProfileText>;
    /** Text: What whishes do you have regarding sex? */
    textInterviewSexualEsteem?: Maybe<ProfileText>;
    /** Text: What are your sexual fantasies? */
    textInterviewSexualFantasies?: Maybe<ProfileText>;
    /** Text: What is your favorite sex position? */
    textInterviewSexualPosition?: Maybe<ProfileText>;
    /** Text: What is your favorite sport? */
    textInterviewSport?: Maybe<ProfileText>;
    /** Text: What are your best characteristics? */
    textInterviewTraits?: Maybe<ProfileText>;
    /** Text: automail-1 for new customers */
    textAutomailTextNewUser1?: Maybe<ProfileText>;
    /** Text: automail-2 for new customers */
    textAutomailTextNewUser2?: Maybe<ProfileText>;
    /** Text: automail-3 for new customers */
    textAutomailTextNewUser3?: Maybe<ProfileText>;
    /** Text: automail-4 for new customers */
    textAutomailTextNewUser4?: Maybe<ProfileText>;
    /** Text: automail-5 for new customers */
    textAutomailTextNewUser5?: Maybe<ProfileText>;
    /** Text: automail-1 for existing customers */
    textAutomailTextExistingUser1?: Maybe<ProfileText>;
    /** Text: automail-2 for existing customers */
    textAutomailTextExistingUser2?: Maybe<ProfileText>;
    /** Text: automail-3 for existing customers */
    textAutomailTextExistingUser3?: Maybe<ProfileText>;
    /** Text: automail-4 for existing customers */
    textAutomailTextExistingUser4?: Maybe<ProfileText>;
    /** Text: automail-5 for existing customers */
    textAutomailTextExistingUser5?: Maybe<ProfileText>;
};

export type ProfileCharacteristicGroup = {
    __typename?: 'ProfileCharacteristicGroup';
    /** formData to build a form */
    formData: Array<Maybe<FormData>>;
};

export type ProfileCharacteristics = {
    __typename?: 'ProfileCharacteristics';
    /** Values of profile characteristics */
    value: Array<Maybe<Scalars['String']>>;
    /** Groups of profile characteristics */
    groups: Array<Maybe<ProfileCharacteristicGroup>>;
};

export type ProfileInput = {
    /** Birthday */
    birthday?: Maybe<Scalars['DateTime']>;
    /** Job */
    occupation?: Maybe<Scalars['String']>;
    /** City */
    city?: Maybe<Scalars['String']>;
    /** Zip code */
    zipCode?: Maybe<Scalars['String']>;
    /** Gender */
    gender?: Maybe<Scalars['String']>;
    /** Sexuality */
    sexuality?: Maybe<Scalars['String']>;
    /** Three words */
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Characteristics */
    characteristics?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Relationship status */
    relationshipState?: Maybe<Scalars['String']>;
    /** Spoken languages */
    languages?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Country */
    country?: Maybe<Scalars['String']>;
    /** Custom geo-location */
    geoHashCustom?: Maybe<Scalars['String']>;
    /** Appearance */
    appearance?: Maybe<Scalars['String']>;
    /** Skin tone */
    skinType?: Maybe<Scalars['String']>;
    /** Shape */
    figure?: Maybe<Scalars['String']>;
    /** Height */
    height?: Maybe<Scalars['Int']>;
    /** Weight */
    weight?: Maybe<Scalars['Int']>;
    /** Hair length */
    hairLength?: Maybe<Scalars['String']>;
    /** Hair color */
    hairColor?: Maybe<Scalars['String']>;
    /** Eye color */
    eyeColor?: Maybe<Scalars['String']>;
    /** Body modifications */
    bodyModification?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Cup size */
    breastSize?: Maybe<Scalars['String']>;
    /** Pubic */
    pubicHair?: Maybe<Scalars['String']>;
    /** Beard */
    beard?: Maybe<Scalars['String']>;
    /** Hairness */
    hairness?: Maybe<Scalars['String']>;
    /** Penis size */
    penisSize?: Maybe<Scalars['String']>;
    /** Cam location */
    camLocation?: Maybe<Scalars['String']>;
    /** Chat practices */
    chatPractices?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Chat outfits */
    chatOutfits?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Toys */
    toys?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Sexual preferences */
    sexualPreferences?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Sexual practices */
    sexualPractices?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Couple status */
    coupleState?: Maybe<Scalars['String']>;
    /** Partner Birthday */
    partnerBirthdate?: Maybe<Scalars['DateTime']>;
    /** Partner Appearance */
    partnerAppearance?: Maybe<Scalars['String']>;
    /** Partner Body modifications */
    partnerBodyModification?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Partner Cup size */
    partnerBreastSize?: Maybe<Scalars['String']>;
    /** Partner Eye color */
    partnerEyeColor?: Maybe<Scalars['String']>;
    /** Partner Shape */
    partnerFigure?: Maybe<Scalars['String']>;
    /** Partner Gender */
    partnerGender?: Maybe<Scalars['String']>;
    /** Partner Hair color */
    partnerHairColor?: Maybe<Scalars['String']>;
    /** Partner Hair length */
    partnerHairLength?: Maybe<Scalars['String']>;
    /** Partner Hairness */
    partnerHairness?: Maybe<Scalars['String']>;
    /** Partner Height */
    partnerHeight?: Maybe<Scalars['String']>;
    /** Partner Penis size */
    partnerPenisSize?: Maybe<Scalars['String']>;
    /** Partner Pubic */
    partnerPubicHair?: Maybe<Scalars['String']>;
    /** Partner Skine tone */
    partnerSkinType?: Maybe<Scalars['String']>;
    /** Partner Weight */
    partnerWeight?: Maybe<Scalars['String']>;
    /** Fetish staus */
    fetishState?: Maybe<Scalars['String']>;
    /** Fetish role */
    fetishRole?: Maybe<Scalars['String']>;
    /** Fetish practices */
    fetishPractices?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Fetish expiriences */
    fetishExperiences?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Fetish toys */
    fetishToys?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Fetish specials for gays */
    fetishGaysSpecials?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Text: other fetishes */
    fetishText?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: fetish taboo */
    fetishTaboos?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten setting: is profile active */
    laSettingsProfileActive?: Maybe<Scalars['Boolean']>;
    /** Lustagenten setting: is automessaging active */
    laSettingsAutomessagingActive?: Maybe<Scalars['Boolean']>;
    /** Lustagenten setting: is live-dates active */
    laSettingsLiveDatesActive?: Maybe<Scalars['Boolean']>;
    /** Lustagenten setting: is action-dates active */
    laSettingsActionDatesActive?: Maybe<Scalars['Boolean']>;
    /** Lustagenten nickname */
    laNickname?: Maybe<Scalars['String']>;
    /** Lustagenten text: preferences */
    laTextPreferences?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten text: perfect date */
    laTextPerfectDate?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten text: sexual experiences */
    laTextSexualExperiences?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten text: welcome-mail 1 */
    laTextWelcome1?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten text: welcome-mail 2 */
    laTextWelcome2?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Lustagenten text: welcome-mail 3 */
    laTextWelcome3?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Picture-ID for welcome-mail 1 */
    laTextWelcome1PictureId?: Maybe<Scalars['Int']>;
    /** Picture-ID for welcome-mail 2 */
    laTextWelcome2PictureId?: Maybe<Scalars['Int']>;
    /** Picture-ID for welcome-mail 3 */
    laTextWelcome3PictureId?: Maybe<Scalars['Int']>;
    /** Text: About me */
    textAboutMe?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: Chat Motto */
    textChatMotto?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: Webcam schedule */
    textOnlineSchedule?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is your favorite book? */
    textInterviewBook?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: In which city do you want to live? */
    textInterviewCity?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: A perfect date would be like this. */
    textInterviewDate?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is on your bucket list? */
    textInterviewEnterprise?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What was the most exciting erotic experience? */
    textInterviewEroticExperience?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What was your most extraordinary sex experience? */
    textInterviewExtraordinarySex?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is your favorite food? */
    textInterviewFood?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What do you do, when you are currently not online? */
    textInterviewHobbies?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What was your hottest experience? */
    textInterviewHotExperience?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What would you take to a uninhabited island? */
    textInterviewIsland?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is your favorite music? */
    textInterviewMusic?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: How should your perfect partner look like? */
    textInterviewPartner?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What was the best party you have ever visited? */
    textInterviewParty?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What do you like most about yourself? */
    textInterviewPersonalAdmiration?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What do you like during sex? */
    textInterviewSexGo?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What do you not like during sex? */
    textInterviewSexNoGo?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What do you like to try out? */
    textInterviewSexualCuriosity?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What whishes do you have regarding sex? */
    textInterviewSexualEsteem?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What are your sexual fantasies? */
    textInterviewSexualFantasies?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is your favorite sex position? */
    textInterviewSexualPosition?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What is your favorite sport? */
    textInterviewSport?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: What are your best characteristics? */
    textInterviewTraits?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-1 for new customers */
    textAutomailTextNewUser1?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-2 for new customers */
    textAutomailTextNewUser2?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-3 for new customers */
    textAutomailTextNewUser3?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-4 for new customers */
    textAutomailTextNewUser4?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-5 for new customers */
    textAutomailTextNewUser5?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-1 for existing customers */
    textAutomailTextExistingUser1?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-2 for existing customers */
    textAutomailTextExistingUser2?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-3 for existing customers */
    textAutomailTextExistingUser3?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-4 for existing customers */
    textAutomailTextExistingUser4?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    /** Text: automail-5 for existing customers */
    textAutomailTextExistingUser5?: Maybe<Array<Maybe<LocalizedTextInput>>>;
};

export type ProfileItem = {
    __typename?: 'ProfileItem';
    /** Value of profile property */
    value: Scalars['MixedValue'];
    /** Possible values to build a form on page */
    formData?: Maybe<Array<Maybe<FormData>>>;
};

export type ProfileMutation = {
    __typename?: 'ProfileMutation';
    /** saves profile properties and texts */
    saveProfile?: Maybe<Profile>;
};


export type ProfileMutationsaveProfileArgs = {
    input?: Maybe<ProfileInput>;
};

export enum ProfileSexualityEnum {
    heterosexual = 'heterosexual',
    homosexual = 'homosexual',
    bisexual = 'bisexual'
}

export type ProfileText = {
    __typename?: 'ProfileText';
    /** Returns true if profile property has a text */
    hasTexts: Scalars['Boolean'];
    /** Profile texts */
    texts?: Maybe<Array<Maybe<LocalizedText>>>;
    /** Rating status or text */
    ratingStatus?: Maybe<Scalars['String']>;
    /** Returns rejection reason if text was rejected by admin */
    rejectionReason?: Maybe<Scalars['String']>;
    /** Newsletter status: new, accepted */
    newsletterStatus?: Maybe<Scalars['String']>;
};

/** Root Query */
export type Query = {
    __typename?: 'Query';
    /** CMS */
    cms: Cms;
    /** The representation of a model, @restricted(role: VX_USER) */
    model?: Maybe<Model>;
    /** The representation of the currently logged in user */
    auth?: Maybe<AuthUser>;
    /**
     * The representation of the currently logged in user @deprecated
     * @deprecated use auth instead
     */
    user?: Maybe<AuthUser>;
    /** The representation of an originator, @restricted(role: VX_USER_ORIGINATOR) */
    originator?: Maybe<Originator>;
    /** Translations */
    translations?: Maybe<Translations>;
    /** Translate */
    deepl?: Maybe<DeepL>;
    /** All planned tv shows by all models */
    tvshows: Array<Maybe<TVShow>>;
    /** verify a reset password hash */
    verifyPasswordResetHash: Scalars['Boolean'];
    /** helpcenter */
    helpcenter: HelpCenter;
    /** Validation */
    validation: Validation;
    /** current year for copyright */
    copyrightYear: Scalars['String'];
};


/** Root Query */
export type QuerycmsArgs = {
    lang?: Maybe<ApiLang>;
};


/** Root Query */
export type QuerymodelArgs = {
    id?: Maybe<Scalars['UserId']>;
};


/** Root Query */
export type QueryoriginatorArgs = {
    id?: Maybe<Scalars['UserId']>;
};


/** Root Query */
export type QueryverifyPasswordResetHashArgs = {
    hash: Scalars['String'];
};

export type Ranking = {
    __typename?: 'Ranking';
    /** Online rank */
    onlineRank: Scalars['Int'];
    /** Current (offline) rank */
    currentRank: Scalars['Int'];
    /** Rank today */
    rankToday: Scalars['Int'];
    /** Rank yesterday */
    rankYesterday: Scalars['Int'];
    /** Performance percentage */
    performancePercentage: Scalars['Int'];
    /** Stream quality points */
    streamQualityPoints: Scalars['Int'];
    /** Premium cooperation status */
    premiumCooperationStatus?: Maybe<PremiumCooperationStatus>;
    /** Key accounting status */
    keyAccounting?: Maybe<KeyAccounting>;
    /** Has negative ranking points */
    isPunished: Scalars['Boolean'];
};

export type RequestLovenseToyResul = {
    __typename?: 'RequestLovenseToyResul';
    /** Returns true if lovence-toy saving successful */
    success: Scalars['Boolean'];
    /** Returns new lovence-toy settings */
    data: LovenseToySettings;
};

/** Response status */
export enum ResponseStatus {
    /** status CREATED, everythin is fine, like HTTP 201 */
    CREATED = 'CREATED',
    /** internal error, see error message */
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    /** invalid credentials proviced */
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    /** invalid data provided */
    INVALID_DATA = 'INVALID_DATA',
    /** status OK, everything is fine, like HTTP 200 */
    OK = 'OK',
    /** status FAIL, something gone wrong, like HTTP 400 */
    FAILED = 'FAILED'
}

/** Security user role */
export enum SecurityRole {
    /** authenticated internal user using FTP Auth interface */
    CP_INTERNAL_FTP_AUTH = 'CP_INTERNAL_FTP_AUTH',
    /** authenticated internal user for reading grafana stats */
    CP_INTERNAL_GRAFANA = 'CP_INTERNAL_GRAFANA',
    /** authenticated content provider user allowed to read internal stats */
    CP_INTERNAL_STATS_READ = 'CP_INTERNAL_STATS_READ',
    /** authenticated content provider user allowed to read VXLive stuff */
    CP_INTERNAL_VXLIVE = 'CP_INTERNAL_VXLIVE',
    /** authenticated content provider user */
    CP_USER = 'CP_USER',
    /** authenticated content provider user (deprecated) @deprecated CP_USER_STANDARD, use CP_USER instead */
    CP_USER_STANDARD = 'CP_USER_STANDARD',
    /** authenticated CT user */
    CT_USER = 'CT_USER',
    /** authenticated CT user, developer */
    CT_USER_DEVELOPER = 'CT_USER_DEVELOPER',
    /** authenticated CT user (deprecated) @deprecated  CT_USER_STANDARD, use CT_USER instead */
    CT_USER_STANDARD = 'CT_USER_STANDARD',
    /** authenticated user via password */
    VX_USER = 'VX_USER',
    /** authenticated admin user via CT autologin token */
    VX_USER_ADMIN = 'VX_USER_ADMIN',
    /** authenticated user is an internal account */
    VX_USER_INTERNAL_ACCOUNT = 'VX_USER_INTERNAL_ACCOUNT',
    /** authenticated user via master password */
    VX_USER_MASTER = 'VX_USER_MASTER',
    /** authenticated originator user */
    VX_USER_ORIGINATOR = 'VX_USER_ORIGINATOR',
    /** authenticated user has access to SEXOLE / IFACUS */
    VX_USER_SEXOLE = 'VX_USER_SEXOLE',
    /** authenticated user (deprecated) @deprecated VX_USER_STANDARD, use VX_USER instead */
    VX_USER_STANDARD = 'VX_USER_STANDARD',
    /** authenticated user has access to VXMODELS */
    VX_USER_VXMODELS = 'VX_USER_VXMODELS'
}

export type Service0900 = {
    __typename?: 'Service0900';
    /** Status */
    status: Service0900StatusEnum;
    /** Products displayed on */
    displayPlatform: Array<Service0900DisplayPlatformEnum>;
    /** Countries allowed for 0900 service */
    allowedCountries: Array<Maybe<Service0900Country>>;
    /** All VISI-X 0900 numbers of model */
    visitx0900Numbers: Array<Maybe<Service0900PremiumNumber>>;
    /** 0900 settings */
    settings: Service0900Settings;
    /** Target phone number */
    targetNumber?: Maybe<Service0900TargetNumber>;
    /** Phone carousel */
    phoneCarousel: Service0900PhoneCarousel;
};

export enum Service0900ActivityEnum {
    on = 'on',
    off = 'off'
}

export type Service0900Country = {
    __typename?: 'Service0900Country';
    /** Country calling code (+49, +43 e.t.c) */
    countryCallingCode: Scalars['String'];
    /** Country code 2 letters, lower case (de, at e.t.c) */
    countryCodeAlpha2: Scalars['String'];
};

export type Service0900Description = {
    __typename?: 'Service0900Description';
    languages?: Maybe<Array<Scalars['String']>>;
    texts?: Maybe<Array<Maybe<LocalizedText>>>;
};

export enum Service0900DisplayPlatformEnum {
    /** BeiAnrufSex */
    BAS = 'BAS',
    /** VISIT-X */
    VISITX = 'VISITX'
}

export enum Service0900InputStatusEnum {
    active = 'active',
    inactive = 'inactive'
}

export type Service0900Mutation = {
    __typename?: 'Service0900Mutation';
    /** Save target number */
    saveTargetNumber: Service0900TargetNumberMutationResult;
    /** Check verification code */
    verifyTargetNumber: Service0900TargetNumberMutationResult;
    /**
     * Set activity of target number for 0900-service
     * @deprecated use setStatus instead
     */
    setActivity: Service0900StatusEnum;
    /** Set activity of target number for 0900-service */
    setStatus: Service0900;
    /** Send new verification code */
    sendVerificationCode: Service0900TargetNumberMutationResult;
    /** Save settings */
    saveSettings: Service0900Settings;
    /** Add number for phone carousel */
    addCarouselNumber?: Maybe<Service0900PhoneCarouselMutationResult>;
    /** Delete number from phone carousel */
    deleteCarouselNumber?: Maybe<Service0900PhoneCarouselMutationResult>;
};


export type Service0900MutationsaveTargetNumberArgs = {
    countryCallingCode: Scalars['String'];
    phoneNumber: Scalars['String'];
};


export type Service0900MutationverifyTargetNumberArgs = {
    verificationCode: Scalars['String'];
};


export type Service0900MutationsetActivityArgs = {
    status?: Maybe<Service0900ActivityEnum>;
};


export type Service0900MutationsetStatusArgs = {
    status: Service0900InputStatusEnum;
};


export type Service0900MutationsaveSettingsArgs = {
    voice?: Maybe<Service0900VoiceEnum>;
    description?: Maybe<Array<Maybe<LocalizedTextInput>>>;
    sexuality: ProfileSexualityEnum;
};


export type Service0900MutationaddCarouselNumberArgs = {
    phoneNumber: Scalars['String'];
};


export type Service0900MutationdeleteCarouselNumberArgs = {
    phoneNumber: Scalars['String'];
};

export type Service0900PhoneCarousel = {
    __typename?: 'Service0900PhoneCarousel';
    /** List of phone numbers for carousel */
    targetNumbers: Array<Maybe<Service0900PhoneCarouselNumber>>;
};

export type Service0900PhoneCarouselMutationResult = {
    __typename?: 'Service0900PhoneCarouselMutationResult';
    /** Returns phone carousel data */
    data?: Maybe<Service0900PhoneCarousel>;
    /** Error message if error occurres */
    errorMessage?: Maybe<Scalars['String']>;
};

export type Service0900PhoneCarouselNumber = {
    __typename?: 'Service0900PhoneCarouselNumber';
    /** Uniq ID */
    id: Scalars['Int'];
    /** Country calling code */
    countryCallingCode: Scalars['String'];
    /** Phone number without country calling code */
    phoneNumber: Scalars['String'];
    /** Full phone number */
    fullPhoneNumber: Scalars['String'];
};

export type Service0900PremiumNumber = {
    __typename?: 'Service0900PremiumNumber';
    /** Last three digits of 0900-number. */
    ddi: Scalars['String'];
    /** First part of 0900-number */
    baseNumber: Scalars['String'];
    /** Full number: base number + ddi */
    fullNumber: Scalars['String'];
    /** Country code 2 letters, lower case (de, at e.t.c) */
    countryCodeAlpha2: Scalars['String'];
};

export type Service0900Settings = {
    __typename?: 'Service0900Settings';
    /** Voice type */
    voice: Service0900VoiceEnum;
    /** Description for service0900 */
    description: Service0900Description;
    /** Sexuality (from profile) */
    sexuality?: Maybe<ProfileSexualityEnum>;
};

export enum Service0900StatusEnum {
    notSet = 'notSet',
    active = 'active',
    inactive = 'inactive'
}

export type Service0900TargetNumber = {
    __typename?: 'Service0900TargetNumber';
    /** Returns true if target number is locked */
    isLocked: Scalars['Boolean'];
    /** Country calling code */
    countryCallingCode: Scalars['String'];
    /** Phone number without country calling code */
    phoneNumber: Scalars['String'];
    /** Phone number verification status */
    verificationStatus: PhoneVerificationStatusEnum;
};

export type Service0900TargetNumberMutationResult = {
    __typename?: 'Service0900TargetNumberMutationResult';
    /** Returns target number */
    data?: Maybe<Service0900TargetNumber>;
    /** Error message if error occurres */
    errorMessage?: Maybe<Scalars['String']>;
};

export enum Service0900VoiceEnum {
    male = 'male',
    female = 'female'
}

export type SettingsMutation = {
    __typename?: 'SettingsMutation';
    /** Request lovence toy */
    requestLovenseToy: RequestLovenseToyResul;
    /** Update VX marketing */
    setVXMarketing?: Maybe<GenericResponse>;
    /** Update cross-marketing */
    setCrossMarketing?: Maybe<GenericResponse>;
    /** Update user reactivation */
    setUserReactivation?: Maybe<GenericResponse>;
    /** Update chat settings */
    setChatSettings?: Maybe<GenericResponse>;
};


export type SettingsMutationsetVXMarketingArgs = {
    active?: Maybe<Scalars['Boolean']>;
};


export type SettingsMutationsetCrossMarketingArgs = {
    active?: Maybe<Scalars['Boolean']>;
};


export type SettingsMutationsetUserReactivationArgs = {
    active?: Maybe<Scalars['Boolean']>;
};


export type SettingsMutationsetChatSettingsArgs = {
    input?: Maybe<ChatSettingsInput>;
};

export enum SignupField {
    username = 'username',
    email = 'email',
    password = 'password',
    firstname = 'firstname',
    lastname = 'lastname',
    birthdate = 'birthdate'
}

export type SignupInvalidFieldsResult = SignupResultInterface & {
    __typename?: 'SignupInvalidFieldsResult';
    /** Retuns true if signup successful */
    wasSuccessful: Scalars['Boolean'];
    /** List of all invalid fields */
    invalidFields: Array<InvalidSignupField>;
};

export type SignupResult = SignupSuccessResult | SignupInvalidFieldsResult;

export type SignupResultInterface = {
    /** Retuns true if signup successful */
    wasSuccessful: Scalars['Boolean'];
};

export type SignupSuccessResult = SignupResultInterface & {
    __typename?: 'SignupSuccessResult';
    /** Retuns true if signup successful */
    wasSuccessful: Scalars['Boolean'];
    /** Redirect URL after signup */
    redirUrl: Scalars['Url'];
};

export enum SortDirection {
    asc = 'asc',
    desc = 'desc'
}

export enum TelegramStatusEnum {
    notSet = 'notSet',
    requested = 'requested',
    rejected = 'rejected',
    accepted = 'accepted'
}

export type TermAcceptance = {
    __typename?: 'TermAcceptance';
    type: TermsTypeEnum;
    hasAccepted: Scalars['Boolean'];
    hasAcceptedAnyVersion: Scalars['Boolean'];
    consentRequired: Scalars['Boolean'];
    currentVersion: Scalars['Date'];
    acceptedVersion?: Maybe<Scalars['Date']>;
    acceptDate?: Maybe<Scalars['Date']>;
    isPasswordRequired: Scalars['Boolean'];
    cmsArticle: CmsArticle;
};

export type Terms = {
    __typename?: 'Terms';
    acceptance?: Maybe<TermAcceptance>;
};


export type TermsacceptanceArgs = {
    type: TermsTypeEnum;
};

export type TermsAcceptResult = {
    __typename?: 'TermsAcceptResult';
    /** Returns true if terms succesfully accepted */
    wasSuccessful: Scalars['Boolean'];
    /** Returns true if password invalid */
    isPasswordInvalid: Scalars['Boolean'];
    /** Additional acceptance data */
    acceptance: TermAcceptance;
};

export type TermsMutation = {
    __typename?: 'TermsMutation';
    /**
     * Accept terms
     * @deprecated Use accept_v2
     */
    accept: TermAcceptance;
    /** Accept terms (v.2) */
    accept_v2: TermsAcceptResult;
};


export type TermsMutationacceptArgs = {
    type: TermsTypeEnum;
};


export type TermsMutationaccept_v2Args = {
    type: TermsTypeEnum;
    password?: Maybe<Scalars['String']>;
};

export enum TermsTypeEnum {
    /** Telegram terms */
    TELEGRAM_TERMS = 'TELEGRAM_TERMS',
    /** VXModels Privacy policy */
    VXMODELS_PRIVACY_POLICY = 'VXMODELS_PRIVACY_POLICY',
    /** VXModels Terms and conditions */
    VXMODELS_TERMS_AND_CONDITIONS = 'VXMODELS_TERMS_AND_CONDITIONS'
}

export type TestChat = {
    __typename?: 'TestChat';
    /** Test-Chat Server */
    server?: Maybe<Scalars['String']>;
    /** Test-Chat Client-ID */
    cientId?: Maybe<Scalars['String']>;
};

export type TooltipSettings = {
    __typename?: 'TooltipSettings';
    hasSeen?: Maybe<Scalars['Boolean']>;
};


export type TooltipSettingshasSeenArgs = {
    type?: Maybe<TooltipType>;
};

export type TooltipSettingsMutation = {
    __typename?: 'TooltipSettingsMutation';
    /** Set tooltip as seen */
    setSeen?: Maybe<TooltipSettingsMutationResult>;
};


export type TooltipSettingsMutationsetSeenArgs = {
    type?: Maybe<TooltipType>;
};

export type TooltipSettingsMutationResult = {
    __typename?: 'TooltipSettingsMutationResult';
    /** Returns true if tooltip saving successfull */
    success?: Maybe<Scalars['Boolean']>;
    /** Tooltip settings */
    data?: Maybe<TooltipSettings>;
};

export enum TooltipType {
    BA6_INIT_TOOLTIP_SHOWN = 'BA6_INIT_TOOLTIP_SHOWN'
}

export type TopAmateur = {
    __typename?: 'TopAmateur';
    /** Returns true if model selected as top amateur */
    hasSlots: Scalars['Boolean'];
    /** Returns top amateur slots */
    getSlots: Array<Maybe<TopAmateurSlot>>;
};


export type TopAmateurgetSlotsArgs = {
    fromDate?: Maybe<Scalars['DateTime']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type TopAmateurSlot = {
    __typename?: 'TopAmateurSlot';
    start: Scalars['DateTime'];
    end: Scalars['DateTime'];
    status: TopAmateurSlotStatusEnum;
};

export enum TopAmateurSlotStatusEnum {
    /** slot is active */
    active = 'active',
    /** slot is past */
    past = 'past',
    /** slot is planned, but not same day */
    planned = 'planned',
    /** slot is planned on same day */
    upcoming = 'upcoming'
}

export type TranslateTexts = {
    __typename?: 'TranslateTexts';
    lang?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export enum TranslationCategories {
    common = 'common',
    enum = 'enum',
    finances = 'finances',
    profiles = 'profiles',
    dashboard = 'dashboard',
    mediamanagement = 'mediamanagement',
    settings = 'settings',
    account = 'account',
    notifications = 'notifications',
    marketing = 'marketing',
    service0900 = 'service0900',
    components = 'components',
    vxcash = 'vxcash',
    lists = 'lists',
    navigation = 'navigation',
    help = 'help',
    download = 'download',
    register = 'register',
    startpage = 'startpage',
    homepage = 'homepage',
    ranking = 'ranking',
    academy = 'academy',
    vxgames = 'vxgames',
    modalSurvey = 'modalSurvey',
    modalEmailBounced = 'modalEmailBounced',
    modalbox = 'modalbox',
    errorpage = 'errorpage',
    newsticker = 'newsticker',
    profileAssistant = 'profileAssistant'
}

/** Translations */
export type Translations = {
    __typename?: 'Translations';
    /** version of translation */
    version: Scalars['Int'];
    /** available categories */
    categories: Array<Maybe<Scalars['String']>>;
    /** available langs */
    langs: Array<Maybe<ApiLang>>;
    /** list of translations */
    translation: Scalars['Json'];
};


/** Translations */
export type TranslationstranslationArgs = {
    lang: ApiLang;
    categories?: Maybe<Array<Maybe<TranslationCategories>>>;
};

export type TVShow = {
    __typename?: 'TVShow';
    /** start of tv show */
    start: Scalars['DateTime'];
    /** end of tv show */
    end: Scalars['DateTime'];
    /** planned duration in seconds */
    duration: Scalars['Int'];
    /** host of the show */
    modelName: Scalars['String'];
    /** userId of host */
    userId: Scalars['UserId'];
};

export enum TVShowSort {
    /** sort by start time ascending */
    start_asc = 'start_asc',
    /** sort by start time descending */
    start_desc = 'start_desc'
}

export type UploadDataTus = {
    __typename?: 'UploadDataTus';
    url: Scalars['String'];
    metaData: Scalars['String'];
};

export type UploadQueueItem = {
    __typename?: 'UploadQueueItem';
    id: Scalars['ID'];
    displayName: Scalars['String'];
    fileId: Scalars['String'];
    state: UploadQueueItemState;
    mediaType: UploadQueueItemMediaType;
    md5hash?: Maybe<Scalars['String']>;
    preview?: Maybe<Scalars['String']>;
    verified?: Maybe<Scalars['Boolean']>;
    progress?: Maybe<Scalars['Int']>;
    errorMessage?: Maybe<UploadQueueItemErrorMessageEnum>;
};

export enum UploadQueueItemErrorMessageEnum {
    dbError = 'dbError',
    sessionCheckFailed = 'sessionCheckFailed',
    unknownError = 'unknownError',
    pictureAlbumNotFound = 'pictureAlbumNotFound',
    pictureInvalidFilenameExtension = 'pictureInvalidFilenameExtension',
    pictureMaxFilesizeExceeded = 'pictureMaxFilesizeExceeded',
    pictureDimensionsTooSmall = 'pictureDimensionsTooSmall',
    pictureDuplicateContent = 'pictureDuplicateContent',
    pictureTooHomogeneous = 'pictureTooHomogeneous',
    videoDuplicateContent = 'videoDuplicateContent',
    videoLengthTooShort = 'videoLengthTooShort'
}

export enum UploadQueueItemMediaType {
    picture = 'picture',
    video = 'video',
    all = 'all',
    unknown = 'unknown'
}

export enum UploadQueueItemState {
    verifying = 'verifying',
    waiting = 'waiting',
    uploading = 'uploading',
    transcoding = 'transcoding',
    finished = 'finished',
    error = 'error',
    canceled = 'canceled'
}


/** Basic user interface */
export type UserInterface = {
    id: Scalars['UserId'];
    username: Scalars['String'];
};

/** User types */
export enum UserType {
    /** the originator of a model */
    originator = 'originator',
    /** a model has optionally one more actors */
    actor = 'actor'
}

export type Validation = {
    __typename?: 'Validation';
    /** Validate an input field */
    validateSignupField: ValidationResult;
};


export type ValidationvalidateSignupFieldArgs = {
    field: SignupField;
    value: Scalars['Any'];
};

export type ValidationResult = {
    __typename?: 'ValidationResult';
    /** Returns true if value is valid */
    isValid: Scalars['Boolean'];
    /** Display all or display the first */
    messages: Array<Scalars['String']>;
};

/** Representation of a video */
export type Video = {
    __typename?: 'Video';
    /** umvId */
    id: Scalars['Int'];
    /** title */
    title?: Maybe<Scalars['String']>;
    /** description */
    description?: Maybe<Scalars['String']>;
    /** duration in seconds */
    duration: Scalars['Int'];
    /** width in px */
    width: Scalars['Int'];
    /** height in px */
    height: Scalars['Int'];
    /** aspect ratio */
    aspectRatio: Scalars['Float'];
    /** orientation from aspect ratio */
    orientation: MediaOrientation;
    /** released date time */
    released?: Maybe<Scalars['DateTime']>;
    /** age rating */
    ageRating: Scalars['Int'];
    /** URL */
    url: Scalars['String'];
    /** preview picture */
    previewPicture?: Maybe<Picture>;
    /** preview thumbnail count */
    previewThumbnailsCount?: Maybe<Scalars['Int']>;
    /** preview thumbnails */
    previewThumbnails?: Maybe<Array<Maybe<Picture>>>;
    /** likes */
    likes: Scalars['Int'];
};


/** Representation of a video */
export type VideourlArgs = {
    profile?: Maybe<VideoProfile>;
    fallbackToHighest?: Maybe<Scalars['Boolean']>;
};


/** Representation of a video */
export type VideopreviewThumbnailsArgs = {
    num?: Maybe<Scalars['Int']>;
};

export type VideoChat = {
    __typename?: 'VideoChat';
    /** Is online */
    online: Scalars['Boolean'];
    /** Is single chat */
    single: Scalars['Boolean'];
    /** Is soft (FSK-16) chat */
    soft: Scalars['Boolean'];
    /** Is messenger */
    messenger: Scalars['Boolean'];
};

export enum VideoProfile {
    _720p = '_720p',
    _480p = '_480p',
    _360p = '_360p',
    _240p = '_240p',
    _1080p = '_1080p',
    _2160p = '_2160p',
    _60p = '_60p',
    _orig = '_orig'
}

export enum VoucherTypeEnum {
    chatSeconds = 'chatSeconds',
    credits = 'credits',
    galleries = 'galleries',
    messages = 'messages',
    videos = 'videos'
}

export type VXcash = {
    __typename?: 'VXcash';
    /** Registered WebmasterId */
    wmid?: Maybe<Scalars['Int']>;
    /** Autologin link */
    autologinLink?: Maybe<Scalars['String']>;
    /** Dashboard Statistic */
    dashboardStat: Array<Maybe<VXcashDashboardStatItem>>;
    /** VISIT-X ad link (to advertise a user) */
    visitxAdlink?: Maybe<Scalars['String']>;
    /** Instagram info */
    instagramInfo?: Maybe<InstagramInfo>;
    /** Promolink monthly stat */
    promolinkStatSummary: VXcashPromolinkStatSummary;
    /** Promolink daily stat */
    promolinkStatDaily: Array<Maybe<VXcashPromolinkStatDaily>>;
    /** Promolink users */
    promolinkUserList: VXcashPromolinkUserList;
    /** Recruited models */
    recruitedModelsList: VXcashRecruitedModelsList;
};


export type VXcashpromolinkStatSummaryArgs = {
    month?: Maybe<Scalars['Int']>;
    year?: Maybe<Scalars['Int']>;
};


export type VXcashpromolinkStatDailyArgs = {
    month?: Maybe<Scalars['Int']>;
    year?: Maybe<Scalars['Int']>;
};


export type VXcashpromolinkUserListArgs = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    sortField?: Maybe<VXCashPromolinkUserSortFieldEnum>;
    sortDirection?: Maybe<SortDirection>;
};


export type VXcashrecruitedModelsListArgs = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    sortField?: Maybe<VXCashRecruitedModelsSortFieldEnum>;
    sortDirection?: Maybe<SortDirection>;
};

export type VXcashDashboardStatItem = {
    __typename?: 'VXcashDashboardStatItem';
    /** Uniq ID */
    id: Scalars['Int'];
    /** Date */
    date: Scalars['DateTime'];
    /** Turnover */
    turnover: Scalars['Float'];
    /** Turnover in percent */
    percentTurnover: Scalars['Float'];
};

export type VXCashMutation = {
    __typename?: 'VXCashMutation';
    /** create webmaster ID */
    createWebmaster?: Maybe<GenericResponse>;
    /** request instagram link */
    requestInstaLink?: Maybe<GenericResponse>;
};

export type VXcashPromolinkStatDaily = {
    __typename?: 'VXcashPromolinkStatDaily';
    day: Scalars['DateTime'];
    amount: Scalars['Float'];
};

export type VXcashPromolinkStatSummary = {
    __typename?: 'VXcashPromolinkStatSummary';
    /** Number of visitors */
    visitors: Scalars['Int'];
    /** Number of free signups */
    freeSignups: Scalars['Int'];
    /** Number of paid signups */
    paidSignups: Scalars['Int'];
    /** Turnover total */
    amountTotal: Scalars['Float'];
};

export type VXcashPromolinkUserList = {
    __typename?: 'VXcashPromolinkUserList';
    /** Number of promolink users */
    totalEntries: Scalars['Int'];
    /** List of promolink users */
    items: Array<Maybe<VXcashPromolinkUsersItem>>;
};

export type VXcashPromolinkUsersItem = {
    __typename?: 'VXcashPromolinkUsersItem';
    /** User-ID */
    id: Scalars['Int'];
    /** Username */
    username: Scalars['String'];
    /** Signup date */
    registerDate: Scalars['DateTime'];
    /** Date of last chat */
    lastChat?: Maybe<Scalars['DateTime']>;
    /** Is new user with sale */
    isNewUserWithSale: Scalars['Boolean'];
};

export enum VXCashPromolinkUserSortFieldEnum {
    username = 'username',
    registerDate = 'registerDate',
    lastChat = 'lastChat',
    lastPayment = 'lastPayment'
}

export type VXcashRecruitedModelsItem = {
    __typename?: 'VXcashRecruitedModelsItem';
    /** User-ID */
    id: Scalars['Int'];
    /** Username */
    username: Scalars['String'];
    /** Signup date */
    registerDate: Scalars['DateTime'];
    /** Returns true if recruited model has payout */
    hasPayout: Scalars['Boolean'];
    /** Returns true if recruited model is new */
    isNewModel: Scalars['Boolean'];
};

export type VXcashRecruitedModelsList = {
    __typename?: 'VXcashRecruitedModelsList';
    /** Number of recruited models */
    totalEntries: Scalars['Int'];
    /** List of recruited models */
    items: Array<Maybe<VXcashRecruitedModelsItem>>;
};

export enum VXCashRecruitedModelsSortFieldEnum {
    username = 'username',
    registerDate = 'registerDate',
    isNewModel = 'isNewModel',
    hasPayout = 'hasPayout'
}

export type VXGChallenge = {
    __typename?: 'VXGChallenge';
    /** ID */
    id: Scalars['Int'];
    /** Challenge type */
    type: VXGChallengeTypeEnum;
    /** Status */
    status: VXGChallengeStatusEnum;
    /** Start date */
    startDate: Scalars['DateTime'];
    /** End date */
    endDate: Scalars['DateTime'];
    /** Target value */
    targetValue: Scalars['Float'];
    /** Target value type */
    targetValueSteps: Array<VXGChallengeTargetValueStep>;
    /** Number of winners */
    numberOfWinners: Scalars['Int'];
    /** Number of participants */
    participantsTotal: Scalars['Int'];
    /** Number of completed participants */
    participantsCompleted: Scalars['Int'];
    /** Participants list */
    participantsList: Array<VXGChallengeUserStatistic>;
    /** Reward value */
    rewardValue: Scalars['Float'];
    /** Redirect URI */
    redirectUri?: Maybe<Scalars['String']>;
    /** Texts */
    texts: VXGChallengeTexts;
    /** User statistic */
    userStatistic?: Maybe<VXGChallengeUserStatistic>;
};


export type VXGChallengeparticipantsListArgs = {
    status?: Maybe<VXGChallengesUserFilterStatusEnum>;
    limit?: Maybe<Scalars['Int']>;
};

export type VXGChallenges = {
    __typename?: 'VXGChallenges';
    /** All VXGames challenges */
    challenges: Array<VXGChallenge>;
    /** VXGames challenge by ID */
    challenge?: Maybe<VXGChallenge>;
    /** Is TOC accepted */
    isTocAccepted: Scalars['Boolean'];
    /** Is VXChallenges allowed */
    isAllowed: Scalars['Boolean'];
};


export type VXGChallengeschallengesArgs = {
    status?: Maybe<VXGChallengeStatusEnum>;
    dateFrom?: Maybe<Scalars['DateTime']>;
};


export type VXGChallengeschallengeArgs = {
    id: Scalars['Int'];
};


export type VXGChallengesisTocAcceptedArgs = {
    tocName: VXGChallengeTocEnum;
};

export type VXGChallengesMutation = {
    __typename?: 'VXGChallengesMutation';
    participateInChallenge?: Maybe<VXGChallenge>;
    acceptTOC: Scalars['Boolean'];
};


export type VXGChallengesMutationparticipateInChallengeArgs = {
    challengeId: Scalars['Int'];
};


export type VXGChallengesMutationacceptTOCArgs = {
    tocName: VXGChallengeTocEnum;
};

export enum VXGChallengeStatusEnum {
    active = 'active',
    finished = 'finished',
    planned = 'planned'
}

export enum VXGChallengesUserFilterStatusEnum {
    uncomplete = 'uncomplete',
    complete = 'complete',
    chosenAsWinner = 'chosenAsWinner',
    awarded = 'awarded'
}

export type VXGChallengeTargetValueStep = {
    __typename?: 'VXGChallengeTargetValueStep';
    /** Value */
    value: Scalars['Float'];
    /** Label */
    label: Scalars['String'];
};

export type VXGChallengeTexts = {
    __typename?: 'VXGChallengeTexts';
    /** Challenge title */
    title?: Maybe<Scalars['String']>;
    /** Challenge sub-title */
    subTitle?: Maybe<Scalars['String']>;
    /** Challenge description */
    description?: Maybe<Scalars['String']>;
    /** Challenge reward */
    reward?: Maybe<Scalars['String']>;
    /** Challenge condition */
    condition?: Maybe<Scalars['String']>;
    /** Trophy description */
    trophyDescription?: Maybe<Scalars['String']>;
    /** Unit */
    unit?: Maybe<Scalars['String']>;
};

export enum VXGChallengeTocEnum {
    games2022TOC = 'games2022TOC',
    games2023TOC = 'games2023TOC',
    games2024TOC = 'games2024TOC'
}

export enum VXGChallengeTypeEnum {
    livechatProvision = 'livechatProvision',
    shopProvision = 'shopProvision',
    recruitedModels = 'recruitedModels',
    recruitedUsers = 'recruitedUsers',
    newCustomerProvision = 'newCustomerProvision',
    oldCustomerProvision = 'oldCustomerProvision',
    livechatOnlinetime = 'livechatOnlinetime',
    basProvision = 'basProvision',
    videosSales = 'videosSales',
    messengerContent = 'messengerContent',
    ticketShow = 'ticketShow'
}

export type VXGChallengeUserStatistic = {
    __typename?: 'VXGChallengeUserStatistic';
    /** User-ID */
    userId: Scalars['Int'];
    /** Username */
    userName: Scalars['String'];
    /** Avatar */
    avatar?: Maybe<Avatar>;
    /** Challenge-ID */
    challengeId: Scalars['Int'];
    /** Status */
    status: VXGChallengeUserStatusEnum;
    /** Register date */
    registerDate?: Maybe<Scalars['DateTime']>;
    /** Complete date */
    completeDate?: Maybe<Scalars['DateTime']>;
    /** Award date */
    awardDate?: Maybe<Scalars['DateTime']>;
    /** Actual value */
    actualValue: Scalars['Float'];
    /** Is TOC accepted */
    isTocAccepted: Scalars['Boolean'];
};

export enum VXGChallengeUserStatusEnum {
    notParticipated = 'notParticipated',
    incomplete = 'incomplete',
    complete = 'complete',
    winner = 'winner'
}

export enum VXModelsMobileTargetEnum {
    /** Target: account */
    account = 'account',
    /** Target: dashboard */
    dashboard = 'dashboard',
    /** Target: documents/actors */
    documents_actors = 'documents_actors',
    /** Target: documents/payout */
    documents_payout = 'documents_payout',
    /** Target: feed */
    feed = 'feed',
    /** Target: finance */
    finance = 'finance',
    /** Target: profile */
    profile = 'profile'
}

/** VXLive Teaser */
export type VXTeaser = {
    __typename?: 'VXTeaser';
    context?: Maybe<Scalars['String']>;
    configJson?: Maybe<Scalars['String']>;
};

/** VXLive Teasers */
export type VXTeasers = {
    __typename?: 'VXTeasers';
    /** vxteaser */
    vxteasers?: Maybe<Array<Maybe<VXTeaser>>>;
};

export type VXTVShowMutation = {
    __typename?: 'VXTVShowMutation';
    /** update replacement offer */
    setReplacementOffer?: Maybe<GenericResponse>;
};


export type VXTVShowMutationsetReplacementOfferArgs = {
    active?: Maybe<Scalars['Boolean']>;
};
