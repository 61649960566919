import React, { memo, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
// import { ThemeProvider } from 'emotion-theming'; // disabled due issues with upgraded emotion-theming, I think, we don't use it
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINT_PHONE_CONDITION } from '../camtool-styles';
import { Alert, LeftMenu, LoginRedirect, PhotoGallery, TopBar } from '../components';
import ModalboxState from '../components/Modalboxes';
import ModalDailyBonusOptIn from '../components/Modalboxes/ModalDailyBonusOptIn/ModalDailyBonusOptIn';
import TermsUpdatePrompts from '../components/Modalboxes/ModalTerms/TermsUpdatePrompts';
import ModalVerifyEmail from '../components/Modalboxes/ModalVerifyEmail/ModalVerifyEmail';
import ExternalRedirect from '../components/Routing/ExternalRedirect';
import TranslationConfig from '../components/Translation/TranslationConfig';
import { ApolloMultipleClientsProvider } from '../graphql';
import { ClientsNamespace } from '../graphql/types';
import { DailyBonusOptInStatusEnum, SecurityRole } from '../graphql/VXModels/types';
import ModalNewFeatures from '../components/Modalboxes/ModalNewFeatures/ModalNewFeatures';
import { AppState } from '../util/AppState';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { UploadManagerProvider } from '../packages/UploadManager';
import AuthCallback from '../routes/Login/AuthCallback';
import Login from '../routes/Login/Login';
import Logout from '../routes/Logout';
import MailVerify from '../routes/MailVerify';
import PasswordReset from '../routes/PasswordReset';
import { JsUploaderPictureStore, JsUploaderVideoStore } from '../stores/JsUploader/JsUploader';
import { Websocket } from '../stores/Websocket/Websocket';
import '../style/style.scss';
import {
  APP_API_HOST,
  APP_IS_DEVELOPMENT,
  APP_NAME,
  CONFIG_GOOGLE_TAG_MANAGER_ID_BETA,
  REACT_APP_BASE_PATH as basePath,
} from '../util/env';
import ServerUtils from '../util/ServerUtils';
import { getStartpageSignupUrl } from '../util/startpage';
import { hasRole, UserDataProvider } from '../util/UserData';
import Debug from '../_development/docs/Debug';
import { Content } from './Content';
import MUIThemeProvider from './Theme/MUIThemeProvider';
import GoogleTagManager from '../util/GoogleTagManager';
import TOSContentModal from '../routes/VXCash/Register/TOSContentModal';
import { useCookieConsent } from '../util/CookieConsent';

const DEBUG = APP_IS_DEVELOPMENT && true;
const App = memo(() => {
  // condition to render "New Features" Modal -> ToDo: Set Condition (i.e. Mobile Flag)
  const [showFeatureModal, setShowFeatureModal] = useState(true);
  const [isFeedNoticed, setIsFeedNoticed] = useLocalStorage('hasFeedFeatureModalNotice', false);
  useCookieConsent();

  return (
    <MUIThemeProvider>
      <AppState>
        {({ state: { authToken, sessionId, lang } }) => {
          DEBUG && console.log('AppState updated', { authToken, sessionId, lang });
          return (
            <TranslationConfig lang={lang}>
              <ApolloMultipleClientsProvider defaultNamespace={ClientsNamespace.VXModels}>
                <Router>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${APP_NAME}`}</title>
                  </Helmet>
                  <ModalboxState>
                    <Switch>
                      <Route exact path={`/auth/callback`} component={AuthCallback} />
                      <Route exact path={`${basePath}/login`} component={Login} />
                      <ExternalRedirect
                        exact
                        path={`${basePath}/signup`}
                        to={getStartpageSignupUrl(lang)}
                      />
                      <Route path="/passwordreset" component={PasswordReset} />
                      <Route
                        exact
                        path={`/mail/verify/:userId/:verificationCode`}
                        component={MailVerify}
                      />
                      <Route exact path={`${basePath}/logout`} component={Logout} />
                      <Route exact path={`${basePath}/debug`} component={Debug} />

                      {authToken ? (
                        <UserDataProvider>
                          {({
                            userData: {
                              auth: { id: userId, username },
                              model: {
                                isMobileRegistration,
                                contests: { contests = [] },
                                account: { email = '' },
                                extra: { picturePoolUmaId, isNewRegistrationWithoutVerifiedEmail },
                                media: { hasSubmittedAlbumsForDailyBonus, dailyBonusOptInStatus },
                              },
                            },
                          }) => {
                            DEBUG && console.log('App render');

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                              ServerUtils.init({
                                baseUrl: APP_API_HOST,
                                token: authToken,
                              });

                              Websocket.init(); // legacy websocket
                            }, [authToken]);

                            ServerUtils.setRouteParams({ userId });

                            JsUploaderVideoStore.init(userId, sessionId);
                            JsUploaderPictureStore.init(userId, sessionId, {
                              umaId: picturePoolUmaId,
                            });

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const [showMobileModal, setShowMobileModal] = useLocalStorage(
                              'showMobileModal',
                              false
                            );

                            const onClickCloseFeatureModal = () => {
                              setShowFeatureModal(false);
                              setIsFeedNoticed(true);
                            };

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const [isNoticed, setIsNoticed] = useLocalStorage('isNoticed', false);

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                              setShowMobileModal(
                                isMobileRegistration && !isMobile && !isNoticed ? true : false
                              );
                            }, [authToken]);

                            const isLoggedInViaCT = hasRole(SecurityRole.VX_USER_ADMIN);

                            const displayDailyBonusOptInModal =
                              !isLoggedInViaCT &&
                              hasSubmittedAlbumsForDailyBonus &&
                              dailyBonusOptInStatus === DailyBonusOptInStatusEnum.notSet;
                            return (
                              <UploadManagerProvider>
                                <div id="wrappercontent" className="spinner-container">
                                  <GoogleTagManager
                                    gtmId={CONFIG_GOOGLE_TAG_MANAGER_ID_BETA}
                                    userId={userId}
                                  />

                                  <TopBar
                                    isRegComplete={!isNewRegistrationWithoutVerifiedEmail}
                                    contests={contests}
                                  />
                                  <PhotoGallery />
                                  <Alert />
                                  <LeftMenu />
                                  <Content />
                                  {!showMobileModal && displayDailyBonusOptInModal && (
                                    <ModalDailyBonusOptIn />
                                  )}
                                  {!showMobileModal && isNewRegistrationWithoutVerifiedEmail && (
                                    <ModalVerifyEmail />
                                  )}
                                  {!showMobileModal && !isNewRegistrationWithoutVerifiedEmail && (
                                    <TermsUpdatePrompts />
                                  )}
                                  <TOSContentModal />
                                  {showFeatureModal && !isFeedNoticed && (
                                    <ModalNewFeatures
                                      onClickCloseModal={onClickCloseFeatureModal}
                                    />
                                  )}
                                </div>
                              </UploadManagerProvider>
                            );
                          }}
                        </UserDataProvider>
                      ) : (
                        <LoginRedirect />
                      )}
                    </Switch>
                  </ModalboxState>
                </Router>
              </ApolloMultipleClientsProvider>
            </TranslationConfig>
          );
        }}
      </AppState>
    </MUIThemeProvider>
  );
});

App.displayName = 'App';

export default App;
