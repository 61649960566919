import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Media, { useMedia } from 'react-media';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import logoSmall from '../../assets/images/logoSmall.svg';
import TopBarNotifications2 from './Notifications/TopBarNotifications2';
import Chat from './Chat/Chat';
import Profile from './Profile/Profile';
import CurrentCampaignCountdown from './CurrentCampaignCountdown/CurrentCampaignCountdown';
import { BREAKPOINT_SHRINK_LOGO } from './constants';
import { Service0900TopBar } from '../../packages/Service0900';
import VXModelsIcon from '../../atoms/Icon/VXModelsIcon';
import VXLogo from '../../atoms/Icon/VXLogo';
import { BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';
import { leftMenuWidthTablet, leftMenuWidth } from '../LeftMenu';
import IconBase from '../../atoms/Icon/IconBase';
import { useUserData } from '../../util/UserData';

const sharedLogoStyles = css`
  flex-grow: 0;
  flex-shrink: 0;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: ${BREAKPOINT_SHRINK_LOGO}px) {
    background-image: url(${logoSmall});
    flex-basis: 76px;
    background-size: 40px;
  }
`;

const VXModelsLogoContainer = styled.div`
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isLarge ? leftMenuWidth : leftMenuWidthTablet)}px;
`;

const TopBar = ({ isRegComplete, contests }) => {
  const isMobile = useMedia({ query: BREAKPOINT_PHONE_CONDITION });
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;

  return (
    <nav className="topbar">
      <VXModelsLogoContainer isLarge={!isMobile}>
        <>
          {isMobile ? (
            <IconBase fontSize="28.7px" iconElement={<VXLogo />} viewBox="0 0 28.7 20.5" />
          ) : (
            <VXModelsIcon />
          )}
        </>
      </VXModelsLogoContainer>
      <aside className="h-spacer" />
      <Media query="(min-width: 1000px)">
        {(matches) => {
          if (contests.length < 1) {
            return null;
          }

          if (Date.parse(contests[0].uploadEnd) < Date.now()) {
            return null;
          }

          if (contests[0].status !== 'active') {
            return null;
          }

          return matches ? <CurrentCampaignCountdown timestamp={contests[0].uploadEnd} /> : null;
        }}
      </Media>

      {isRegComplete && (
        <Fragment>
          <Service0900TopBar />
          <TopBarNotifications2 />
          <Chat isVerified={isVerified} />
        </Fragment>
      )}

      <Profile isRegComplete={isRegComplete} />
    </nav>
  );
};

TopBar.propTypes = {
  isRegComplete: PropTypes.bool,
  contests: PropTypes.arrayOf(
    PropTypes.shape({ uploadEnd: PropTypes.string, status: PropTypes.string })
  ),
};

TopBar.defaultProps = {
  isRegComplete: false,
  contests: [],
};

export default TopBar;
