import React, { FC } from 'react';
import { LeftMenuItem, LeftMenuGroup } from '../../components';
import { film, picture } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';

const ContentGroup: FC = () => {
  return (
    <LeftMenuGroup title={_('navigation:main.content')} icon={film}>
      <LeftMenuItem
        title={_('navigation:content.videos')}
        icon={film}
        uri="/mediamanagement/video"
      />
      <LeftMenuItem
        title={_('navigation:content.pictures')}
        icon={picture}
        uri="/mediamanagement/picture"
      />
    </LeftMenuGroup>
  );
};

export default ContentGroup;
