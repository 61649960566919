import { useEffect } from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import { CookieValue } from 'vanilla-cookieconsent';
import { useLang } from '../AppState';
import { setGtmConsent } from '../GoogleTagManager';
import de from './de';
import en from './en';
import { ConsentCategoryEnum } from './types';

const cookieName = 'cc_cookie';
const cookieDomain = '.' + window.location.hostname.split('.').splice(-2).join('.');
export const isFunctionalAccepted = (): boolean =>
  CookieConsent.acceptedCategory(ConsentCategoryEnum.functional);
const onConsent = ({ cookie }: { cookie: CookieValue }) => {
  // cleanup old cookies
  document.cookie = `${cookieName}=; Path=/; Domain=.app.vxmodels.com; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

  // functional cookies rejected
  if (!cookie.categories.includes(ConsentCategoryEnum.functional)) {
    console.log('CookieConsent: functional cookies rejected, clearing entries');

    window.localStorage.removeItem('cache.geoData');
    window.localStorage.removeItem('currentStep');
    window.localStorage.removeItem('i18nextLng');
    window.localStorage.removeItem('pubsub[session.init]');
    window.localStorage.removeItem('pubsub[session.update]');
    window.localStorage.removeItem('pubsub[session.close]');

    // session storage
    window.sessionStorage.removeItem('userdata');
    window.sessionStorage.removeItem('FOSP');
  }

  // Google Tag Manager consent
  setGtmConsent({
    necessary_storage: cookie.categories.includes(ConsentCategoryEnum.necessary)
      ? 'granted'
      : 'denied',
    functional_storage: cookie.categories.includes(ConsentCategoryEnum.functional)
      ? 'granted'
      : 'denied',
    analytics_storage: cookie.categories.includes(ConsentCategoryEnum.analytics)
      ? 'granted'
      : 'denied',
  });
};

export const useCookieConsent = (): void => {
  const [lang] = useLang();
  useEffect(() => {
    CookieConsent.run({
      cookie: { domain: cookieDomain, name: cookieName },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        functional: {},
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          layout: 'box inline',
        },
      },
      disablePageInteraction: true,
      language: {
        autoDetect: 'browser',
        default: lang,
        translations: { de, en },
      },
      onConsent,
      onChange: onConsent,
      autoShow: true,
      autoClearCookies: true,
      revision: 1,
    });
  }, [lang]);
};
