import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { listAlt } from '../../../../atoms/Icon/libraries/glyph';
import { LeftMenuItemBadge } from '../../components';
import { Query } from '@apollo/react-components';
import { useVXServicesNotificationsClient } from '../../../../graphql';
import { useLang } from '../../../../util/AppState';
import { VXMN_QUERY_NOTIFICATIONS } from '../../../../util/NotificationsHelper';
import { buildNewsCenterNotificationVariables } from '../../../../routes/NewsCenter/NewsCenter';

const NewscenterItem: FC = () => {
  const client = useVXServicesNotificationsClient();
  const [lang] = useLang();

  return (
    <LeftMenuItem title={_('navigation:main.newscenter')} icon={listAlt} uri="/newscenter">
      <Query
        client={client}
        query={VXMN_QUERY_NOTIFICATIONS}
        variables={buildNewsCenterNotificationVariables('', lang)}
        fetchPolicy="cache-only"
      >
        {({ data }: any) => {
          const edges = data?.vxmnQueryNotifications?.pagination?.edges ?? [];
          const items: any[] = edges.map((edge: any) => edge.node) ?? [];

          return items.length ? <LeftMenuItemBadge>{items.length}</LeftMenuItemBadge> : null;
        }}
      </Query>
    </LeftMenuItem>
  );
};

export default NewscenterItem;
