import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: any;
  /** Date in ISO 8601 format. */
  Date: any;
  Token: any;
  UUID: any;
  Url: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  peerId: Scalars['Long'];
  master?: Maybe<Master>;
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  peerName: Scalars['String'];
  comment: Scalars['String'];
  active: Scalars['Boolean'];
  workerLastSeenSuccess?: Maybe<Scalars['Boolean']>;
  userList: UserList;
  telegramSystemMessages: Array<Message>;
};


export type AccountuserListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilter>;
  order?: Maybe<UsersOrder>;
};

export type AccountWorker = {
  __typename?: 'AccountWorker';
  account: Account;
  workerLastSeen?: Maybe<Scalars['Date']>;
};

export type Admin = {
  __typename?: 'Admin';
  masterList?: Maybe<MasterList>;
  master?: Maybe<Master>;
  peerUserList?: Maybe<UserList>;
  siteList?: Maybe<SiteList>;
  statusMonitor: StatusMonitor;
  site?: Maybe<Site>;
  userActionList?: Maybe<UserActionList>;
  userReceivedMessagesList?: Maybe<UserReceivedMessagesList>;
  adminPaidContentList?: Maybe<AdminPaidContentList>;
  adminUserPaidContentList?: Maybe<AdminPaidContentList>;
  adminPaidContent?: Maybe<AdminPaidContent>;
  user?: Maybe<User>;
  userAction: Array<UserAction>;
  broadcastActivityMonitor?: Maybe<Array<BroadcastActivityMonitor>>;
  file?: Maybe<File>;
  broadcastList: BroadcastListV2;
  redisRead: Scalars['String'];
  defaultVipText: Scalars['String'];
};


export type AdminmasterListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  guestId?: Maybe<Scalars['Int']>;
  showActive?: Maybe<Scalars['Boolean']>;
};


export type AdminmasterArgs = {
  masterId: Scalars['Int'];
};


export type AdminpeerUserListArgs = {
  guestId: Scalars['Int'];
  masterId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type AdminsiteListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type AdminsiteArgs = {
  masterId?: Maybe<Scalars['Int']>;
  actorId?: Maybe<Scalars['Int']>;
};


export type AdminuserActionListArgs = {
  guestId: Scalars['Int'];
  masterId: Scalars['Int'];
};


export type AdminuserReceivedMessagesListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  guestId: Scalars['Int'];
  masterId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};


export type AdminadminPaidContentListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  guestId: Scalars['Int'];
  masterId?: Maybe<Scalars['Int']>;
};


export type AdminadminUserPaidContentListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};


export type AdminadminPaidContentArgs = {
  paidContentId: Scalars['String'];
};


export type AdminuserArgs = {
  userId: Scalars['Int'];
};


export type AdminuserActionArgs = {
  userId: Scalars['Int'];
};


export type AdminfileArgs = {
  fileUUID: Scalars['String'];
};


export type AdminbroadcastListArgs = {
  limit?: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  lastBroadcastId?: Maybe<Scalars['Int']>;
  filter?: Maybe<BroadcastFilter>;
};


export type AdminredisReadArgs = {
  key: Scalars['String'];
};


export type AdmindefaultVipTextArgs = {
  language: Scalars['String'];
};

export type AdminActivateAccountResult = {
  __typename?: 'AdminActivateAccountResult';
  accountId: Scalars['Int'];
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type AdminAddAccountResult = {
  __typename?: 'AdminAddAccountResult';
  accountId: Scalars['Int'];
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type AdminAddMasterResult = {
  __typename?: 'AdminAddMasterResult';
  masterId: Scalars['Int'];
  success: Scalars['Boolean'];
  existed: Scalars['Boolean'];
};

export type AdminAddSiteResult = {
  __typename?: 'AdminAddSiteResult';
  success: Scalars['Boolean'];
};

export type AdminMutation = {
  __typename?: 'AdminMutation';
  resendMessage: Scalars['Boolean'];
  addMaster: AdminAddMasterResult;
  addAccount: AdminAddAccountResult;
  updateAccount: Scalars['Boolean'];
  activateAccount: AdminActivateAccountResult;
  /** Add site */
  addSite: AdminAddSiteResult;
  /** Update existing site */
  updateSite: Scalars['Boolean'];
  /** Cancel sending of a broadcast */
  cancelBroadcast: Scalars['Boolean'];
  /** Set custom message as a command response */
  setCommandResponse: Scalars['Boolean'];
  updateMaster: Scalars['Boolean'];
  lockMaster: Scalars['Boolean'];
  unlockMaster: Scalars['Boolean'];
  updateAccountProfileMedia: Scalars['Boolean'];
  blockUserGlobal: Scalars['String'];
  /** The value is kept for 28 days, if there is no update in the meantime, it will be deleted. */
  redisWrite: Scalars['String'];
  /** Phone Number with +491730000 format */
  archiveSession: Scalars['String'];
};


export type AdminMutationresendMessageArgs = {
  messageId: Scalars['Int'];
};


export type AdminMutationaddMasterArgs = {
  actorId: Scalars['Int'];
  actorName: Scalars['String'];
  slug: Scalars['String'];
  token?: Maybe<Scalars['Token']>;
  uuid?: Maybe<Scalars['UUID']>;
  language?: Maybe<Scalars['String']>;
};


export type AdminMutationaddAccountArgs = {
  masterId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  comment: Scalars['String'];
};


export type AdminMutationupdateAccountArgs = {
  accountId: Scalars['Int'];
  accountPeerId: Scalars['Long'];
  peerName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};


export type AdminMutationactivateAccountArgs = {
  phoneNumber: Scalars['String'];
  pinCode: Scalars['String'];
};


export type AdminMutationaddSiteArgs = {
  masterId: Scalars['Int'];
  hostname: Scalars['String'];
  logoUrl: Scalars['Url'];
  premiumLogoUrl?: Maybe<Scalars['Url']>;
  backgroundImageUrl: Scalars['Url'];
  premiumBackgroundImageUrl?: Maybe<Scalars['Url']>;
  premiumBackgroundImageUrlDesktop?: Maybe<Scalars['Url']>;
  premiumBackgroundImageUrlMobile?: Maybe<Scalars['Url']>;
  theme: SiteTheme;
  hasAvs?: Maybe<Scalars['Boolean']>;
  usdMode?: Maybe<Scalars['Boolean']>;
  channelInviteUrl?: Maybe<Scalars['String']>;
};


export type AdminMutationupdateSiteArgs = {
  masterId: Scalars['Int'];
  hostname: Scalars['String'];
  logoUrl: Scalars['Url'];
  premiumLogoUrl?: Maybe<Scalars['Url']>;
  backgroundImageUrl: Scalars['Url'];
  premiumBackgroundImageUrl?: Maybe<Scalars['Url']>;
  premiumBackgroundImageUrlDesktop?: Maybe<Scalars['Url']>;
  premiumBackgroundImageUrlMobile?: Maybe<Scalars['Url']>;
  theme: SiteTheme;
  hasAvs?: Maybe<Scalars['Boolean']>;
  usdMode?: Maybe<Scalars['Boolean']>;
  channelInviteUrl?: Maybe<Scalars['String']>;
};


export type AdminMutationcancelBroadcastArgs = {
  broadcastId: Scalars['Int'];
  revoke?: Maybe<Scalars['Boolean']>;
};


export type AdminMutationsetCommandResponseArgs = {
  masterId: Scalars['Int'];
  command: TextCommand;
  premium: Scalars['Boolean'];
  text: Scalars['String'];
  fileId?: Maybe<Scalars['Int']>;
  fileUUID?: Maybe<Scalars['String']>;
};


export type AdminMutationupdateMasterArgs = {
  masterId: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};


export type AdminMutationlockMasterArgs = {
  actorId: Scalars['Int'];
};


export type AdminMutationunlockMasterArgs = {
  actorId: Scalars['Int'];
};


export type AdminMutationupdateAccountProfileMediaArgs = {
  masterId: Scalars['Int'];
  fileUUID: Scalars['String'];
};


export type AdminMutationblockUserGlobalArgs = {
  peerId: Scalars['Long'];
  unblock?: Maybe<Scalars['Boolean']>;
};


export type AdminMutationredisWriteArgs = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


export type AdminMutationarchiveSessionArgs = {
  phoneNumber: Scalars['String'];
};

export type AdminPaidContent = {
  __typename?: 'AdminPaidContent';
  id: Scalars['String'];
  messageId?: Maybe<Scalars['Int']>;
  unlockedMessageId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  guestId?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  status: PaidContentStatus;
  text?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  uhash?: Maybe<Scalars['String']>;
  externalPaymentId?: Maybe<Scalars['Int']>;
  message?: Maybe<Message>;
  unlockedMessage?: Maybe<Message>;
  unlockMessageSent: Scalars['Boolean'];
  timestamp?: Maybe<Scalars['String']>;
  bought_timestamp?: Maybe<Scalars['String']>;
};

export type AdminPaidContentList = {
  __typename?: 'AdminPaidContentList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  adminPaidContents: Array<AdminPaidContent>;
};

export type BotMutation = {
  __typename?: 'BotMutation';
  /** Returns registered file info with UUID to enable upload. */
  requestFileUpload?: Maybe<File>;
};


export type BotMutationrequestFileUploadArgs = {
  peerId: Scalars['Long'];
};

export type Broadcast = {
  __typename?: 'Broadcast';
  id: Scalars['Int'];
  master: Master;
  text?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  price?: Maybe<Scalars['Float']>;
  paidContent: Scalars['Boolean'];
  salesCount: Scalars['Int'];
  usersCount: Scalars['Int'];
  channelUsersCount: Scalars['Int'];
  channelSentCount: Scalars['Int'];
  sentCount: Scalars['Int'];
  timestamp: Scalars['String'];
  referenceId?: Maybe<Scalars['String']>;
  canceled: Scalars['Boolean'];
  revoked: Scalars['Boolean'];
  cancelable: Scalars['Boolean'];
  status: BroadcastStatus;
  failedCount: Scalars['Int'];
  userType?: Maybe<UserType>;
  usertagCategoryId?: Maybe<Scalars['Int']>;
  recipientDisplayName: Scalars['String'];
  progress: Scalars['Float'];
  isSticky: Scalars['Boolean'];
  isTipping: Scalars['Boolean'];
  isPromotePremium: Scalars['Boolean'];
  source: BroadcastSource;
};

export type BroadcastActivityMonitor = {
  __typename?: 'BroadcastActivityMonitor';
  actorName: Scalars['String'];
  actorId: Scalars['Int'];
  vipAbos: Scalars['Int'];
  lastFreeBroadcast: Scalars['String'];
  lastVIPBroadcast: Scalars['String'];
};

export type BroadcastFilter = {
  /** List of ids. */
  ids?: Maybe<Array<Scalars['Int']>>;
  /** List of master ids. */
  masterIds?: Maybe<Array<Scalars['Int']>>;
  /** Filter for paid content. */
  paidContent?: Maybe<Scalars['Boolean']>;
  /** Filter for tag destination broadcasts. */
  isTagDestination?: Maybe<Scalars['Boolean']>;
  /** List of broadcast external ref ids. */
  referenceIds?: Maybe<Array<Scalars['String']>>;
  /** Filter for destinations Enum(free,vip,both) */
  userType?: Maybe<UserType>;
  /** Date filter for entries > from this date */
  fromType?: Maybe<Scalars['String']>;
};

export type BroadcastList = {
  __typename?: 'BroadcastList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  broadcasts: Array<Broadcast>;
};

export type BroadcastListV2 = {
  __typename?: 'BroadcastListV2';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  broadcasts: Array<Broadcast>;
};

export type BroadcastResult = {
  __typename?: 'BroadcastResult';
  success: Scalars['Boolean'];
  status: BroadcastResultStatus;
  broadcastId?: Maybe<Scalars['Int']>;
  receiversCount: Scalars['Int'];
};

/** Status of broadcast */
export enum BroadcastResultStatus {
  ok = 'ok',
  sendIntervalException = 'sendIntervalException',
  uniqueReferenceIdException = 'uniqueReferenceIdException',
  restrictPersonalizedMessageForChannel = 'restrictPersonalizedMessageForChannel'
}

/** Order of broadcasts. */
export enum BroadcastsOrder {
  newest = 'newest'
}

/** Shows the available sources of broadcasts */
export enum BroadcastSource {
  telegrambot = 'telegrambot',
  vxmodels = 'vxmodels',
  channel = 'channel'
}

/** Possible Broadcast Status result values */
export enum BroadcastStatus {
  running = 'running',
  done = 'done',
  failed = 'failed',
  canceled = 'canceled',
  revoked = 'revoked'
}

export type BulletText = {
  __typename?: 'BulletText';
  id: Scalars['Int'];
  masterId: Scalars['Int'];
  order: Scalars['Int'];
  text: Scalars['String'];
  language: Scalars['String'];
};

export type BulletTextList = {
  __typename?: 'BulletTextList';
  bulletTexts: Array<BulletText>;
};

/** description */
export type ChatEventUnion = Message | Tipping;

export type ClientMultiMutation = {
  __typename?: 'ClientMultiMutation';
  /** Returns registered file info with UUID to enable upload. */
  requestFileUpload?: Maybe<File>;
};


export type ClientMultiMutationrequestFileUploadArgs = {
  peerId: Scalars['Long'];
};

export type CommandResponse = {
  __typename?: 'CommandResponse';
  masterId: Scalars['Int'];
  command: TextCommand;
  premium: Scalars['Boolean'];
  text: Scalars['String'];
  file?: Maybe<File>;
};

export type Config = {
  __typename?: 'Config';
  allowedMimeTypes: Array<Scalars['String']>;
};

export type Context = {
  __typename?: 'Context';
  token?: Maybe<Scalars['String']>;
};

/** EUR or USD */
export enum Currency {
  EUR = 'EUR',
  USD = 'USD'
}


export type Dsgvo = {
  __typename?: 'Dsgvo';
  guest?: Maybe<Array<GuestDsgvo>>;
  model?: Maybe<Array<ModelDsgvo>>;
};


export type DsgvoguestArgs = {
  guestId: Scalars['Int'];
  phoneNumbers: Array<Maybe<Scalars['String']>>;
};


export type DsgvomodelArgs = {
  modelId: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  id: Scalars['Int'];
  uuid: Scalars['String'];
  type: FileType;
  exists: Scalars['Boolean'];
  url: Scalars['String'];
  previewPictureUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  imgSrcSet: Scalars['String'];
  transcodingFailed: Scalars['Boolean'];
  mimeType: Scalars['String'];
};


export type FileurlArgs = {
  mediaSize?: Maybe<MediaSize>;
  showSendVersion?: Maybe<Scalars['Boolean']>;
};

/** Type of file. */
export enum FileType {
  undefined = 'undefined',
  picture = 'picture',
  video = 'video',
  audio = 'audio',
  sticker = 'sticker'
}

export type GroupedChat = {
  __typename?: 'GroupedChat';
  date: Scalars['String'];
  events?: Maybe<Array<Maybe<ChatEventUnion>>>;
};

export type GroupedMessagesDate = {
  __typename?: 'GroupedMessagesDate';
  date: Scalars['String'];
  messages: Array<Message>;
};

export type GuestDsgvo = {
  __typename?: 'GuestDsgvo';
  peerId: Scalars['Long'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  subscribedSince: Scalars['String'];
  onlineUntil: Scalars['String'];
  premium: Scalars['Boolean'];
  countMessagesSent: Scalars['Int'];
  countMessagesReceived: Scalars['Int'];
  countFileUploads: Scalars['Int'];
};

export type InputContext = {
  token?: Maybe<Scalars['String']>;
};

export type LandingPageMutation = {
  __typename?: 'LandingPageMutation';
  sendEMail: Scalars['Boolean'];
};


export type LandingPageMutationsendEMailArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
};

/** Locale */
export enum Locale {
  de = 'de',
  de_DE = 'de_DE',
  en = 'en',
  en_GB = 'en_GB',
  en_US = 'en_US'
}


export type Master = {
  __typename?: 'Master';
  id: Scalars['Int'];
  actorId: Scalars['Int'];
  actorName: Scalars['String'];
  slug: Scalars['String'];
  uuid: Scalars['String'];
  freeSlots: Scalars['Int'];
  /** @deprecated Feature got removed */
  freeTVSlots?: Maybe<Scalars['Int']>;
  countVipUser: Scalars['Int'];
  countUser: Scalars['Int'];
  countChannelUser: Scalars['Int'];
  language: Scalars['String'];
  active: Scalars['Boolean'];
  accounts: Array<Account>;
  userList: UserList;
  user?: Maybe<User>;
  file?: Maybe<File>;
  /** @deprecated Use new Endpoint "broadcastListV2" for full support of broadcast filter */
  broadcastList: BroadcastList;
  broadcastListV2: BroadcastListV2;
  userLifeCycle: Array<Maybe<UserLifeCycleStep>>;
  freeUserLifeCycleSteps?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  commandResponse?: Maybe<CommandResponse>;
  tippingList: TippingList;
  tippingAmount: Scalars['Float'];
  tippingCount: Scalars['Int'];
  unreadTips: Scalars['Int'];
  unreadTippingAmount: Scalars['Float'];
  currency: Currency;
  isUnpinable: Scalars['Boolean'];
  freeSubscriptions: Scalars['Int'];
  vipSubscriptions: Scalars['Int'];
  paidContent: PaidContentStats;
  userTag: UserTag;
  allowedMimeTypes: Array<Scalars['String']>;
  channelExists: Scalars['Boolean'];
  channelInviteUrl?: Maybe<Scalars['String']>;
  channelExistsSince?: Maybe<Scalars['String']>;
};


export type MasteruserListArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  filter?: Maybe<UserFilter>;
  order?: Maybe<UsersOrder>;
};


export type MasteruserArgs = {
  id: Scalars['Int'];
};


export type MasterfileArgs = {
  uuid: Scalars['String'];
};


export type MasterbroadcastListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<BroadcastFilter>;
  order?: Maybe<BroadcastsOrder>;
};


export type MasterbroadcastListV2Args = {
  limit?: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  lastBroadcastId?: Maybe<Scalars['Int']>;
  filter?: Maybe<BroadcastFilter>;
};


export type MasteruserLifeCycleArgs = {
  vip?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
};


export type MasterfreeUserLifeCycleStepsArgs = {
  vip?: Maybe<Scalars['Boolean']>;
};


export type MastercommandResponseArgs = {
  command: TextCommand;
  premium?: Maybe<Scalars['Boolean']>;
};


export type MastertippingListArgs = {
  limit?: Maybe<Scalars['Int']>;
  lastTippingId?: Maybe<Scalars['Int']>;
};


export type MastertippingAmountArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type MastertippingCountArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type MasterfreeSubscriptionsArgs = {
  date: Scalars['String'];
};


export type MastervipSubscriptionsArgs = {
  date: Scalars['String'];
};

export type MasterList = {
  __typename?: 'MasterList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  masters: Array<Master>;
};

export type MasterMessengerVipTextMutation = {
  __typename?: 'MasterMessengerVipTextMutation';
  /** Updates messenger vip bullet texts. */
  setBullets: MasterMessengerVipTextMutationResult;
};


export type MasterMessengerVipTextMutationsetBulletsArgs = {
  bullets: Array<MessengerVipBulletTextInput>;
};

export type MasterMessengerVipTextMutationResult = {
  __typename?: 'MasterMessengerVipTextMutationResult';
  success: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type MasterMutation = {
  __typename?: 'MasterMutation';
  /** Sends `text` and/or uploaded file specified by `fileUUID` to the given user. */
  send?: Maybe<Message>;
  /**
   * Sends `text` and/or uploaded file specified by `fileUUID` to the user group
   * specified by `toUserFilter`and returns the number of receivers.
   */
  broadcast?: Maybe<BroadcastResult>;
  tipping: BroadcastResult;
  /** Returns registered file info with UUID to enable upload. */
  requestFileUpload: File;
  /** Marks all incoming user messages and tippings as read and returns the number of affected messages. */
  setMessagesRead: Scalars['Int'];
  /** Deletes message */
  deleteMessage: Scalars['Boolean'];
  /** User life cycle. */
  userLifeCycle: MasterUserLifeCycleMutation;
  /** Messenger VIP Texts */
  messengerVipText: MasterMessengerVipTextMutation;
  /** Block User */
  blockUser: Scalars['Boolean'];
  /** Unblock User */
  unblockUser: Scalars['Boolean'];
  /** Cancel sending of a broadcast. Note, a master can only cancel his own broadcast */
  cancelBroadcast: Scalars['Boolean'];
  /** Removes all sticky messages of all user chats */
  unpinMessages: Scalars['Boolean'];
  /** Creates a PIN to authenticate in the bot */
  botPin: Scalars['String'];
  updateAccountProfileMedia: Scalars['Boolean'];
  userTag: UserTagMutation;
};


export type MasterMutationsendArgs = {
  toUserId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  fileUUID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  sticky?: Maybe<Scalars['Boolean']>;
  paidContentCategoryId?: Maybe<Scalars['Int']>;
};


export type MasterMutationbroadcastArgs = {
  toUserFilter: UserFilter;
  text?: Maybe<Scalars['String']>;
  fileUUID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  referenceId?: Maybe<Scalars['ID']>;
  sticky?: Maybe<Scalars['Boolean']>;
  promotePremium?: Maybe<Scalars['Boolean']>;
  paidContentCategoryId?: Maybe<Scalars['Int']>;
};


export type MasterMutationtippingArgs = {
  toUserFilter: UserFilter;
  text?: Maybe<Scalars['String']>;
  fileUUID?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['ID']>;
  sticky?: Maybe<Scalars['Boolean']>;
  promotePremium?: Maybe<Scalars['Boolean']>;
};


export type MasterMutationsetMessagesReadArgs = {
  userId: Scalars['Int'];
};


export type MasterMutationdeleteMessageArgs = {
  messageId: Scalars['Int'];
};


export type MasterMutationblockUserArgs = {
  userId: Scalars['Int'];
};


export type MasterMutationunblockUserArgs = {
  userId: Scalars['Int'];
};


export type MasterMutationcancelBroadcastArgs = {
  broadcastId: Scalars['Int'];
  revoke?: Maybe<Scalars['Boolean']>;
};


export type MasterMutationupdateAccountProfileMediaArgs = {
  fileUUID: Scalars['String'];
};

export type MasterUserLifeCycleMutation = {
  __typename?: 'MasterUserLifeCycleMutation';
  /** Inserts or updates user life cycle steps. */
  setSteps?: Maybe<MasterUserLifeCycleMutationResult>;
  /** Inserts or updates a user life cycle step. */
  setStep?: Maybe<MasterUserLifeCycleMutationResult>;
  /** Deletes user life cycle step. */
  unsetStep: Scalars['Boolean'];
};


export type MasterUserLifeCycleMutationsetStepsArgs = {
  steps: Array<UserLifeCycleStepInput>;
};


export type MasterUserLifeCycleMutationsetStepArgs = {
  step: UserLifeCycleStepInput;
};


export type MasterUserLifeCycleMutationunsetStepArgs = {
  id: Scalars['Int'];
};

export type MasterUserLifeCycleMutationResult = {
  __typename?: 'MasterUserLifeCycleMutationResult';
  success: Scalars['Boolean'];
  reason: Scalars['String'];
  step?: Maybe<Array<Maybe<UserLifeCycleStep>>>;
};

/** Size of media. */
export enum MediaSize {
  b640 = 'b640',
  b1920 = 'b1920',
  orig = 'orig'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  direction: MessageDirection;
  in: Scalars['Boolean'];
  out: Scalars['Boolean'];
  type: MessageType;
  system: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  timestamp: Scalars['String'];
  read: Scalars['Boolean'];
  isBroadcastMessage: Scalars['Boolean'];
  broadcast?: Maybe<Broadcast>;
  isPaidContent: Scalars['Boolean'];
  paidContent?: Maybe<PaidContent>;
};

/** Direction of message. */
export enum MessageDirection {
  in = 'in',
  out = 'out'
}

export type MessageList = {
  __typename?: 'MessageList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  messages: Array<Message>;
  groupedMessages: Array<GroupedMessagesDate>;
};

/** Type of message. */
export enum MessageType {
  text = 'text',
  file = 'file',
  vcard = 'vcard'
}

export type MessengerVipBulletTextInput = {
  /** Display Order (Values: 1,2,3) */
  order: Scalars['Int'];
  /** Language (DE, EN) */
  language: Scalars['String'];
  /** Bullet Text (80 Chars) */
  text: Scalars['String'];
};

export type ModelDsgvo = {
  __typename?: 'ModelDsgvo';
  countMessagesSent: Scalars['Int'];
  countMessagesReceived: Scalars['Int'];
  countFileUploads: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  __context: Context;
  admin: AdminMutation;
  bot: BotMutation;
  clientMulti: ClientMultiMutation;
  landingPage: LandingPageMutation;
  master?: Maybe<MasterMutation>;
  simCard: SimCardMutation;
  vxpay: VXPayMutation;
};


export type Mutation__contextArgs = {
  context: InputContext;
};


export type MutationmasterArgs = {
  token: Scalars['String'];
};

export type PaidContent = {
  __typename?: 'PaidContent';
  id: Scalars['String'];
  messageId: Scalars['Int'];
  unlockedMessageId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  guestId?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  status: PaidContentStatus;
  text?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  uhash?: Maybe<Scalars['String']>;
  externalPaymentId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  payable: Scalars['Boolean'];
  unlockedMessage?: Maybe<Message>;
  unlockMessageSent: Scalars['Boolean'];
  currency: Currency;
};


export type PaidContenturlArgs = {
  mediaSize?: Maybe<MediaSize>;
};

export type PaidContentList = {
  __typename?: 'PaidContentList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  paidContents: Array<PaidContentV2>;
};

export type PaidContentPurchase = {
  __typename?: 'PaidContentPurchase';
  id: Scalars['Int'];
  paidContentId: Scalars['Int'];
  userId: Scalars['Int'];
  status: Scalars['Int'];
  externalPaymentId: Scalars['Int'];
  guestId: Scalars['Int'];
  unlockMessageId?: Maybe<Scalars['Int']>;
  created: Scalars['String'];
};

export type PaidContentPurchaseList = {
  __typename?: 'PaidContentPurchaseList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  paidContentPurchases: Array<PaidContentPurchase>;
};

export type PaidContentStats = {
  __typename?: 'PaidContentStats';
  paidContentList: PaidContentList;
  paidContentPurchaseList: PaidContentPurchaseList;
  countSoldPaidContent: Scalars['Int'];
  sumAmountSoldPaidContent: Scalars['Float'];
};


export type PaidContentStatspaidContentListArgs = {
  limit?: Maybe<Scalars['Int']>;
  lastPaidContentId?: Maybe<Scalars['Int']>;
};


export type PaidContentStatspaidContentPurchaseListArgs = {
  limit?: Maybe<Scalars['Int']>;
  lastPaidContentPurchaseId?: Maybe<Scalars['Int']>;
  guestId?: Maybe<Scalars['Int']>;
};


export type PaidContentStatscountSoldPaidContentArgs = {
  guestId?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type PaidContentStatssumAmountSoldPaidContentArgs = {
  guestId?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};

/** Paid Content status. */
export enum PaidContentStatus {
  free = 'free',
  payment_required = 'payment_required',
  avs_required = 'avs_required',
  avs_pending = 'avs_pending',
  paid = 'paid'
}

export type PaidContentV2 = {
  __typename?: 'PaidContentV2';
  id: Scalars['Int'];
  masterId: Scalars['Int'];
  broadcastId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  fileId: Scalars['Int'];
  price: Scalars['Float'];
  created: Scalars['String'];
};

export type Pin = {
  __typename?: 'Pin';
  pin: Scalars['String'];
  guestId: Scalars['Int'];
  actorId?: Maybe<Scalars['Int']>;
  expire: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  __context: Context;
  admin: Admin;
  config: Config;
  dsgvo: Dsgvo;
  master?: Maybe<Master>;
  paidContent?: Maybe<PaidContent>;
  simCardInfo: SimCardInfo;
  tipRanking: TipRanking;
  /** @deprecated Use new generic endpoint "userHash" */
  tipping?: Maybe<Scalars['Int']>;
  userHash?: Maybe<Scalars['Int']>;
  vxpay: VXPay;
  widget?: Maybe<Widget>;
};


export type QuerymasterArgs = {
  token: Scalars['String'];
};


export type QuerypaidContentArgs = {
  id: Scalars['String'];
};


export type QuerytipRankingArgs = {
  actorId: Scalars['Int'];
};


export type QuerytippingArgs = {
  id: Scalars['String'];
};


export type QueryuserHashArgs = {
  id: Scalars['String'];
};


export type QuerywidgetArgs = {
  slug?: Maybe<Scalars['String']>;
  actorId?: Maybe<Scalars['Int']>;
};

export type Ranking = {
  __typename?: 'Ranking';
  amount: Scalars['Float'];
  rank: Scalars['Int'];
  rankBadge: Scalars['String'];
  name: Scalars['String'];
  isRefreshed: Scalars['Boolean'];
};

export type SimCard = {
  __typename?: 'SimCard';
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
  masterId?: Maybe<Scalars['Int']>;
  provider: Scalars['String'];
  canceled: Scalars['Boolean'];
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type SimCardFilter = {
  /** Show only unassigned SimCards */
  unassignedSimCards?: Maybe<Scalars['Boolean']>;
  /** Show only assigned SimCards to MasterID */
  masterId?: Maybe<Scalars['Int']>;
  /** Show only canceled SimCards */
  filterCanceled?: Maybe<Scalars['Boolean']>;
  lastSimCardId?: Maybe<Scalars['Int']>;
};

export type SimCardInfo = {
  __typename?: 'SimCardInfo';
  availableCountryCodes: Array<Scalars['String']>;
  simCardList: SimCardList;
  simCard?: Maybe<SimCard>;
  smsList?: Maybe<SMSList>;
};


export type SimCardInfosimCardListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<SimCardFilter>;
};


export type SimCardInfosimCardArgs = {
  phoneNumber: Scalars['String'];
};


export type SimCardInfosmsListArgs = {
  simCardId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  lastSmsId?: Maybe<Scalars['Int']>;
};

export type SimCardList = {
  __typename?: 'SimCardList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  offset: Scalars['Int'];
  simCards: Array<SimCard>;
};

export type SimCardMutation = {
  __typename?: 'SimCardMutation';
  /** Request a new sim card */
  requestSim: Scalars['Boolean'];
  /** Cancel an existing phone number */
  cancelSim: Scalars['Boolean'];
};


export type SimCardMutationrequestSimArgs = {
  countryCode: Scalars['String'];
};


export type SimCardMutationcancelSimArgs = {
  phoneNumber: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  masterId: Scalars['Int'];
  hostname: Scalars['String'];
  logoUrl: Scalars['String'];
  premiumLogoUrl?: Maybe<Scalars['String']>;
  backgroundImageUrl: Scalars['String'];
  premiumBackgroundImageUrl?: Maybe<Scalars['String']>;
  premiumBackgroundImageUrlDesktop?: Maybe<Scalars['String']>;
  premiumBackgroundImageUrlMobile?: Maybe<Scalars['String']>;
  /** @deprecated Unused, always returns empty string */
  tosUrl: Scalars['String'];
  /** @deprecated Unused, always returns empty string */
  dataProtectionUrl: Scalars['String'];
  /** @deprecated Unused, always returns empty string */
  imprintUrl: Scalars['String'];
  theme: SiteTheme;
  avs: Scalars['Boolean'];
  usdMode: Scalars['Boolean'];
  channelInviteUrl?: Maybe<Scalars['String']>;
};

export type SiteList = {
  __typename?: 'SiteList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  sites: Array<Site>;
};

/** Visual theme of the site */
export enum SiteTheme {
  light = 'light',
  dark = 'dark'
}

export type Sms = {
  __typename?: 'Sms';
  id: Scalars['Int'];
  SmsId: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  created: Scalars['String'];
};

export type SMSList = {
  __typename?: 'SMSList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  offset: Scalars['Int'];
  sms: Array<Sms>;
};

export type StatusMonitor = {
  __typename?: 'StatusMonitor';
  /** Returns the timestamp of the last sent message for the given direction */
  lastMessage: Scalars['Date'];
  /** Returns the timestamp of the last premium abo */
  lastPremiumAbo: Scalars['Date'];
  /** List of all account workers */
  accountWorker: Array<AccountWorker>;
};


export type StatusMonitorlastMessageArgs = {
  messageDirection?: Maybe<MessageDirection>;
};

/** All text commands (internal as well) */
export enum TextCommand {
  START = 'START',
  STOP = 'STOP',
  SUBSCRIBE_PREMIUM = 'SUBSCRIBE_PREMIUM'
}

export type Tipping = {
  __typename?: 'Tipping';
  id: Scalars['Int'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  read: Scalars['Boolean'];
  externalPaymentId: Scalars['Int'];
  date: Scalars['String'];
};

export type TippingList = {
  __typename?: 'TippingList';
  limit: Scalars['Int'];
  total: Scalars['Int'];
  tippings: Array<Tipping>;
};

export type TipRanking = {
  __typename?: 'TipRanking';
  topList: Array<Ranking>;
  user?: Maybe<Ranking>;
};


export type TipRankinguserArgs = {
  guestId: Scalars['Int'];
};



export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  peerId: Scalars['Long'];
  accountName?: Maybe<Scalars['String']>;
  accountPhoneNumber?: Maybe<Scalars['String']>;
  guestId: Scalars['Int'];
  accountId: Scalars['Int'];
  account: Account;
  master: Master;
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  subscribed: Scalars['Boolean'];
  subscribedSince?: Maybe<Scalars['String']>;
  premium: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  firstSeen?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  onlineUntil: Scalars['String'];
  onlineDiffHumans: Scalars['String'];
  typing: Scalars['Boolean'];
  unreadByMaster: Scalars['Int'];
  unreadByUser: Scalars['Int'];
  messageList: MessageList;
  blocked: Scalars['Boolean'];
  premiumAboInstances: Array<UserPremiumAboInstance>;
  tippingList: TippingList;
  tippingAmount: Scalars['Float'];
  unreadTips: Scalars['Int'];
  unreadTippingAmount: Scalars['Float'];
  groupedChat: Array<GroupedChat>;
  rankBadge?: Maybe<Scalars['String']>;
};


export type UseronlineDiffHumansArgs = {
  locale?: Maybe<Locale>;
};


export type UsermessageListArgs = {
  lastMessageId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type UsertippingListArgs = {
  limit?: Maybe<Scalars['Int']>;
  lastTippingId?: Maybe<Scalars['Int']>;
};


export type UsertippingAmountArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  firstOfMonth?: Maybe<Scalars['Boolean']>;
};


export type UsergroupedChatArgs = {
  limit?: Maybe<Scalars['Int']>;
  lastTippingId?: Maybe<Scalars['Int']>;
  lastMessageId?: Maybe<Scalars['Int']>;
};

export type UserAction = {
  __typename?: 'UserAction';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  userActionId: Scalars['Int'];
  timestamp: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  ref?: Maybe<Scalars['String']>;
};

export type UserActionList = {
  __typename?: 'UserActionList';
  userActions: Array<UserAction>;
};

export type UserFilter = {
  /** List of ids. */
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of telegram peer ids. */
  peerIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of guest ids. */
  guestIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** List of phone numbers. */
  phoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Are online or offline. */
  online?: Maybe<Scalars['Boolean']>;
  /** Were online after specified time. */
  onlineAfter?: Maybe<Scalars['String']>;
  /** Were online specified hours ago. Filter is only applied if `onlineAfter` is not set. */
  onlineLastHours?: Maybe<Scalars['Int']>;
  /** Subscribed or not subscribed. */
  subscribed?: Maybe<Scalars['Boolean']>;
  /** Subscribed before specified time. */
  subscribedBefore?: Maybe<Scalars['String']>;
  /** Subscribed since specified time - static 7 days */
  subscribedSince?: Maybe<Scalars['Boolean']>;
  /** VIP User subscribed since specified time - static 7 days */
  vipSubscribedSince?: Maybe<Scalars['Boolean']>;
  /** Users who have a premium subscription or not. */
  premium?: Maybe<Scalars['Boolean']>;
  /** Users who have unread messages. */
  unread?: Maybe<Scalars['Boolean']>;
  /** Users who are blocked or not. */
  blocked?: Maybe<Scalars['Boolean']>;
  /** First and or Lastname */
  name?: Maybe<Scalars['String']>;
  /** If true only user who tipped */
  onlyTipping?: Maybe<Scalars['Boolean']>;
  /** Shows the users with who the model has written last. */
  lastInteraction?: Maybe<Scalars['Boolean']>;
  /** List of user tag category ids. */
  userTagCategoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Possible delay units */
export enum UserLifeCycleDelayDisplayUnit {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days'
}

/** Possible delay schedules */
export enum UserLifeCycleDelaySchedule {
  morning = 'morning',
  midday = 'midday',
  evening = 'evening',
  immediately = 'immediately'
}

export type UserLifeCycleStep = {
  __typename?: 'UserLifeCycleStep';
  id: Scalars['Int'];
  active: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  vip: Scalars['Boolean'];
  delayMinutes: Scalars['Int'];
  delayDisplayUnit: UserLifeCycleDelayDisplayUnit;
  delaySchedule: UserLifeCycleDelaySchedule;
};

export type UserLifeCycleStepInput = {
  /** Life cycle step ID. Null to add a new step. Id to change an existing step. */
  id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  /** Limited to 4096 bytes. */
  text?: Maybe<Scalars['String']>;
  /** Is vip lifecycle step */
  vip?: Maybe<Scalars['Boolean']>;
  /** Delay in minutes after the registration after which the the step should be sent. */
  delayMinutes: Scalars['Int'];
  /** The unit in which the delay should be displayed */
  delayDisplayUnit: UserLifeCycleDelayDisplayUnit;
  /** Specifies at which time period the step should be sent AFTER the given delay */
  delaySchedule: UserLifeCycleDelaySchedule;
  /** File UUID. */
  fileUUID?: Maybe<Scalars['String']>;
};

export type UserList = {
  __typename?: 'UserList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  users: Array<User>;
};

export type UserPremiumAboInstance = {
  __typename?: 'UserPremiumAboInstance';
  userId: Scalars['Int'];
  instanceId: Scalars['Int'];
  guestId: Scalars['Int'];
  inserted: Scalars['String'];
};

export type UserReceivedMessages = {
  __typename?: 'UserReceivedMessages';
  guestId: Scalars['Int'];
  userId: Scalars['Int'];
  messageId: Scalars['Int'];
  referenceId?: Maybe<Scalars['Int']>;
  sent: Scalars['Boolean'];
  peerName: Scalars['String'];
  broadcastId: Scalars['Int'];
  text: Scalars['String'];
  file?: Maybe<File>;
  usersCount: Scalars['Int'];
  sentCount: Scalars['Int'];
  timestamp: Scalars['String'];
  premium: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
};

export type UserReceivedMessagesList = {
  __typename?: 'UserReceivedMessagesList';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  userReceivedMessages: Array<UserReceivedMessages>;
};

/** Order of users. */
export enum UsersOrder {
  newest = 'newest',
  relevance = 'relevance'
}

export type UserTag = {
  __typename?: 'UserTag';
  categories?: Maybe<Array<UserTagCategory>>;
};

export type UserTagCategory = {
  __typename?: 'UserTagCategory';
  id: Scalars['Int'];
  masterId: Scalars['Int'];
  displayName: Scalars['String'];
  active: Scalars['Boolean'];
  created: Scalars['String'];
};

export type UserTagMutation = {
  __typename?: 'UserTagMutation';
  addCategory: Scalars['Boolean'];
  enableCategory: Scalars['Boolean'];
  disableCategory: Scalars['Boolean'];
  addRelation: Scalars['Boolean'];
  removeRelation: Scalars['Boolean'];
  addPaidContentCategoryRelation: Scalars['Boolean'];
  unsetPaidContentCategoryRelation: Scalars['Boolean'];
};


export type UserTagMutationaddCategoryArgs = {
  displayName: Scalars['String'];
};


export type UserTagMutationenableCategoryArgs = {
  categoryId: Scalars['Int'];
};


export type UserTagMutationdisableCategoryArgs = {
  categoryId: Scalars['Int'];
};


export type UserTagMutationaddRelationArgs = {
  categoryId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type UserTagMutationremoveRelationArgs = {
  categoryId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type UserTagMutationaddPaidContentCategoryRelationArgs = {
  categoryId: Scalars['Int'];
  paidContentId: Scalars['Int'];
};


export type UserTagMutationunsetPaidContentCategoryRelationArgs = {
  paidContentId: Scalars['Int'];
};

/** Which recipients should be filtered by */
export enum UserType {
  both = 'both',
  vip = 'vip',
  free = 'free'
}


export type VXPay = {
  __typename?: 'VXPay';
  guest?: Maybe<VXPayGuest>;
};


export type VXPayguestArgs = {
  guestId: Scalars['Int'];
};

export type VXPayGuest = {
  __typename?: 'VXPayGuest';
  /** VISIT-X Guest ID */
  id: Scalars['Int'];
  /** List of users */
  userList: UserList;
  /** Has guest active channel with actor? */
  activeChannel: Scalars['Boolean'];
  premiumStatus: Scalars['String'];
  /** Checks if connection auf teleram user and guest exist. */
  existsActor: Scalars['Boolean'];
};


export type VXPayGuestactiveChannelArgs = {
  actorId: Scalars['Int'];
};


export type VXPayGuestpremiumStatusArgs = {
  actorId: Scalars['Int'];
};


export type VXPayGuestexistsActorArgs = {
  actorId?: Maybe<Scalars['Int']>;
};

export type VXPayMutation = {
  __typename?: 'VXPayMutation';
  /** Create PIN to set the user guest id. */
  requestPin: Pin;
  /** Set or unset premium flag. */
  setPremium: Scalars['Boolean'];
  /** Checks if connection on telegram user and guest exist. */
  existsGuest: Scalars['Boolean'];
  /** Set guestId for paid content id. */
  setPaidContentGuest: Scalars['Boolean'];
  /** Set content paid. */
  setPaidContentPurchased: Scalars['Boolean'];
  /** Tipping purchased */
  setTippingPurchased: Scalars['Boolean'];
  /** Set guest avs. */
  setAvs: Scalars['Boolean'];
  /** Set guest avs. */
  setCommunipayAvsStatus: Scalars['Boolean'];
};


export type VXPayMutationrequestPinArgs = {
  guestId: Scalars['Int'];
  actorId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};


export type VXPayMutationsetPremiumArgs = {
  guestId: Scalars['Int'];
  actorId: Scalars['Int'];
  premium: Scalars['Boolean'];
  instanceId?: Maybe<Scalars['Int']>;
  isChargeback?: Maybe<Scalars['Boolean']>;
};


export type VXPayMutationexistsGuestArgs = {
  guestId: Scalars['Int'];
  actorId?: Maybe<Scalars['Int']>;
};


export type VXPayMutationsetPaidContentGuestArgs = {
  paidContentId: Scalars['String'];
  guestId: Scalars['Int'];
};


export type VXPayMutationsetPaidContentPurchasedArgs = {
  paidContentId: Scalars['String'];
  ptxId?: Maybe<Scalars['Int']>;
  guestId: Scalars['Int'];
  hasAvs?: Maybe<Scalars['Boolean']>;
};


export type VXPayMutationsetTippingPurchasedArgs = {
  guestId: Scalars['Int'];
  actorId: Scalars['Int'];
  ptxId: Scalars['Int'];
  amount: Scalars['Float'];
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type VXPayMutationsetAvsArgs = {
  guestId: Scalars['Int'];
};


export type VXPayMutationsetCommunipayAvsStatusArgs = {
  guestId: Scalars['Int'];
  actorId: Scalars['Int'];
  status: Scalars['String'];
};

export type Widget = {
  __typename?: 'Widget';
  modelname: Scalars['String'];
  peerName: Scalars['String'];
  phoneNumber: Scalars['String'];
  hostname: Scalars['String'];
  logoUrl: Scalars['String'];
  premiumLogoUrl?: Maybe<Scalars['String']>;
  backgroundImageUrl: Scalars['String'];
  premiumBackgroundImageUrl?: Maybe<Scalars['String']>;
  premiumBackgroundImageUrlDesktop?: Maybe<Scalars['String']>;
  premiumBackgroundImageUrlMobile?: Maybe<Scalars['String']>;
  /** @deprecated Unused, always returns empty string */
  tosUrl: Scalars['String'];
  /** @deprecated Unused, always returns empty string */
  dataProtectionUrl: Scalars['String'];
  /** @deprecated Unused, always returns empty string */
  imprintUrl: Scalars['String'];
  theme: Scalars['String'];
  actorId: Scalars['Int'];
  avs: Scalars['Boolean'];
  usdMode: Scalars['Boolean'];
  bulletTextList: BulletTextList;
  channelInviteUrl?: Maybe<Scalars['String']>;
};


export type WidgetpeerNameArgs = {
  requestId?: Maybe<Scalars['String']>;
};


export type WidgetphoneNumberArgs = {
  requestId?: Maybe<Scalars['String']>;
};


export type WidgetbulletTextListArgs = {
  language: Scalars['String'];
};
