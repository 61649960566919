import { getScopeEnvironment, VXMODELS } from './scope';
import { getXdebugSessionCookie } from './cookies';

const DEBUG = true;
const env = process ? process.env || {} : {};

// helper functions
const getApiBaseUri = (REACT_APP_API_HOST) => {
  if (REACT_APP_API_HOST === '__inherit__') {
    const regex = /^(http[s]?):\/\/www\.(.+?)\//g;
    const match = regex.exec(window.document.hostname);
    if (match) {
      return `${match[1]}://api.${match[2]}`;
    } else {
      return 'https://api.vxmodels.com';
    }
  }
  return REACT_APP_API_HOST;
};

const getWebsocketUri = (REACT_APP_WS_HOST) => {
  const path = '/boomer/phoenix';
  return REACT_APP_WS_HOST === '__inherit__'
    ? `https://ws.vxmodels.com${path}`
    : `${REACT_APP_WS_HOST}${path}`;
};

const getScopeByHostname = (hostname) => {
  // production && stage
  if (/.*\.?vxmodels\.com$/.test(hostname)) return VXMODELS;

  // development
  if ('localhost' === hostname) return REACT_APP_DEV_EMULATE_SCOPE;
  if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) return REACT_APP_DEV_EMULATE_SCOPE;
  if (/.*\.?phoeni\.x$/.test(hostname)) return VXMODELS;
  if (/.*\.?vxmodels-frontend-app\.x$/.test(hostname)) return VXMODELS;

  return VXMODELS;
};

// static vars
export const {
  REACT_APP_API_HOST = window.app ? window.app.apiHost : '__inherit__',
  REACT_APP_BASE_PATH = window.app ? window.app.basePath : '/camtool',
  REACT_APP_DEV_PHPSTORM_DEBUG = getXdebugSessionCookie() || null,
  REACT_APP_DEV_EMULATE_SCOPE,
  REACT_APP_LANG = 'de',
  REACT_APP_VERSION = '0.0.0',
  REACT_APP_VXTOOL_HOST = 'https://www.vxtool.net',
  REACT_APP_WS_HOST = window.app ? window.app.wsHost : '__inherit__',
  REACT_APP_VXSERVICES_TELEGRAM_HOST = 'https://telegram.vx-services.net',
  REACT_APP_VXSERVICES_TELEGRAM_UPLOAD_URL = 'https://transcodeit.vxmodels.com/api/v1/upload/telegram',
  REACT_APP_VXSERVICES_NOTIFICATIONS_URL = 'https://notifications.vx-services.net',
  REACT_APP_VXSERVICES_ABO_SERVICE_UPLOAD_URL = 'https://transcodeit.visit-x.net/api/v1/upload/vxabo',
} = env;

// calculated vars
export const APP_API_HOST = getApiBaseUri(REACT_APP_API_HOST);
export const APP_BASE_PATH = REACT_APP_BASE_PATH; // alias for easier usage
export const APP_HOSTNAME = window.location.hostname;
export const APP_IS_DEVELOPMENT = env.NODE_ENV === 'development';
export const APP_IS_STAGE =
  window.location.hostname.startsWith('stage-') ||
  window.location.hostname.startsWith('stage1-') ||
  window.location.hostname.startsWith('stage2-') ||
  window.location.hostname.startsWith('stage3-');
export const APP_IS_PRODUCTION = env.NODE_ENV === 'production';
export const APP_SCOPE = getScopeByHostname(APP_HOSTNAME);
export const APP_WS_HOST = getWebsocketUri(REACT_APP_WS_HOST);
export const {
  APP_NAME,
  CONFIG_GOOGLE_TAG_MANAGER_ID_BETA,
  LANDING_PAGE_URL,
  PICTURES_CATEGORIES,
  VIDEOS_CATEGORIES,
} = getScopeEnvironment(APP_SCOPE);

// debug output
DEBUG && console.log('process.env', env);
