import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { heart } from '../../../../atoms/Icon/libraries/glyph';
import { useUserData } from '../../../../util/UserData';
import { _ } from '../../../../util/translate';

const WelcomeItem: FC = () => {
  const userData = useUserData();
  const hasAllRequiredDocs = userData?.model?.documents?.hasAllRequiredDocuments;
  const isActorDocsUploaded = userData?.model?.documents?.allActorDocumentsUploaded;
  const isOriDocsUploaded = userData?.model?.documents?.allOriginatorDocumentsUploaded;
  const preview = userData?.model?.media.previewPicture16;
  const isPictureAccepted = preview?.picture?.isChecked;
  const ProfilePicAndDocsUploadedAndRP =
    isPictureAccepted && (hasAllRequiredDocs || isActorDocsUploaded || isOriDocsUploaded);

  return ProfilePicAndDocsUploadedAndRP ? null : (
    <LeftMenuItem title={_('navigation:main.welcome')} icon={heart} uri="/welcome" />
  );
};

export default WelcomeItem;
