import update from 'immutability-helper';
import gql from 'graphql-tag';
import { FRAGMENT_NOTIFICATION_ITEMS } from '../graphql/VXModels/fragments';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { APP_BASE_PATH } from './env';
import { useDeepLinkBeta } from '../hooks';
import { VXModelsMobileTargetEnum } from '../graphql/VXModels/types';

export const NOTIFICATIONS_LIMIT = 15;

export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    category
    title
    subtitle
    createdAt
    updatedAt
    readAt
    showFrom
    type
    shortInfo
    uri
    link {
      uri
      label
    }
    picture(size: b160) {
      src
      alt
      height
      width
    }
    icon {
      src
      alt
      height
      width
    }
    primaryButton {
      label
      action
    }
    secondaryButton {
      label
      action
    }
    priority
  }
`;

export const VXMN_QUERY_NOTIFICATIONS = gql`
  query GetAllNotifications(
    $lang: ApiLangEnum!
    $filter: NotificationsFilterInput
    $count: Int!
    $after: String
  ) {
    vxmnQueryNotifications: notifications(lang: $lang, filter: $filter) {
      pagination(first: $count, after: $after) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            ...Notification
          }
        }
      }
    }
  }
  ${NotificationFragmentDoc}
`;

export const QUERY_UNREAD_NOTIFICATIONS = gql`
  query QUERY_UNREAD_NOTIFICATIONS(
    $notificationsOffset: Int = 0
    $notificationsLimit: Int
    $timezone: String!
  ) {
    # QUERY_UNREAD_NOTIFICATIONS
    model {
      unreadNotifications: notifications(
        showExpired: false
        unreadOnly: true
        category: [
          common
          directNotification
          document
          finance
          marketing
          medal
          mediacontent
          homepage
          profile
          ranking
          system
          vxchallenges
        ]
        timezone: $timezone
      ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
        count
        items(sort: PRIO_DESC__DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
          @connection(key: "items", filter: ["sort"]) {
          ...NotificationItems
        }
      }
    }
  }
  ${FRAGMENT_NOTIFICATION_ITEMS}
`;

export const QUERY_NEWSCENTER_NOTIFICATIONS = gql`
  query QUERY_NEWSCENTER_NOTIFICATIONS(
    $notificationsOffset: Int = 0
    $notificationsLimit: Int
    $timezone: String!
  ) {
    # QUERY_ACTIVE_NOTIFICATIONS
    model {
      newscenterNotifications: notifications(
        showExpired: true
        unreadOnly: false
        category: [system, snagbar, newscenter, vxgames2019, vxgames2020, vxchallenges]
        timezone: $timezone
      ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
        count
        items(sort: DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
          @connection(key: "items", filter: ["sort"]) {
          ...NotificationItems
        }
      }
    }
  }

  ${FRAGMENT_NOTIFICATION_ITEMS}
`;

export const VXMN_MUTATION_NOTIFICATIONS_MARKREAD = gql`
  mutation MarkRead($id: ID!) {
    notifications {
      markRead(id: $id) {
        ...Notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;

export const MUTATION_NOTIFICATIONS_MARKREAD = gql`
  mutation MarkRead($notificationIds: [Int]!) {
    # MUTATION_NOTIFICATIONS_MARKREAD
    model {
      notifications {
        markNotificationRead(id: $notificationIds) {
          id
          read
        }
      }
    }
  }
`;

export const updateQuery =
  (notificationsField) =>
  (prev, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return prev;
    }

    const prevItemIds = prev[notificationsField].pagination.edges.reduce((carry, item) => {
      carry.push(item.node.id);
      return carry;
    }, []);

    const nextItems = fetchMoreResult[notificationsField].pagination.edges.filter(
      (item) => prevItemIds.indexOf(item.node.id) === -1
    );

    const data = {};
    data[notificationsField] = {
      pagination: {
        edges: { $push: nextItems },
        pageInfo: {
          $set: fetchMoreResult[notificationsField].pagination.pageInfo,
        },
      },
    };

    return update(prev, data);
  };

export const updateQueryAfterMarkRead =
  (notificationsField, query, variables) =>
  (
    cache,
    {
      data: {
        notifications: {
          markRead: { id, readAt },
        },
      },
    }
  ) => {
    cache.writeQuery({
      query: query,
      variables,
      data: update(cache.readQuery({ query, variables }), {
        [notificationsField]: {
          pagination: {
            edges: (edges) => {
              return edges.map((edge) => {
                if (edge.node.id === id) {
                  edge.node.readAt = readAt;
                }
                return edge;
              });
            },
            totalCount: (count) => count - 1,
          },
        },
      }),
    });
  };

export const optimisticMarkReadResponse = (id) => {
  const now = new Date();
  return {
    notifications: {
      markRead: {
        id,
        readAt: now.toISOString(),
        __typename: 'NotificationInterface',
      },
      __typename: 'NotificationsMutation',
    },
    __typename: 'RootApiMutation',
  };
};

const possibleNotificationColors = {
  'error.500': '#EB0000',
  'lime.500': '#8CD062',
  'info.500': '#0072C6',
};
const possibleNotifcationIcons = {
  Error: 'icon-exclamation-sign',
  CheckCircle: 'icon-ok-circle',
  Info: 'icon-info-sign',
};

// from beta::NewscenterPopper/utils.ts
const getIconTypeAsString = (str, startIdx, endIdx) => {
  if (startIdx === -1) return '';
  else {
    if (endIdx === -1) {
      return str.slice(startIdx + 5);
    }
    return str.slice(startIdx + 5, endIdx);
  }
};

// from beta::NewscenterPopper/utils.ts
export const getIconAndColor = (str) => {
  if (typeof str !== 'string') return { iconType: null, color: null };
  const iconIndex = str.indexOf('icon/');
  const iconEndIndex = str.indexOf('?', iconIndex);
  const colorIndex = str.indexOf('?color=');
  const colorEndIndex = str.length;

  const iconType = getIconTypeAsString(str, iconIndex, iconEndIndex);

  const color = colorIndex !== -1 ? str.slice(colorIndex + 7, colorEndIndex) : '';

  return { iconType: possibleNotifcationIcons[iconType], color: possibleNotificationColors[color] };
};

// all keys tolowercase
const betaLinkMap = {
  'profile?tab=aboutme': APP_BASE_PATH + '/onlineprofile/visitx/profilepic/_sedcardVX12',
  'profile?tab=sedcard': APP_BASE_PATH + '/onlineprofile/visitx/profilepic/_sedcardVX12',
  'documents/payout': APP_BASE_PATH + '/account/documents',
  'documents/payout#taxeditsection': APP_BASE_PATH + '/account/documents',
  home: APP_BASE_PATH + '/dashboard',
  'documents/actors': APP_BASE_PATH + '/account/documents',
  'finance/tapid=billing': APP_BASE_PATH + '/finances/overview', // presumably a typo in the backend
  'finance/tabid=billing': APP_BASE_PATH + '/finances/overview',
  'contest/': APP_BASE_PATH + '/marketing/campaigns',
};

function buildBetaDeeplink(betaDeepLinkData, link) {
  var token = betaDeepLinkData?.auth?.betaDeeplink;
  if (token !== undefined && token.includes('token=')) {
    token = token.split('token=')[1];
  }
  const separator = link.includes('?') ? '&' : '?';
  return 'https://beta.vxmodels.com/' + link + separator + 'token=' + token;
}

const MappedBetaLink = ({ link, children, openNotification, onLinkClick, className, style }) => {
  const { data: betaDeepLinkData, refetch } = useDeepLinkBeta(VXModelsMobileTargetEnum.feed);

  if (typeof link !== 'string') {
    return (
      <div className={className} style={style} onClick={openNotification}>
        {children}
      </div>
    );
  }

  if (link.startsWith('http')) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={className}
        style={style}
        onClick={onLinkClick}
      >
        {children}
      </a>
    );
  }

  let target = '';
  if (link.startsWith('classic')) {
    link = link.toLowerCase();
    link = link.replace(/classic:\/\/\/?/g, '');
  } else if (link.startsWith('app')) {
    link = link.toLowerCase();
    link = link.replace(/app:\/\/\/?/g, '');

    console.log('link', link);

    if (link.startsWith('feed')) {
      target = '_blank';
      link = buildBetaDeeplink(betaDeepLinkData, link);
      const _onLinkClick = onLinkClick;
      onLinkClick = () => {
        refetch().then(() => {
          _onLinkClick?.();
        });
      };
    } else if (link.endsWith('#contest')) {
      target = '_blank';
      link = buildBetaDeeplink(betaDeepLinkData, link);
      const _onLinkClick = onLinkClick;
      onLinkClick = () => {
        refetch().then(() => {
          _onLinkClick?.();
        });
      };
    } else if (link.startsWith('notification')) {
      return (
        <div className={className} style={style} onClick={openNotification}>
          {children}
        </div>
      );
    } else {
      link = betaLinkMap[link];
    }
  }

  return (
    <Link
      to={{ pathname: link }} // TODO check if normal links still work
      target={target}
      className={className}
      style={style}
      onClick={onLinkClick}
    >
      {children}
    </Link>
  );
};
MappedBetaLink.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
  openNotification: PropTypes.func,
  onLinkClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};
export { MappedBetaLink };

/** Notifications count for <LeftMenu> badge */
export const NOTIFICATIONS_COUNT_MENU = gql`
  query NOTIFICATIONS_COUNT_MENU($timezone: String!) {
    model {
      newscenterNotifications: notifications(
        unreadOnly: true
        showExpired: true
        category: [newscenter, system, snagbar, vxgames2020, vxchallenges]
        timezone: $timezone
      ) {
        count
      }
    }
  }
`;
