import React, {FC} from 'react';
import styled from '@emotion/styled';
import {_} from '../../util/translate';
import {useQuery} from '@apollo/react-hooks';
import {useHasRole} from '../../util/UserData';
import {BLACK_2, BREAKPOINT_PHONE_CONDITION} from '../../camtool-styles';
import {Markdown, NotificationBox, Spinner, T} from '../../components';
import {ProfilePictureTile} from './Tiles';
import {BREAKPOINT} from './constants';
import {SecurityRole} from '../../graphql/VXModels/types';
import {
    QUERY_ATLEASTONEACTORDOC,
    QUERY_PHOTO_ALBUMS_BY_TYPE,
    QUERY_WELCOME_CONTENT,
} from '../../graphql/VXModels/queries';
import {ProfilePictureType} from '../../components/ProfilePicture/ProfilePictureType';
import GenericEmptyContent from '../../atoms/GenericEmptyContent/GenericEmptyContent';
import {useAssistantIncompleteRequiredTypes} from './Tiles/Secondary/DocumentsTile/hooks';
import {getTimezone} from '../../util/timezone';
import {QUERY_DASHBOARD_INITIAL} from '../Dashboard/graphql';
import AccountSwitchToBeta from "../Account/AccountSwitchToBeta";
import {useAppState} from "../../util/AppState";

interface Props {
    onDocsUploadedSuccess: () => void;
    onProfilePicUploadSuccess: () => void;
}

const Container = styled.div`
    flex-direction: column;
    align-items: center;
`;

const TileRow = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: ${BREAKPOINT}px) {
        flex-direction: row;
    }
`;

const Headline = styled.h3`
    text-align: center;
    color: ${BLACK_2};
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;

    @media ${BREAKPOINT_PHONE_CONDITION} {
        font-size: 14px;
        margin-bottom: 0;
    }
`;

const SpinnerContainer = styled.div`
    min-height: 300px;
    width: 100%;
    position: relative;
`;

const WelcomeContent: FC<Props> = ({onDocsUploadedSuccess, onProfilePicUploadSuccess}) => {
    const hasRoleAdmin = useHasRole(SecurityRole.VX_USER_ADMIN);

    const {
        loading: welcomeLoading,
        error: welcomeError,
        data: welcomeData,
    } = useQuery(QUERY_WELCOME_CONTENT, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        loading: dashboardLoading,
        error: dashboardError,
        data: dashboardData,
    } = useQuery(QUERY_DASHBOARD_INITIAL, {variables: {timezone: getTimezone()}});

    const {error: atLeastError, data: atLeastData} = useQuery(QUERY_ATLEASTONEACTORDOC, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        loading: loadingPicture,
        error: errorPicture,
        data: dataPicture,
    } = useQuery(QUERY_PHOTO_ALBUMS_BY_TYPE, {
        variables: {type: 'profile16'},
        fetchPolicy: 'network-only',
    });

    const {
        snagbarNotifications
    } =
        !dashboardLoading && dashboardData
            ? dashboardData?.model
            : {
                dashboard: {},
                wasOnlineWithSSW: false,
                commonProfileFillingPercentage: 0,
                tvshows: {planned: []},
                isOnlineWithSSW: true,
                media: {}
            }; // prettier-ignore
    const {
        loading: loadingInCompleteDocs,
        rejectedDocumentsExist,
        checkingDocumentsExist,
    } = useAssistantIncompleteRequiredTypes();

    const preview = welcomeData?.model?.media.previewPicture16;
    const album = preview?.album;
    const picture = preview?.picture;
    const isPictureAccepted = picture?.isChecked;
    const lastPictures = dataPicture?.model?.photoAlbums?.albums[0]?.pictures;
    const lastPicture = lastPictures ? lastPictures[lastPictures.length - 1] : undefined;
    const pictureChecking = picture?.url;
    const rejectionReason = lastPicture ? lastPicture.rejectionReason : undefined;

    const isActorDocsUploaded = welcomeData?.model?.documents?.allActorDocumentsUploaded;
    const isOriginatorDocsUploaded = welcomeData?.model?.documents?.allOriginatorDocumentsUploaded;

    const atLeastOneActorDocumentUploaded =
        atLeastData?.model?.documents?.atLeastOneActorDocumentUploaded;
    const hasDocsUploaded = isActorDocsUploaded || isOriginatorDocsUploaded;
    const showDocumentTile = atLeastOneActorDocumentUploaded;

    if (isPictureAccepted && hasDocsUploaded) {
        onProfilePicUploadSuccess();
        onDocsUploadedSuccess();
    }

    const [{lang}] = useAppState();
    let isDE = true;
    if (lang === 'en') {
        isDE = false;
    }

    if (loadingPicture)
        return (
            <SpinnerContainer>
                <Spinner noBackground={true}/>
            </SpinnerContainer>
        );

    if (welcomeError || errorPicture || atLeastError || dashboardError)
        return <GenericEmptyContent/>;

    return (
        <Container>
            <Headline>
                {!hasDocsUploaded && (
                    <>
                        {isDE ?
                            'Lade deine Dokumente schnell und einfach auf unserer neuen Oberfläche hoch und starte durch!' :
                            'Upload your documents quickly and easily to our new interface and get started through!'
                        }
                        <Markdown linkTarget={'_blank'} source={_('welcome:assistent.verifyOutlook')}/>
                    </>
                )}
                {hasDocsUploaded ? (
                    pictureChecking ? (
                        <T t={'welcome:assistent.finishedAllStepsTitle'}/>
                    ) : (
                        <T t={'welcome:assistent.pictureTitle'}/> && (
                            <T t={'welcome:assistent.pictureDescriptionTitle'}/>
                        )
                    )
                ) : null}
            </Headline>
            <TileRow>
                {(hasDocsUploaded || showDocumentTile) && (
                    <AccountSwitchToBeta
                        isStartpage={true}
                    />
                )}
                {hasDocsUploaded && (
                    <ProfilePictureTile
                        type={ProfilePictureType.previewPicture16}
                        album={album}
                        picture={picture}
                        loading={welcomeLoading}
                        rejectionReason={rejectionReason}
                        pictureChecking={pictureChecking}
                    />
                )}
            </TileRow>
            {!hasRoleAdmin && <NotificationBox/>}
        </Container>
    );
};

export default WelcomeContent;
