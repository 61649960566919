import React, { FC } from 'react';
import { infoSign } from '../../../../atoms/Icon/libraries/glyph';
import { LeftMenuItem } from '../../components';

const HelpItem: FC = () => (
  <LeftMenuItem
    title={'Helpcenter'}
    icon={infoSign}
    onClick={() => {
      window.open('https://support.vxmodels.com', '_blank');
    }}
  />
);

export default HelpItem;
