import React, { useEffect, useRef, useState } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import styled from '@emotion/styled';

import TopBarNotificationsEntry from './TopBarNotificationsEntry';
import TopBarNotificationsOverlay from './TopBarNotificationsOverlay';

import InfiniteScroll from 'react-infinite-scroller';
import { EmptyContent, Spinner } from '../../index';
import {
  NOTIFICATIONS_LIMIT,
  updateQuery,
  updateQueryAfterMarkRead,
  VXMN_QUERY_NOTIFICATIONS,
  VXMN_MUTATION_NOTIFICATIONS_MARKREAD,
} from '../../../util/NotificationsHelper';
import Translation from '../../Translation/Translation';
import { BLACK, RED, WHITE } from '../../../camtool-styles';
import { StyledButton } from '../styles';
import { _ } from '../../../util/translate';
import { getTimezone } from '../../../util/timezone';
import { BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET, TOP_BAR_BUTTON_WIDTH } from '../constants';
import { useVXServicesNotificationsClient } from '../../../graphql';
import { useLang } from '../../../util/AppState';
import { set } from 'date-fns';

const Container = styled.div`
  position: relative;
`;

const PopupContainer = styled.div`
  position: absolute;
  right: 0;
  top: 49px;
  flex-direction: column;
  align-items: center;
  background-color: ${WHITE};
  color: ${BLACK};
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  height: 300px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET}px) {
    right: -${TOP_BAR_BUTTON_WIDTH * 2}px;
  }
`;

function TopBarNotifications2(props) {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(null);

  useEffect(() => {
    window.addEventListener('click', handlePageClick);
    return () => {
      window.removeEventListener('click', handlePageClick);
    };
  }, []);

  const open = () => setIsOpen(true);

  const close = () => {
    setIsOpen(false);
    setShowNotification(null);
  };

  const handlePageClick = (event) => {
    // If the button is clicked, we let the button handle stuff. Otherwise, we handle it.
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return null;
    } else {
      // !!containerRef.current means whether isOpen is true or false. If it
      // is open but the user clicked outside it, then we set isOpen to false.
      // otherwise we do nothing
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        close();
      }
    }
  };

  const timezone = getTimezone();
  const client = useVXServicesNotificationsClient();
  const [lang] = useLang();

  const buildVariables = (after) => {
    return {
      lang,
      filter: { preset: 'ALL_UNREAD', sort: 'updated_at_desc' },
      count: NOTIFICATIONS_LIMIT,
      after,
    };
  };

  return (
    <Query client={client} query={VXMN_QUERY_NOTIFICATIONS} variables={buildVariables('')}>
      {({ loading, error, data, fetchMore }) => {
        const edges = data?.vxmnQueryNotifications?.pagination?.edges ?? [];
        const items = edges.map((edge) => edge.node).filter((item) => !item.readAt) ?? [];
        const count = data?.vxmnQueryNotifications?.pagination?.totalCount ?? 0;
        const hasNextPage = data?.vxmnQueryNotifications?.pagination?.pageInfo?.hasNextPage ?? true;
        const cursor = edges[edges.length - 1]?.cursor ?? '';

        return (
          <Mutation
            client={client}
            mutation={VXMN_MUTATION_NOTIFICATIONS_MARKREAD}
            update={updateQueryAfterMarkRead(
              'vxmnQueryNotifications',
              VXMN_QUERY_NOTIFICATIONS,
              buildVariables('')
            )}
          >
            {(markRead) => (
              <Container>
                <StyledButton ref={buttonRef} onClick={isOpen ? close : open} isOpen={isOpen}>
                  <div>
                    <span className="icon-bell" style={{ fontSize: 16 }} />
                    {count > 0 && (
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          right: 8,
                          top: 4,
                          height: 19,
                          width: 19,
                          fontSize: 10,
                          color: WHITE,
                          background: RED,
                          borderRadius: '50%',
                        }}
                      >
                        {count > 99 ? '+99' : count}
                      </span>
                    )}
                  </div>
                </StyledButton>

                {isOpen && (
                  <PopupContainer ref={containerRef}>
                    {showNotification && (
                      <TopBarNotificationsOverlay
                        {...showNotification}
                        onClickBack={(e) => {
                          setShowNotification(null);
                          e.stopPropagation();
                        }}
                        onLinkClick={close}
                      />
                    )}
                    <InfiniteScroll
                      className="grid__box__item__content"
                      style={{ width: '100%', overflow: showNotification ? 'hidden' : 'unset' }}
                      initialLoad={false}
                      loadMore={() => {
                        if (!loading) {
                          fetchMore({
                            variables: buildVariables(cursor),
                            updateQuery: updateQuery('vxmnQueryNotifications'),
                          });
                        }
                      }}
                      hasMore={hasNextPage}
                      loader={<Spinner key="loading" size="xs" inline={true} />}
                      useWindow={false}
                    >
                      {error && (
                        <EmptyContent
                          style={{ div: { fontSize: '16px !important' } }}
                          icon="icon-bell"
                          title={
                            <Translation t={'dashboard:app.notification.emptyContent.title'} />
                          }
                        />
                      )}
                      {items.length > 0 &&
                        items.reduce((render, item) => {
                          render.push(
                            <TopBarNotificationsEntry
                              className="topbar__notification__entry"
                              key={item.id}
                              {...item}
                              onMarkRead={markRead}
                              openNotification={() => setShowNotification(item)}
                              onLinkClick={close}
                            />
                          );
                          return render;
                        }, [])}

                      {!error && items.length === 0 && (
                        <EmptyContent
                          icon="icon-bell"
                          title={_('dashboard:app.notification.emptyContent.title')}
                        />
                      )}
                    </InfiniteScroll>
                  </PopupContainer>
                )}
              </Container>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default TopBarNotifications2;
