import React from 'react';
import * as env from '../../util/env';

const Environment = () => {
  const {
    REACT_APP_VERSION,
    REACT_APP_LANG,
    REACT_APP_BASE_PATH,
    REACT_APP_API_HOST,
    REACT_APP_WS_HOST,
    REACT_APP_VXTOOL_HOST,
    REACT_APP_DEV_PHPSTORM_DEBUG,
    REACT_APP_DEV_EMULATE_SCOPE,
    ...calculatedVars
  } = env;

  return (
    <div className="grid__row">
      <div className="grid__column grid__box min-width--0">
        <header className="grid__box__header">
          <div className="grid__box__header__title">Environment Variables</div>
        </header>
        <div className="grid__box__item">
          <div className="grid__box__item__content">
            <div className="warning-text" style={{ justifyContent: 'flex-start' }}>
              <div className="warning-text__column">
                <div className="title">Static Variables</div>
                <pre>
                  {JSON.stringify(
                    {
                      REACT_APP_API_HOST,
                      REACT_APP_BASE_PATH,
                      REACT_APP_DEV_EMULATE_SCOPE: REACT_APP_DEV_EMULATE_SCOPE,
                      REACT_APP_DEV_PHPSTORM_DEBUG,
                      REACT_APP_LANG,
                      REACT_APP_VERSION,
                      REACT_APP_VXTOOL_HOST,
                      REACT_APP_WS_HOST,
                    },
                    null,
                    2
                  )}
                </pre>
              </div>

              <div className="warning-text__column">
                <div className="title">Calculated Variables</div>
                <pre>{JSON.stringify({ ...calculatedVars }, null, 2)}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Environment;
